import Vue from 'vue';
import VueI18n from 'vue-i18n';
import locale from 'element-ui/lib/locale';
import CHT from './lang/zh-TW';
import CHS from './lang/zh-CN';

// import EnLocale from 'element-ui/lib/locale/lang/en'
import CHSLocale from 'element-ui/lib/locale/lang/zh-CN'
import CHTLocale from 'element-ui/lib/locale/lang/zh-TW'

Vue.use(VueI18n)
const DEFAULT_LANG = 'CHS';
//将数据配置好
const locales = {
    // En: Object.assign(En, EnLocale),
    CHS: Object.assign(CHS, CHSLocale),
    CHT: Object.assign(CHT, CHTLocale),
}
const i18n = new VueI18n({
    locale: localStorage.getItem('language') || DEFAULT_LANG,
    messages: locales,
    silentTranslationWarn: true
})
locale.i18n((key, value) => i18n.t(key, value)) //为了实现element插件的多语言切换

export default i18n