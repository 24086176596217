<template>
 <Frame>
    <PageHeader :title="$t('comm.trackingProducts')" :hasBack="false" />
    <div class="mainContain">
        <div class="ordermessage">
            <span class="detialmessage">
                <span>{{ $t('Order.messagedate') }}：</span>
                <el-date-picker size="small"
                v-model="billdate" type="daterange"  range-separator="~" @change="getTime()" 
                :start-placeholder="$t('comm.startdate')" :unlink-panels="true" :end-placeholder="$t('comm.enddate')"></el-date-picker></span>
            <span class="detialmessage">
                <span>{{ $t('TrackingProducts.orderbillno') }}：</span>
                <el-input :placeholder="$t('Order.inputpls')" v-model="billno" clearable   size="small"
                @change="inputchange(billno);"></el-input></span>
            <span class="detialmessage">
                <span>{{ $t('TrackingProducts.material') }}：</span>
                <el-input :placeholder="$t('Order.inputpls')"
                v-model="material" clearable   size="small"
                @change="inputchange(material);"></el-input></span>
        </div>
        <div class="ordermessage">
            <span class="detialmessage">
                <span>{{ $t('TrackingProducts.fromBillNo') }}：</span>
                <el-input :placeholder="$t('Order.inputpls')"
                v-model="frombillno" clearable   size="small"
                @change="inputchange(frombillno);"></el-input></span>
            <span class="detialmessage" v-if="$store.state.ShowMTO">
                <span>{{ $t('TrackingProducts.MTONo') }}：</span>
                <el-input :placeholder="$t('Order.inputpls')"
                v-model="mto" clearable   size="small"
                @change="inputchange(mto);"></el-input></span>
            <span class="detialmessage">
                <span>{{ $t('TrackingProducts.BatchNo') }}：</span>
                <el-input :placeholder="$t('Order.inputpls')"
                v-model="batchno" clearable   size="small"
                @change="inputchange(batchno);"></el-input></span>
        </div>
        <div class="buttonrow">
            <el-button type="reset" @click="reset()">{{ $t('button.reset') }}</el-button>
            <el-button type="primary" @click="query()">{{ $t('button.query') }}</el-button>
        </div>
        <el-table ref="multipleTable" :data="tableData" :height="tableHeight" style="margin:0;width:100%"
        v-loading="tableLoading" :header-cell-style="moneyclass">
            <el-table-column  prop="BillDate" :min-width="WidthControl('BillDate',tableData)" fixed :label="$t('Order.messagedate')"></el-table-column>
            <el-table-column  prop="BillNo" :min-width="WidthControl('BillNo',tableData,5)" fixed :label="$t('TrackingProducts.orderbillno')"></el-table-column>
            <el-table-column  prop="MaterialId" :width="WidthControl('MaterialId',tableData)" fixed :label="$t('TrackingProducts.MaterialId')"></el-table-column>
            <el-table-column  prop="MaterialName" :width="WidthControl('MaterialName',tableData)" :fixed="bigscreen" :label="$t('TrackingProducts.MaterialName')"></el-table-column>
            <el-table-column  prop="MaterialSpec" :width="WidthControl('MaterialSpec',tableData)" :fixed="bigscreen" :label="$t('TrackingProducts.MaterialSpec')"></el-table-column>
            <el-table-column  prop="MTONo" :min-width="WidthControl('MTONo',tableData,5)" v-if="$store.state.ShowMTO" :label="$t('TrackingProducts.MTONo')"></el-table-column>
            <el-table-column  prop="BatchNo" :min-width="WidthControl('BatchNo',tableData,5)" :label="$t('TrackingProducts.BatchNo')"></el-table-column>
            <el-table-column  prop="CarrySQty" :min-width="WidthControl('CarrySQty&&SUnitName',tableData,6)" align="right" :label="$t('TrackingProducts.CarrySQty')">
                <template slot-scope="scope">
                    <span style="margin: auto 0;">{{ scope.row.CarrySQty + scope.row.SUnitName }}</span>
                </template>
            </el-table-column>
            <el-table-column  prop="ReceivingSQty" :min-width="WidthControl('ReceivingSQty&&SUnitName',tableData,6)" align="right" :label="$t('TrackingProducts.ReceivingSQty')">
                <template slot-scope="scope">
                    <span style="margin: auto 0;">{{ scope.row.ReceivingSQty + scope.row.SUnitName }}</span>
                </template>
            </el-table-column>
            <el-table-column  prop="CheckState" :min-width="WidthControl('CheckState',tableData)" :label="$t('TrackingProducts.CheckState')"></el-table-column>
            <el-table-column  prop="GradeSQty" :min-width="WidthControl('GradeSQty&&SUnitName',tableData,6)" align="right" :label="$t('TrackingProducts.GradeSQty')">
                <template slot-scope="scope">
                    <span style="margin: auto 0;">{{ scope.row.GradeSQty + scope.row.SUnitName }}</span>
                </template>
            </el-table-column>
            <el-table-column  prop="BadnessSQty" :min-width="WidthControl('BadnessSQty&&SUnitName',tableData,6)" align="right" :label="$t('TrackingProducts.BadnessSQty')">
                <template slot-scope="scope">
                    <span style="margin: auto 0;">{{ scope.row.BadnessSQty + scope.row.SUnitName }}</span>
                </template>
            </el-table-column>
            <el-table-column  prop="CheckResult" :min-width="WidthControl('CheckResult',tableData)" :label="$t('TrackingProducts.CheckResult')"></el-table-column>
            <el-table-column  prop="StoreConfirmSQty" :min-width="WidthControl('StoreConfirmSQty&&SUnitName',tableData,6)" align="right" :label="$t('TrackingProducts.StoreConfirmSQty')">
                <template slot-scope="scope">
                    <span style="margin: auto 0;">{{ scope.row.StoreConfirmSQty + scope.row.SUnitName }}</span>
                </template>
            </el-table-column>
            <el-table-column  prop="UnStoreConfirmSQty" :min-width="WidthControl('UnStoreConfirmSQty&&SUnitName',tableData,6)" align="right" :label="$t('TrackingProducts.UnStoreConfirmSQty')">
                <template slot-scope="scope">
                    <span style="margin: auto 0;">{{ scope.row.UnStoreConfirmSQty + scope.row.SUnitName }}</span>
                </template>
            </el-table-column>
            <el-table-column  prop="FromBillNo" :min-width="WidthControl('FromBillNo',tableData,5)" :label="$t('TrackingProducts.FromBillNo')"></el-table-column>
        </el-table>
        <div class="pagefooter" >
            <span style="padding-left:15px;width: 37%;">
                <el-button @click="exportdialog()" v-if="$store.state.exportExcelState">
                <img src="../assets/img/button/batchexport.png" style="width: 12px;height:12px;" class="img">{{$t('button.batchexport')}}</el-button>
                <el-button id="print" @click="printdialog()">
                <img src="../assets/img/button/BatchPrint.png" style="width: 12px;height:12px;" class="img">{{$t('button.batchPrint')}}</el-button>
            </span>

            <el-pagination style="margin:auto 0;" @current-change="handleCurrentChange" ref="pageination"
            layout="total,prev,pager,next,jumper" :pager-count="5" :current-page.sync="currentPage" :total="totalCount">
            </el-pagination>
        </div>
        <el-dialog :title="$t('button.exporttitle')" :visible.sync="batchexport" width="25%">
            <el-radio v-model="radio" label="exportTextA" @change="changeRadio($event)" v-if="selectexport">{{ $t('button.exportTextA')}}</el-radio>
            <el-radio v-model="radio" label="exportTextB" @change="changeRadio($event)">{{ $t('button.exportTextB')}}</el-radio>
            <el-select style="width:100%;margin-top: 30px;" class="dialogselect" v-model="exportType"
                    :placeholder="typegather.length != 0 ? $t('Order.exportPlaceHolder') : $t('Order.exportNoneholder')">
                    <el-option v-for="item in typegather" :key="item.value" :label="item.name" :value="item.value">
                    </el-option>
                </el-select>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancel()">{{ $t('button.cancel') }}</el-button>
                <el-button type="primary" @click="determine(true)">{{ $t('button.determine') }}</el-button>
            </span>
        </el-dialog>
        <el-dialog width="25%" :title="$t('OrderDetail.tip')" :visible.sync="printvisible">
            <el-radio v-model="printRadio" label="PrintTextA" @change="changePrintRadio($event)" v-if="selectprint">{{ $t('button.printTextA')}}</el-radio>
            <el-radio v-model="printRadio" label="PrintTextB" @change="changePrintRadio($event)">{{ $t('button.printTextB')}}</el-radio>
                <el-select style="width:100%;margin-top: 30px;" class="dialogselect" v-model="printType"
                    :placeholder="typegather.length != 0 ? $t('OrderDetail.placeholder') : $t('OrderDetail.noneholder')">
                    <el-option v-for="item in typegather" :key="item.value" :label="item.name" :value="item.value">
                    </el-option>
                </el-select>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="determine()" type="primary">{{ $t('button.determine') }}</el-button>
                </div>
        </el-dialog>
    </div>
 </Frame>
</template>

<script>
import { handleShortDate } from '../utils/handleDate.js'
import {getWebSupplierConfig} from "@/utils/utils"
import { BillParams, PrintView } from '@/utils/ReportPreView'
export default{
    name:"TrackingProducts",
    data(){
        return{
            tableHeight: window.innerHeight - 361,
            bigscreen:true,
            totalCount:0,
            billdate:'',
            billno:'',
            startdate:'',
            enddate:'',
            material:'',
            tableLoading:false,
            frombillno:'',
            batchno:'',
            mto:'',
            pageindex:0,
            pagesize:10,
            tableData:[],
            resizeFlag:null,
            currentPage:1,
            selectprint:false,
            printvisible:false,
            printType:'',
            typegather:[],
            selectData:[],
            progId:'purReceivingOrder',
            printRadio:'PrintTextB',
            radio:'exportTextB',
            billOrgId:'',
            billTypeId:'',
            batchexport:false,
            selectexport:false,
            exportType:'',
        }
    },
    mounted(){
        var that =this;
        that.tableLoading = true;
        this.bigscreen = document.body.clientWidth>1650?true:false;
        window.onresize = () => {
            this.bigscreen = document.body.clientWidth>1650?true:false;
            if (that.resizeFlag) {
                clearTimeout(that.resizeFlag);
            }
            that.resizeFlag = setTimeout(() => {
                that.tableHeight = window.innerHeight - 361;
                that.resizeFlag = null;
            }, 100);
        }
        var fun = ()=>{
            this.$nextTick(()=>{
            this.$refs.multipleTable.doLayout();
            this.gettable(0)})
        }
        getWebSupplierConfig(this,fun);
    },
    methods:{
        gettable(pageindex){
            this.pageindex = pageindex;
            this.tableLoading = true;
            this.axiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx", 
            { 
                action: 'trackingproducts',
                billno:this.billno,
                startdate:this.startdate,
                enddate:this.enddate,
                material:this.material,
                frombillno:this.frombillno,
                batchno:this.batchno,
                mto:this.mto,
                pageindex:this.pageindex,
                pagesize:this.pagesize
            },(res,errflag)=>{
                this.tableLoading = false;
                if(!errflag){
                    var data = res.data;
                    this.totalCount = data[0].totalCount;
                    data.shift();
                    let patten=/(\d{4})(\d{2})(\d{2})/;
                    data.forEach(v => {
                        v.BillDate = String(v.BillDate).replace(patten, "$1-$2-$3")
                    });
                    this.tableData = data;
                }

            });
        },
        inputchange(val){
            this.gettable(0);
        },
        moneyclass({row,column,rowIndex,columnIndex}){
        },
         //获取时间
         getTime() {
            if(this.billdate){
                this.startdate = handleShortDate(this.billdate[0], "From");
                this.enddate = handleShortDate(this.billdate[1], "To");
            }
            else{
                this.startdate = "";
                this.enddate = "";
            }
            this.gettable(0);
        },
        //翻页处理
    handleCurrentChange(val) {
      this.pageindex = val - 1;
      this.gettable(val - 1);
    },
    reset(){
        this.billdate = "";
        this.pageindex = 0;
        this.billno = "";
        this.startdate = "";
        this.enddate = "";
        this.material = "";
        this.frombillno = "";
        this.batchno = "";
        this.mto = "";
        this.gettable(0);
    },
    query(){
        this.gettable(0);
    },
    exportdialog(){
      let prams = BillParams(this,true);
      this.billOrgId = prams.billOrgId;
      this.billTypeId = prams.billTypeId;
      this.batchexport = prams.printvisible;
      debugger
      if(this.selectData.length>0){
        this.selectexport = true;
        this.radio = "exportTextA";
      }else{
        this.selectexport = false;
        this.radio = "exportTextB";
      }
    },
    //打印弹窗对话框
    printdialog() {
      let prams = BillParams(this,true);
      this.printvisible = prams.printvisible;
      if(this.selectData.length>0){
        this.selectprint = true;
        this.printRadio = "PrintTextA";
      }else{
        this.selectprint = false;
        this.printRadio = "PrintTextB";
    }
    },
    cancel() {//取消
      this.batchexport = false;
    },
    determine(isexcel) {//确定
      debugger
      if(this.exportType==""&&isexcel){
        this.$store.commit("TIPPOP",{popType:'warning',popMessage:this.$t("comm.selectexport")});
        return;
      }
      if(this.printType==""&&!isexcel){
        debugger
        this.$store.commit("TIPPOP",{popType:'warning',popMessage:this.$t("comm.selectprint")});
        return;
      }
      this.batchexport = false;
      if((this.radio == "exportTextB"&&isexcel)||(this.printRadio == "PrintTextB"&&!isexcel)){
        this.axiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx", {
            action: 'trackingproducts',
            billno:this.billno,
            startdate:this.startdate,
            enddate:this.enddate,
            material:this.material,
            frombillno:this.frombillno,
            batchno:this.batchno,
            mto:this.mto,
            pageindex:this.pageindex,
            pagesize:this.pagesize,
            isexcel:true,
      },(res)=>{
        this.printtemp.selectKeys = this.progId + "Track_selectKeys";
        PrintView(this,isexcel);
      })
      }else{
        PrintView(this,isexcel);
      }
    },
    }
}
</script>
<style scoped>
.mainContain{
    padding:10px 0;
    width: 100%;
}
.detialmessage {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  width: 27%;
  color: #5d5d5d;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
}
.detialmessage span{
    margin: auto 0;
}
.buttonrow{
    float:right;
    padding-right: 40px;
    padding-bottom: 20px;
}
.detialmessage .el-date-editor--daterange.el-input__inner{
  width: 100%;
}
@media screen and (max-width: 1600px) {
    .detialmessage {
        width: 30%;
    }
}
.ordermessage {
  display: flex;
  flex-wrap: nowrap;
  padding: 8px 20px;
}
.el-table /deep/ thead {
  font-size: 14px;
  color: #5d5d5d;
}
.el-table /deep/ th.el-table__cell {
  background-color: #f9f9f9;
}
.pagefooter{
    width: 100%;
    display: flex;

}
.el-table /deep/ .el-table__fixed {
  height: calc(100% - 15px) !important;
}
.img {
  padding-right: 8px;
}
</style>
