//加载el-tooltip信息，鼠标聚焦时显示完整信息（Deliver表头栏位）
function renderTooltip (h,titleValue,columnRealWidth) {
    //创建一个span存储title信息，并获取其对应宽度，用于判断是否需要通过ei-tooltip显示完整信息
    let spanBox = document.createElement('span');
    spanBox.style.display = 'inline-block';
    spanBox.style.visibility = 'hidden';
    spanBox.style.fontSize = '14px'; //同表头显示栏位一致，否则可能出现计算宽度不准确
    spanBox.style.fontFamily = 'siyuanregular'; //同表头显示栏位一致，否则可能出现计算宽度不准确
    document.body.appendChild(spanBox);
    if(typeof spanBox.textContent != 'undefined'){
        spanBox.textContent = titleValue
    }else{
        spanBox.innerText = titleValue
    }
    let tooltipBox = ''
    let spanWidth = parseFloat(window.getComputedStyle(spanBox).width);
    //获取到span对应宽度之后，将span移除
    spanBox.remove();
    //当span内容大于父节点column真实宽度时，隐藏超出内容，并实现悬停显示
    //此时column显示得时候有控制左右padding各10px，此处需增加扣减20px
    if(spanWidth > (columnRealWidth - 20 ))
    {
        tooltipBox = 
        <el-tooltip
            content = {titleValue}
            placement = "top" //悬停内容展示位置
        >
            <span>{titleValue}</span> 
        </el-tooltip>
    }else{
        tooltipBox = <span>{titleValue}</span>
    }
    return tooltipBox;
}

export {renderTooltip}