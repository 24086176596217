<template>
    <Frame>
        <PageHeader :title="title" :hasBack="true" detailType="HelpCenterDetail"/>
        <div class="body-container">
            <span class="timeinfo">{{ timeinfo }}</span>
            <div class="helpinfo" ref="innerhtml" :style="height"></div>
        </div>
    </Frame>
</template>

<script>
export default {
    data() {
        return {
            title: "",
            html: "",
            helpId: "",
            LastOperateTime: "",
            CreateTime: "",
            timeinfo: "",
            height: "height:" + (window.innerHeight - 237) + "px;",
        }
    },
    methods: {
    },
    created() {
        this.title = this.$route.query.HelpEssay;
        this.helpId = this.$route.query.helpId;
    },
    mounted() {
        let that = this
        window.onresize = () => {
            if (that.resizeFlag) {
                clearTimeout(that.resizeFlag);
            }
            that.resizeFlag = setTimeout(() => {
                that.tableHeight = window.innerHeight - 237
                that.resizeFlag = null;
            }, 100);
        }
        this.axiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx",
            {
                action: "gethelpdetail",
                helpId: this.helpId
            },
            (res) => {
                var data = eval(res.data);
                console.log(data);
                this.LastOperateTime = data[0].LastOperateTime;
                this.CreateTime = data[0].CreateTime;
                this.timeinfo = '【  ' + this.$t('HelpCenter.updatetime') + ':  ' + this.LastOperateTime + " " + this.$t('HelpCenter.publishtime') + ': ' + this.CreateTime + '  】';
                var html = data[0].html;
                this.$refs.innerhtml.innerHTML = html;
            })
    }


}
</script>

<style scoped>
.body-container {
    width: calc(100% - 40px);
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center
}

.timeinfo {
    width: 100%;
    padding: 20px 0;
}

.helpinfo {
    overflow: auto;
    width: 100%;
}
</style>