<template>
  <Frame>
    <PageHeader :title="$t('comm.orderchangesearch')" :hasBack="hasBack" />
    <div class="order" style="padding:10px 0;">
      <div class="ordermessage">
        <span class="detialmessage">{{ $t('Order.messagenumber') }}：<el-input :readonly="this.isDetialHref" :placeholder="$t('Order.inputpls')+'（'+ $t('OrderVary.billNo') +'、'+ $t('OrderVary.purBillNo')+'）'"
            v-model="billno" clearable style="width:15%;min-width: 240px; color:#bbbbbb;" size="small"
            @change="inputchange(billno);"></el-input></span>
        <span v-if="!isDetialHref" class="detialmessage">{{ $t('OrderVary.compName') }}：<el-input :placeholder="$t('Order.inputpls')"
        v-model="compName" clearable style="width:15%;min-width: 240px;color:#bbbbbb;" size="small"
        @change="inputchange(compName);"></el-input></span>
        <span style="float:right;padding-right:20px;padding-bottom:20px;">
          <el-button v-if="!isDetialHref" type="reset" @click="reset()">{{ $t('button.reset') }}</el-button>
          <el-button type="primary" @click="query()">{{ $t('button.query') }}</el-button>
        </span>
      </div>      
      <el-table ref="multipleTable" :data="tableData" @expand-change="expandChange" :height="tableHeight" style="margin:0;width:100%"
        v-loading="tableLoading" tooltip-effect="dark" :header-cell-style="moneyclass">
        <el-table-column prop="BillNo" :label="$t('OrderVary.billNo')">
        </el-table-column>
        <el-table-column prop="purBillNo" :label="$t('OrderVary.purBillNo')">
        </el-table-column>
        <el-table-column prop="BillDate" :label="$t('Order.messagedate')" style="padding-right:50px">
          <template scope="scope">
            <span v-html="formatDate(scope.row.BillDate)"></span>
        </template>
        </el-table-column>
        <el-table-column prop="CompName" :label="$t('OrderVary.compName')">
        </el-table-column>
        <el-table-column prop="OAmountWithTax" align="right" :label="$t('Order.amount')">
        </el-table-column>
        <el-table-column prop="CurrId" :label="$t('OrderVary.currId')">
        </el-table-column>
        <el-table-column :label="$t('Order.operation')" width="60" type="expand" class="childtable">
          <template slot-scope="parentdata">
                <div style="width:100%;overflow-x:auto;position: relative; background-color: #f2f2f2;">
                  <div class="rowOne">
                    <div class="changeinfo-front changeinfo-title">
                      <div class="line1">{{$t('OrderVary.rowNo')}}</div>
                      <div class="line2">{{$t('OrderVary.varyKind')}}</div>
                      <div class="line3">{{$t('OrderVary.materialId')}}</div>
                      <div class="line4">{{$t('OrderVary.materialName')}}</div>
                      <div class="line5">{{$t('OrderVary.materialSpec')}}</div>
                    </div>
                    <div class="changeinfo-tail changeinfo-title">
                      <div class="line6"></div> <!-- 变更前/后 -->
                      <div class="line7">{{$t('OrderVary.sQuantity')}}</div>
                      <div v-if="$store.state.ShowBasicUnit" class="line8">{{$t('OrderVary.quantity')}}</div>
                      <div class="line9">{{$t('OrderVary.presentSQty')}}</div>
                      <div class="line10">{{$t('OrderVary.sPrice')}}</div>
                      <div class="line11">{{$t('OrderVary.oAmountWithTax')}}</div>
                      <div class="line12">{{$t('OrderVary.deliveryDate')}}</div>
                      <div v-if="$store.state.ShowMTO" class="line12">{{$t('OrderDetailSearch.txtMTONo')}}</div>
                      <div class="line12">{{$t('OrderDetailSearch.txtBatchNo')}}</div>
                      <div class="line13">{{$t('OrderVary.address')}}</div>
                      <div class="line14">{{$t('OrderVary.remark')}}</div>
                      <div class="line14">{{$t('OrderVary.rowState')}}</div>
                    </div>
                  </div>
                    <div style="width:100%;" v-for="v in parentdata.row.childData" >
                        <div class="paddingLeft">
                          <!-- background-color: #fbfbfb; -->
                              <div class="changeinfo-front" style="border-top: 1px solid #d0d0d0;">
                                <div class="line1"><span>{{v.varyField.RowNo}}</span></div>
                                <div class="line2">
                                  <span v-if="v.varyField.VaryKind == 2">{{$t('OrderVary.update')}}</span>
                                  <span v-if="v.varyField.VaryKind == 1" class="diff">{{$t('OrderVary.addnew')}}</span>
                                  <span v-if="v.varyField.VaryKind == 3" class="diff">{{$t('OrderVary.delete')}}</span>
                                </div>
                                <div :class="(v.varyField.VaryKind ===1 || v.varyField.VaryKind ===3) ? 'diff line3':'line3'"><span>{{v.varyField.MaterialId}}</span></div>
                                <div class="line4"><span>{{v.varyField.MaterialName}}</span></div>
                                <div class="line5"><span>{{v.varyField.MaterialSpec}}</span></div>
                              </div>
                            
                                <div class="changeinfo-tail" style="flex-wrap:wrap">
                                  <!-- background-color: #fbfbfb; -->
                                  <div style="width:100%;display:flex;min-height: 50px;  border-top: 1px solid #d0d0d0;">
                                    <div class="line6">
                                      <span v-if="v.editVary">{{ $t('OrderVary.unVaryField')}}</span>
                                      <span v-if="!v.editVary"> ———— </span>
                                    </div>
                                    <div class="line7"><span>{{!v.editVary?v.varyField.SQuantity:v.unVaryField.SQuantity}}{{ v.varyField.SUnitName }}</span></div>
                                    <div v-if="$store.state.ShowBasicUnit" class="line8"><span>{{!v.editVary?v.varyField.Quantity:v.unVaryField.Quantity}}{{ v.varyField.UnitName }}</span></div>
                                    <div class="line9"><span>{{!v.editVary?v.varyField.PresentSQty:v.unVaryField.PresentSQty}}{{ v.varyField.SUnitName }}</span></div>
                                    <div class="line10"><span>{{!v.editVary?v.varyField.SPrice:v.unVaryField.SPrice}}</span></div>
                                    <div class="line11"><span>{{!v.editVary?v.varyField.OAmountWithTax:v.unVaryField.OAmountWithTax}}</span></div>
                                    <div v-if="$store.state.ShowMTO" class="line12"><span>{{String(!v.editVary?v.varyField.DeliveryDate:v.unVaryField.DeliveryDate).replace(patten, "$1-$2-$3")}}</span></div>
                                    <div class="line12"><span>{{!v.editVary?v.varyField.MTONo:v.unVaryField.MTONo}}</span></div>
                                    <div class="line12"><span>{{!v.editVary?v.varyField.BatchNo:v.unVaryField.BatchNo}}</span></div>
                                    <div class="line13" :title="!v.editVary?v.varyField.Address:v.unVaryField.Address"><span>{{v.varyField.Address}}</span></div>
                                    <div class="line14" :title="!v.editVary?v.varyField.Remark:v.unVaryField.Remark"><span>{{v.varyField.Remark}}</span></div>
                                    <div class="line14" :title="!v.editVary?v.varyField.RowStateName:v.unVaryField.RowStateName"><span>{{v.varyField.RowStateName}}</span></div>
                                  </div>
                                  <!-- background-color: #fbfbfb; -->
                                  <div v-if="v.editVary" style="width: 100%;display:flex;min-height: 50px;" >
                                    <div class="line6"><span>{{ $t('OrderVary.varyField')}}</span></div>
                                    <div :class="(v.varyField.VaryKind == 2 && v.unVaryField.SQuantity != v.varyField.SQuantity) ? 'diff line7':'line7'"><span>{{v.varyField.SQuantity}}{{ v.varyField.SUnitName }}</span></div>
                                    <div v-if="$store.state.ShowBasicUnit" :class="(v.varyField.VaryKind == 2 && v.unVaryField.Quantity != v.varyField.Quantity) ? 'diff line8':' line8'"><span>{{v.varyField.Quantity}}{{ v.varyField.UnitName }}</span></div>
                                    <div :class="(v.varyField.VaryKind == 2 && v.unVaryField.PresentSQty != v.varyField.PresentSQty) ? 'diff line9':'line9'"><span>{{v.varyField.PresentSQty}}{{ v.varyField.SUnitName }}</span></div>
                                    <div :class="(v.varyField.VaryKind == 2 && v.unVaryField.SPrice != v.varyField.SPrice) ? 'diff line10':'line10'"><span>{{v.varyField.SPrice}}</span></div>
                                    <div :class="(v.varyField.VaryKind == 2 && v.unVaryField.OAmountWithTax != v.varyField.OAmountWithTax) ? 'diff line11':'line11'"><span>{{v.varyField.OAmountWithTax}}</span></div>
                                    <div :class="(v.varyField.VaryKind == 2 && v.unVaryField.DeliveryDate != v.varyField.DeliveryDate) ? 'diff line12':'line12'"><span>{{String(v.varyField.DeliveryDate).replace(patten, "$1-$2-$3")}}</span></div>
                                    <div v-if="$store.state.ShowMTO" :class="(v.varyField.VaryKind == 2 && v.unVaryField.MTONo != v.varyField.MTONo) ? 'diff line12':'line12'"><span>{{v.varyField.MTONo}}</span></div>
                                    <div :class="(v.varyField.VaryKind == 2 && v.unVaryField.BatchNo != v.varyField.BatchNo) ? 'diff line12':'line12'"><span>{{v.varyField.BatchNo}}</span></div>
                                    <div :class="(v.varyField.VaryKind == 2 && v.unVaryField.Address != v.varyField.Address) ? 'diff line13':'line13'" :title="v.varyField.Address"><span>{{v.varyField.Address}}</span></div>
                                    <div :class="(v.varyField.VaryKind == 2 && v.unVaryField.Remark != v.varyField.Remark) ? 'diff line14':'line14'" :title="v.varyField.Remark"><span>{{v.varyField.Remark}}</span></div>
                                    <div :class="(v.varyField.VaryKind == 2 && v.unVaryField.RowState != v.varyField.RowState) ? 'diff line14':'line14'" :title="v.varyField.RowStateName"><span>{{v.varyField.RowStateName}}</span></div>
                                  </div>
                                </div>                            
                        </div> 
                    </div>
                </div>
                
            </template>
        </el-table-column>
      </el-table>
      <div style="width:100%;display:flex;">
        <PageSizeChanger style="margin-left: auto;"  :pagesize.sync="pagesize"  :afterChange="pageSizeChange"></PageSizeChanger>
        <el-pagination ref="pagination" style="width:25%;margin:2px auto 0 0;" @current-change="handleCurrentChange"
          layout="total,prev,pager,next,jumper" :pager-count="5" :current-page.sync="currentPage" :total="totalCount" :page-size="$store.state.pagesize">
        </el-pagination>
      </div>
    </div>
  </Frame>
</template>

<script>
import SvgIcon from '../components/SvgIcon'
import { handleShortDate } from '../utils/handleDate.js'
import PageSizeChanger from '@/components/PageSizeChanger.vue'
export default {
  name: 'Order',
  components: { SvgIcon,PageSizeChanger },
  data() {
    return {
      hasBack:false,
      isDetialHref: false,
      tableLoading: false,
      batchexport: false,
      batchconfirmation: false,
      tableHeight: window.innerHeight - 271,
      billno: '',
      compName:'',
      billdate: '',
      timeDefault:'',
      // radio: 'exportTextA',
      // checked: '',
      startdate: 0,
      enddate: 99999999,
      unshipped: false,
      unconfirmedchanges: true,
      confirm: false,
      unsignedconfirmation: true,
      unshippe: true,
      partialDel: true,
      allshipments: false,
      currentPage: 1,
      totalCount: 0,
      pagesize: 10,
      pageindex: 0,
      tableData: [],
      patten: /(\d{4})(\d{2})(\d{2})/,
      // selectData: [],
      // confirmtext:'',
      // printvisible: false, //使用打印功能
      // dialogvisible: false,
      // dialogloading: true,
      // dialogtitle: '',
      // printType: '',
      // printtemp: '',
      // typegather: [],
      progId: 'purPurchaseOrder',
      billOrgId: '',
      billTypeId: ''
    }
  },
  created() {
    if (this.$route.query.partialDel && this.$route.query.unshippe) {
      this.partialDel = true;
      this.unshippe = true;
      this.unsignedconfirmation = false;
      this.unconfirmedchanges = false;
    }
    if (this.$route.query.unsignedconfirmation&&this.$route.query.unconfirmedchanges) {
      this.unsignedconfirmation = true;
      this.unconfirmedchanges = true;
      this.partialDel = false;
      this.unshippe = false;
    }
    if (this.$route.query.date && this.$route.query.startdate && this.$route.query.enddate) {
      this.billdate = JSON.parse(this.$route.query.date);
      this.startdate = this.$route.query.startdate;
      this.enddate = this.$route.query.enddate;
    }
    if(this.$route.query.BillNo){
      this.billno = this.$route.query.BillNo;
    }
    if (sessionStorage.getItem("OrderDetail") && sessionStorage.getItem("OrderDetail") != "null") {
      var history = JSON.parse(sessionStorage.getItem("OrderDetail"));
      if(history.hasBack && history.hasBack!='undefined') this.hasBack = history.hasBack;
      if(history.isDetialHref && history.isDetialHref!='undefined') this.isDetialHref = history.isDetialHref;
      if(history.billno && history.billno!='undefined')this.billno = history.billno;
      if(history.compName && history.compName!='undefined') this.compName = history.compName;
      if(history.billdate && history.billdate!='undefined')this.billdate = history.billdate;
      if(history.startdate && history.startdate!='undefined')this.startdate = history.startdate;
      if(history.enddate && history.enddate!='undefined')this.enddate = history.enddate;
      if(history.unshipped && history.unshipped!='undefined')this.unshipped = history.unshipped;
      if(history.unconfirmedchanges && history.unconfirmedchanges!='undefined')this.unconfirmedchanges = history.unconfirmedchanges;
      if(history.confirm && history.confirm!='undefined')this.confirm = history.confirm;
      if(history.unsignedconfirmation && history.unsignedconfirmation!='undefined')this.unsignedconfirmation = history.unsignedconfirmation;
      if(history.unshippe && history.unshippe!='undefined')this.unshippe = history.unshippe;
      if(history.partialDel && history.partialDel!='undefined')this.partialDel = history.partialDel;
      if(history.allshipments && history.allshipments!='undefined') this.allshipments = history.allshipments;
      if(history.pageindex && history.pageindex!='undefined') this.pageindex = history.pageindex;
      this.getorderdata(this.pageindex);
      sessionStorage.removeItem("OrderDetail");
    }
    else this.getorderdata(0);
  },
  mounted() {
    let that = this;
    window.onresize = () => {
      if (that.resizeFlag) {
        clearTimeout(that.resizeFlag);
      }
      that.resizeFlag = setTimeout(() => {
        that.tableHeight = window.innerHeight - 271;
        that.resizeFlag = null;
      }, 100);
    }
    this.timeDefault =new Date();
    this.timeDefault.setMonth(new Date().getMonth()-1);

  },
  methods: {      
    formatDate(date) {
      let patten = /(\d{4})(\d{2})(\d{2})/; 
      let formatDate = date.toString().replace(patten, "$1-$2-$3");
      return formatDate;
    },
    inputchange(val) {
      this.getorderdata(0);
    },
    getorderdata(pageindex) {
      this.currentPage = pageindex + 1;
      this.tableLoading = true;
      this.axiosInvoke("/SupplierComm/Handler/OrderPurPurchaseHandler.ashx", {
        action: "getordervary",
        isDetialHref: this.isDetialHref,
        billno: this.billno,
        compName: this.compName,
        pageindex:pageindex,
        pagesize:this.pagesize,
        startdate: this.startdate,
        enddate: this.enddate
      },
        (res) => {
          var data = eval(res.data); // eval(res.data);
          this.totalCount = data[0].totalcount;
          data.shift(0);
          let usingData = [];          
          data.forEach( v =>{
            if(v.BillNo != "") {
              v.childData = []; 
              v.editVary = false; 
              usingData.push(v)}
          });
          this.tableData = usingData; // data;
          this.tableLoading = false;
          if(this.tableData.length <= 0 && this.isDetialHref) {
            this.$store.commit("TIPPOP",{popType:'warning',popMessage: this.$t('OrderVary.noData')});
          }          
        })
    },
    moneyclass({row,column,rowIndex,columnIndex}){
      // if(rowIndex==0&&columnIndex==3){
      //   return "padding-right:40px;"
      // }
    },
    expandChange(row){
      this.tableHeight = window.innerHeight - 271;
      this.tableLoading = true;
      let billNo = row.purBillNo;
      let varyBillNo = row.BillNo;
      this.axiosInvoke("/SupplierComm/Handler/OrderPurPurchaseHandler.ashx", {
        action: "getorderdetailvary",
        billNo: billNo,
        varyBillNo: varyBillNo
      },
        (res,errflag) => {
          if(errflag) this.tableLoading = false;
          let data = eval(res.data);
          let tempchangeData = eval("("+data[0]+")").detailData;
          if(tempchangeData && tempchangeData.items) {
            let items = tempchangeData.items;
            // data.forEach( v =>{
            //   if(v.BillNo != "") {
            //     v.childData = []; 
            //     // v.editVary = false; 
            //     usingData.push(v)
            //   }
            // });
            row.childData = [];
            items.forEach(item =>{
              item.editVary = false;
              if(item.varyField.VaryKind === 2) {
                // row.editVary = true;
                item.editVary = true
              }
              if(typeof(item.unVaryField)!='undefined' && typeof(item.unVaryField.RowState)!='undefined'){
                var unVaryRowState = item.unVaryField.RowState;
                switch(unVaryRowState){
                  case 0:
                    item.unVaryField.RowStateName = this.$t('OrderVary.rowStateName1'); //'正常';
                  break;
                  case 1:
                    item.unVaryField.RowStateName = this.$t('OrderVary.rowStateName2'); //'终止';
                  break;
                  case 2:
                    item.unVaryField.RowStateName = this.$t('OrderVary.rowStateName3'); //'转单冻结';
                  break;
                  default:
                    item.unVaryField.RowStateName = item.unVaryField.RowState;
                    break;
                }
              }
              if(typeof(item.varyField)!='undefined' && typeof(item.varyField.RowState)!='undefined'){
                var varyRowState = item.varyField.RowState;
                switch(varyRowState){
                  case 0:
                    item.varyField.RowStateName = this.$t('OrderVary.rowStateName1'); //'正常';
                  break;
                  case 1:
                    item.varyField.RowStateName = this.$t('OrderVary.rowStateName2'); //'终止';
                  break;
                  case 2:
                    item.varyField.RowStateName = this.$t('OrderVary.rowStateName3'); //'转单冻结';
                  break;
                  default:
                    item.varyField.RowStateName = item.varyField.RowState;
                    break;
                }
              }
            });
            row.childData = items; // 对接取回二开接口传回数据，获取变更详情
           // console.log(items);
          }
          this.tableLoading = false;
        })
    },
    reset() {//重置
      this.billno = "";
      this.compName = "",
      this.pageindex = 0;
      // this.billdate = "";
      this.startdate = 0;
      this.enddate = 99999999;
      this.getorderdata(0);
    },
    query() {//查询
      console.log(this.billdate);
      this.getorderdata(0);
      this.currentPage = 1;
    },
    
    // checkall() {
    //   if (this.checked) this.$refs.multipleTable.toggleAllSelection();
    //   else this.$refs.multipleTable.clearSelection();
    // },
    //获取时间
    getTime() {
      this.startdate = handleShortDate(this.billdate[0], "From");
      this.enddate = handleShortDate(this.billdate[1], "To");
      this.getorderdata(0);
    },
    //翻页处理
    handleCurrentChange(val) {
      this.pageindex = val - 1;
      this.getorderdata(val - 1);
    },
    pageSizeChange(){
      this.getorderdata(0);
    }
  }
}
</script>

<style scoped>
.detialmessage {
  color: #5d5d5d;
  font-size: 14px;
  width: 20%;
  padding-left: 10px;
  padding-right: 10px;
}

.moneyornumber {
  padding-right: 40px;
}

.ordermessage {
  padding: 8px 20px;
}

.el-checkbox /deep/ .el-checkbox__label {
  color: #5d5d5d;
  font-size: 14px;
}

.stateborder {
  padding: 2px 8px;
  border-radius: 15px;
  border: 1px solid;
}

.el-table /deep/ th.el-table__cell {
  background-color: #f9f9f9;
}

.el-table /deep/ .el-table__body-wrapper {
  font-size: 13px;
  overflow-y: auto;
}

.el-table /deep/ .cell {
  overflow: initial;
}

/* 调整【确认状态】栏位英文版显示文字换行导致样式错乱问题（当前分辨率：1440*900） */
.el-table /deep/ .cell span {
  display: inline-block;
  white-space: nowrap;
}
.el-table /deep/ thead {
  font-size: 14px;
  color: #5d5d5d;
}

.el-table /deep/ .el-table__header .cell .el-checkbox:first-child {
  padding-left: 5px;
}

.topbutton {
  color: #0177d5;
  font-size: 14px;
}

.topbutton:hover {
  color: #5aacff;
}

.img {
  padding-right: 8px;
  height: 12px;
  width: 12px;
}

.el-dialog__wrapper /deep/ .el-dialog .el-dialog__header {
  border-bottom: 1px solid rgb(235, 233, 233);
}

.el-dialog__wrapper /deep/ .el-dialog .el-dialog__body {
  border-bottom: 1px solid rgb(235, 233, 233);
}

.batchconfirmation /deep/ .el-dialog .el-dialog__header {
  display: none;
}

.batchconfirmation /deep/ .el-dialog .el-dialog__body {
  text-align: center;
}

.titleBox{
  font-weight: bold;
  background-color: #f9f9f9;
  padding-left: 10px;
}
.titleBox div{
  line-height: 50px;
}
.floatLeft{
  float: left;
}
.paddingLeft{
  display: flex;
}

.diff{
  color: red;
}
.rowDouble {
  height: 100px;
  line-height: 100px;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.rowOne {
  width: 100%;
  display: flex;
  height: 50px;
  line-height: 50px;
  position: relative;
  white-space: nowrap;
}

.rowDouble div{
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


.changeinfo-front{
  position: sticky;
  left: 0;
  z-index: 1;
  width: calc( 34% + 10px );
  display: flex;
  min-height: 50px;
  min-width: 410px;
  padding-left: 10px;
  background-color:#f2f2f2;
}
.changeinfo-tail span,.changeinfo-front span{
  margin: auto 0;
  word-break: break-all;
}
.changeinfo-title{
  font-weight: bold;
  background-color: #f2f2f2;
}
.changeinfo-tail{
  width: calc( 66% - 10px );
  min-width: 1050px;
  min-height: 50px;
  display: flex;
}
.line1{
  width: 5%;
  min-width: 50px;
}

.line2{
  width: 17%;
  min-width: 70px;
}

.line3{
  width: 26%;
  min-width: 100px;
}
.line4{
  width: 26%;
  min-width: 90px;
}
.line5{
  width: 26%;
  min-width: 90px;
}
.line1,.line2,.line3,.line4,.line5,.line6,.line12,.line13,.line14{
  display: flex;
}
.line6{
   width: 7%;
   position: sticky;
   left: 0;
  min-width: 70px;
}
.line7,.line8,.line9,.line10,.line11{
  display: flex;
  justify-content: right;
  width: 11%;
  min-width: 90px;
  text-align: right;
}
.line12{
  padding-left: 1%;
  width: 10%;
  min-width: 90px;
}
.line13{
  width: 16%;
  min-width: 120px;
}
.line14{
  padding-left: 1%;
  width: 14%;
  min-width: 90px;
}
</style>
