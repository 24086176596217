<template>
  <Frame>
    <PageHeader :title="$t('comm.sortfile')" :hasBack="false" />
    <div>
      <div class="search-row">
        <p class="label">{{ $t('Download.name') }}:</p><el-input size="small" v-model="searchinput" @change="inputchange()"
          :placeholder="$t('Download.inputpls')" clearable></el-input>
        <p class="label">{{ $t('Download.type') }}:</p><el-select size="small" v-model="value" @change="search(0)">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"
            :disabled="item.disabled">
          </el-option>
        </el-select>
        <el-button @click="resetInput" plain>{{ $t('button.reset') }}</el-button>
        <el-button @click="search(0)" type="primary">{{ $t('button.search') }}</el-button>
      </div>
      <div class="download-form">
        <el-table v-loading="loading" :data="tableData" style="width: 100%;" :height="tableHeight">
          <el-table-column prop="type" :label="$t('Download.type')" width="140">
          </el-table-column>
          <el-table-column prop="name" :label="$t('Download.name')">
          </el-table-column>
          <el-table-column prop="intro" :label="$t('Download.intro')">
          </el-table-column>
          <el-table-column prop="date" :label="$t('Download.date')" width="200">
          </el-table-column>
          <el-table-column prop="option" :label="$t('Download.operation')" width="100">
            <template slot-scope="scope">
              <el-link v-if="scope.row.accessorys.length>0&&!scope.row.accessorys[0].NoPowerDownload" :underline="false" @click="downloadFile(scope.$index, scope.row)" type="primary">
              {{$t('Download.download')}}</el-link>
              <span style="color:#606266;" v-if="scope.row.accessorys.length==0"> {{$t('Download.noacc')}}</span>
              <span style="color:#606266;" v-if="scope.row.accessorys.length>0&&scope.row.accessorys[0].NoPowerDownload"> {{$t('Download.nopower')}}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <el-pagination style="padding-left:50%;width:50%;" @current-change="handleCurrentChange"
          layout="total,prev,pager,next,jumper" :pager-count="5" :current-page="currentPage" :total="totalCount">
        </el-pagination>
      </div>
      <el-dialog :title="dialogtitle" :visible.sync="downloadDialog" width="700px"
        @opened="dialogOpened" :before-close="handleClose">
        <el-table :data="dialogData" style="width:100%"  height="40vh" @select-all="handleSelectAll"
                @selection-change="handleSelectionChange" ref="dialogview" v-loading="dialoading">
          <el-table-column type="selection"></el-table-column>
          <el-table-column prop="index" width="100" :label="$t('Download.number')">
          </el-table-column>
          <el-table-column prop="accessoryName" :label="$t('Download.accessoryname')">
          </el-table-column>
          <el-table-column prop="option" :label="$t('Download.operation')" width="100">
            <template slot-scope="scope">
              <el-link :underline="false" @click="downloadmethod(scope.$index, [scope.row],scope.row.FileId)" type="primary">
              {{$t('Download.clickdownload')}}</el-link>
            </template>
          </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="downloadmethod(-1,diaselect,dialogData[0].FileId)">{{$t('Download.downloadall')}}</el-button>
        </span>
      </el-dialog>
    </div>
  </Frame>
</template>
<script>

export default {
  name: "SortFile",
  data() {
    return {
      options: [{ value: '', label: this.$t('comm.alltype') }],
      tableData: [],
      dialogData:[],
      diaselect: [],
      dialogtitle:'',
      value: "",
      searchinput: "",
      loading: false,
      tableHeight: window.innerHeight - 337,
      totalCount: 0,
      currentPage: 1,
      downloadDialog:false,
      dialoading:false,
      progId:"WebFileManager"
    }
  },
  created(){
    if(this.$route.query.Title){
      this.searchinput = this.$route.query.Title;
    }
  },
  mounted() {
    console.log(this.$route.path);
    let that = this
    window.onresize = () => {
      if (that.resizeFlag) {
        clearTimeout(that.resizeFlag);
      }
      that.resizeFlag = setTimeout(() => {
        that.tableHeight = window.innerHeight - 337
        that.resizeFlag = null;
      }, 100);
    }
    this.loading = true;
    this.search(0);
  },
  methods: {
    resetInput() {
      this.value = "";
      this.searchinput = "";
      this.search(0);
    },
    handleSelectionChange(val) {
        this.diaselect = val;
    },
    handleSelectAll(val) {
        this.diaselect = val;
    },
    search(pageindex) {
      this.axiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx",
        {
          action: "getdownloadinfo",
          input: this.searchinput,
          type: this.value,
          pageindex: pageindex,
          pagesize: 10
        },
        (res) => {
          var data = eval(res.data);
          console.log(data);
          this.totalCount = data[0].totalCount;
          data.shift(0);
          this.options = [{ value: '', label: this.$t('comm.alltype') }];
          this.options = this.options.concat(data[0]);
          data.shift(0);
          this.tableData = data;
          console.log(this.tableData);
          this.loading = false;
        }
      )
      //this.searchinput  输入框的内容 this.value 选择框的内容

    },
    inputchange(){
      this.search(0);
    },
    downloadFile(index, row) {
      console.log(index, row)//index 行号 row 行内�?
      if(row.accessorys.length>1){
        this.downloadDialog = true;
        this.dialoading =true;
        this.dialogtitle = this.$t('Download.download')+'('+row.name+')';
        this.dialogData = row.accessorys;
      }else{
        this.downloadmethod(index,row.accessorys,row.FileId);
      }
    },
    dialogOpened(){
      this.dialoading =false;
      this.$refs.dialogview.toggleAllSelection();
      this.diaselect = this.dialogData;
    },
    //附件下載
    downloadmethod(index,data,FileId){
      if(index== -1 && data.length==0){
        this.$store.commit("TIPPOP",{popType:'warning',popMessage:this.$t('comm.unselectwarning')});
      }else{
        for (let i = 0; i < data.length; i++) {
          setTimeout(() => {
            this.axiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx",{
              action:'updateaccopinfo',
              ProgId:this.progId,
              BillNo:FileId,
              AccessoryName:data[i].accessoryName,
              operation:'download'
            })
            let iframe = document.createElement("iframe");
            iframe.src = '/SupplierComm/Handler/AccessoryHandler.ashx?action=download&accessoryId=' + encodeURIComponent(data[i].accessoryId) + '&accessoryName=' + encodeURIComponent(data[i].accessoryName);
            iframe.style.display = "none";
            document.body.appendChild(iframe);
          }, 100)
        }
      }
     
    },
    handleClose(){
      debugger
      this.dialogData = [];
      this.diaselect = [];
      this.downloadDialog = false;
    },
    handleCurrentChange(val) {
      this.search(val - 1);
    }
  }
}
</script>

<style scoped>
.el-input {
  width: 22%;
}

.el-input__inner {
  font-family: "siyuanregular";
  margin: auto 0;
}

.el-input__inner::placeholder {
  font-family: "siyuanregular";
}

.el-select .el-input {
  width: 100%;
}

.el-select {
  width: 10%;
  margin-right: 33%;
}

.label {
  margin: auto 0;
  text-align: right;
  width: 9%;
  padding-right: 1%;
}

::v-deep .el-main {
  margin: 0;
}

.search-row {
  height: 32px;
  margin: 1.5rem 0;
  width: 100%;
  display: flex;
}

.search-row .el-button {
  padding: 9px 15px !important;
}

.download-form {
  width: 95%;
  padding: 25px 2.5%;
}

.pagination {
  width: 100%;
}
</style>