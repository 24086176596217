<template>
    <div class="pagesizeselect" v-show="ShowChanger">
        <el-select style="margin: auto 0;" v-model="$store.state.pagesize" size="small" @change="pageSizeChange">
            <el-option
                v-for="item in $store.state.pagesizes"
                :key="item.pagesize"
                :label="item.pagesize"
                :value="item.pagesize"
            >
            </el-option>
        </el-select>
        <span style="font-size: 13px;white-space: nowrap;margin: auto 0;">{{ $t('Deliver.pagesize') }}</span>
    </div>
</template>
<script>
export default {
    props:{
        ShowChanger:{
            type:Boolean,
            default:true
        },
        afterChange:{
            type:Function,
            default:()=>{}
        },
        pagesize:{
            type:Number,
            default:10,
        }
    },
    methods:{
        pageSizeChange(){
            localStorage.setItem("pagesize", this.$store.state.pagesize, "365D");
            this.$emit("update:pagesize",this.$store.state.pagesize);
            this.afterChange();
        }
    }
}
</script>
<style scoped>
.pagesizeselect{
    display: flex;
    flex-wrap: nowrap;
    padding: 0 8.5px;
}
.pagesizeselect .el-select /deep/ .el-input--small .el-input__inner{
    width: 70px;
}
</style>
