<template>
    <Frame>
      <PageHeader :title="$t('comm.replydeliverytime')" :hasBack="this.hasBack" />
      <div class="order" style="padding:10px 0;">
        <div class="ordermessage">
          <span class="detialmessage"><span class="detiallabel">{{ $t('OrderDetailSearch.txtBillNo') }}：</span><el-input :placeholder="$t('Order.inputpls')"
              v-model="billno" clearable style="color:#bbbbbb;" size="small"
              @change="inputchange(billno);"></el-input></span>
          <span class="detialmessage" style="min-width:285px"><span class="detiallabel">{{ $t('OrderDetailSearch.txtBillDate') }}：</span><el-date-picker size="small"
              v-model="billdate" type="daterange" style=";color:#bbbbbb;" range-separator="~" @change="getTime()" :default-value="timeDefault"
              :start-placeholder="$t('comm.startdate')" :unlink-panels="true" :end-placeholder="$t('comm.enddate')"></el-date-picker></span>  
          <span class="detialmessage" ><span class="detiallabel">{{ $t('OrderDetailSearch.txtMaterialName') }}：</span><el-input :placeholder="$t('Order.inputpls')"
              v-model="materialName" clearable style="color:#bbbbbb;" size="small" @change="inputchange(materialName);"></el-input></span>
          <span class="detialmessage" ><span class="detiallabel">{{ $t('OrderDetailSearch.txtMaterialSpec') }}：</span><el-input :placeholder="$t('Order.inputpls')"
          v-model="materialSpec" clearable style="color:#bbbbbb;" size="small" @change="inputchange(materialSpec);"></el-input></span>
        </div>
        <div class="ordermessage">
          <span v-if="$store.state.ShowMTO" class="detialmessage" ><span class="detiallabel">{{ $t('OrderDetailSearch.txtMTONo') }}：</span><el-input :placeholder="$t('Order.inputpls')"
          v-model="mtoNo" clearable style="color:#bbbbbb;" size="small" @change="inputchange(compName);"></el-input></span>
          <span class="detialmessage" ><span class="detiallabel">{{ $t('OrderDetailSearch.txtBatchNo') }}：</span><el-input :placeholder="$t('Order.inputpls')"
          v-model="batchNo" clearable style="color:#bbbbbb;" size="small" @change="inputchange(compName);"></el-input></span>
          <span class="detialmessage" ><span class="detiallabel">{{ $t('OrderDetailSearch.txtCompName') }}：</span><el-input :placeholder="$t('Order.inputpls')"
          v-model="compName" clearable style="color:#bbbbbb;" size="small" @change="inputchange(compName);"></el-input></span>
        </div>
        <div class="ordermessage" style="justify-content: space-between;">
          <div style="display:flex;flex-wrap:nowrap;">
            <span class="detialmessage" style="width:auto">
            <span class="detiallabel" style="padding-right: 3%;">{{ $t('Order.goodsstate') }}：</span>
            <el-checkbox @change="getorderdata(0);" v-model="unshippe" :disabled="isDisabled" :label="$t('Order.unshipped')"></el-checkbox>
            <el-checkbox @change="getorderdata(0);" v-model="partialDel" :disabled="isDisabled" :label="$t('Order.partialDel')"></el-checkbox>
          </span>
          <span class="detialmessage" style="padding-left: 70px;">
            <span class="detiallabel" style="padding-right: 3%;">{{ $t('Order.confirmStateTxt') }}：</span>
            <el-checkbox @change="getorderdata(0);" v-model="confirmState1" :disabled="isDisabled" :label="$t('Order.confirmState1')"></el-checkbox>
            <el-checkbox @change="getorderdata(0);" v-model="confirmState2" :disabled="isDisabled" :label="$t('Order.confirmState2')"></el-checkbox>
            <el-checkbox @change="getorderdata(0);" v-model="confirmState3" :disabled="isDisabled" :label="$t('Order.confirmState3')"></el-checkbox>
          </span>
          </div>
          <span style="float:right;margin-right: 3%;">
            <el-button type="reset" @click="reset()">{{ $t('button.reset') }}</el-button>
            <el-button type="primary" @click="query()">{{ $t('button.query') }}</el-button>
          </span>
        </div>
        <el-table ref="multipleTable" :data="tableData" :height="tableHeight" style="margin:0;width:100%"
          v-loading="tableLoading" tooltip-effect="dark"  :header-cell-style="moneyclass">
          <el-table-column prop="BillNo" :width="WidthControl('BillNo',tableData,6)" :label="$t('OrderDetailSearch.txtBillNo')" style="padding-right:50px"></el-table-column>
          <el-table-column prop="CompName" :width="WidthControl('CompName',tableData,5)" :label="$t('OrderDetailSearch.txtCompName')" style="padding-right:50px"></el-table-column>
          <el-table-column prop="RowNo" :label="$t('OrderDetailSearch.txtRowNo')" style="padding-right:50px"></el-table-column>
          <el-table-column :width="WidthControl('MaterialId',tableData)" prop="MaterialId" :label="$t('OrderDetailSearch.txtMaterialId')" style="padding-right:50px"></el-table-column>
          <el-table-column :width="WidthControl('MaterialName',tableData)" prop="MaterialName" :label="$t('OrderDetailSearch.txtMaterialName')" style="padding-right:50px"></el-table-column>
          <el-table-column :width="WidthControl('MaterialSpec',tableData)" prop="MaterialSpec" :label="$t('OrderDetailSearch.txtMaterialSpec')" style="padding-right:50px"></el-table-column>
          <el-table-column prop="SQuantity" align="right" :min-width="WidthControl('SQuantity&&SUnitName',tableData)" :label="$t('OrderDetailSearch.txtSQuantity')" style="padding-right:50px">
            <template slot-scope="PerScope">
              <span size="small" >{{ PerScope.row.SQuantity }}&nbsp{{ PerScope.row.SUnitName }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="TotalSQty" align="right" :min-width="WidthControl('TotalSQty&&SUnitName',tableData)" :label="$t('OrderDetailSearch.txtTotalSQty')" style="padding-right:50px">
            <template slot-scope="PerScope">
              <span size="small" >{{ PerScope.row.TotalSQty }}&nbsp{{ PerScope.row.SUnitName }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="UnTransSQty" align="right" :min-width="WidthControl('UnTransSQty&&SUnitName',tableData)" :label="$t('OrderDetailSearch.txtUnTransSQty')" style="padding-right:50px">
            <template slot-scope="PerScope">
              <span size="small" >{{ PerScope.row.UnTransSQty }}&nbsp{{ PerScope.row.SUnitName }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="SPrice" align="right" :width="WidthControl('SPrice',tableData,6)" :label="$t('OrderDetailSearch.txtSPrice')" style="padding-right:50px"></el-table-column>
          <el-table-column prop="OAmountWithTax" :width="WidthControl('OAmountWithTax',tableData,6)" align="right" :label="$t('OrderDetailSearch.txtOAmountWithTax')" style="padding-right:50px"></el-table-column>
          <el-table-column prop="DeliveryDate" :width="WidthControl('DeliveryDate',tableData,6)" :label="$t('OrderDetailSearch.txtDeliveryDate')" min-width="90" style="padding-right:50px"></el-table-column>
          <el-table-column v-if="$store.state.ShowMTO" prop="MTONo" :width="WidthControl('MTONo',tableData,5)" :label="$t('OrderDetailSearch.txtMTONo')" style="padding-right:50px"></el-table-column>
          <el-table-column prop="BatchNo" :width="WidthControl('MTONo',tableData,5)" :label="$t('OrderDetailSearch.txtBatchNo')" style="padding-right:50px"></el-table-column>
          <el-table-column prop="UnTransSQty" width="100" :label="$t('OrderDetailSearch.txtBillState')">
            <template slot-scope="scope">
              <span :style="Number(scope.row.UnTransSQty.replace(/,/g,'')) == Number(scope.row.TotalSQty.replace(/,/g,''))  && Number(scope.row.TotalSQty.replace(/,/g,'')) > 0? 'display:block' : 'display:none'">
                <img src="../assets/img/border/unshipped.png" style="padding:0 5px 4px 0">
                {{ $t('Order.unshipped') }}</span>
              <span :style="Number(scope.row.UnTransSQty.replace(/,/g,'')) < Number(scope.row.TotalSQty.replace(/,/g,'')) && Number(scope.row.UnTransSQty.replace(/,/g,'')) > 0 ? 'display:block' : 'display:none'">
                <img src="../assets/img/border/partialdel.png" style="padding:0 5px 4px 0">
                {{ $t('Order.partialDel') }}</span>
              <span :style="Number(scope.row.UnTransSQty.replace(/,/g,'')) <= 0 ? 'display:block' : 'display:none'">
                <img src="../assets/img/border/shipped.png" style="padding:0 5px 4px 0">
                {{ $t('Order.shipped') }}</span>
            </template>
          </el-table-column>
          <el-table-column :width="WidthControl('Remark',tableData)" prop="Remark" :label="$t('OrderDetailSearch.txtRemark')" style="padding-right:50px"></el-table-column>  

          <!-- <el-table-column :label="$t('Order.operation')">
          <template slot-scope="scope">
            <el-button class="topbutton" size="mini" type="text" @export-change="handleExpandChange">
              {{ $t('button.replyDeliveryDate') }}
            </el-button>
          </template>
        </el-table-column> -->
        <el-table-column :label="$t('Order.operation')" type="expand" class="childtable" width="60" style="width:100px;">
            <template slot-scope="parentdata">
              <el-table ref="multipleTable" :data="parentdata.row.sonData" style="width: 70%; float: right; margin-left: 0" max-height="260" >
                <el-table-column prop="rownumber" :label="$t('ReplyDeliveryTime.rowNo')"></el-table-column>
                <el-table-column prop="FromBillNo" :label="$t('ReplyDeliveryTime.fromBillNo')">
                </el-table-column>
                <!-- <el-table-column prop="RowIndex" label="序号"></el-table-column> -->
                <el-table-column prop="ConfirmSQty" :label="$t('ReplyDeliveryTime.confirmSQty')" >
                  <template slot-scope="scope">
                    <el-input size="small" v-model="scope.row.ConfirmSQty" maxlength="19" style="max-width: 170px;" :placeholder="$t('ReplyDeliveryTime.pleaseEnter')" :readonly="scope.row.rowReadOnly"></el-input>
                    <!-- <span size="small" style="padding-left: 5px;">{{ parentdata.row.SUnitId }}</span> -->
                  </template>
                </el-table-column>
                <el-table-column>
                  <template>
                    <span size="small">{{ parentdata.row.SUnitName }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="ConfirmDelDate" :label="$t('ReplyDeliveryTime.confirmDelDate')"> 
                  <template slot-scope="scope">
                    <!-- <el-input type="date" v-model="scope.row.ConfirmDelDate" :readonly="scope.row.rowReadOnly"></el-input> -->
                    <el-date-picker size="small" v-model="scope.row.ConfirmDelDate" :readonly="scope.row.rowReadOnly"  style=" width: 130px; " :default-value="timeDefault" :unlink-panels="true" ></el-date-picker>
                  </template>
                </el-table-column>
                <el-table-column prop="ConfirmResult" :label="$t('ReplyDeliveryTime.confirmResult')">
                <template slot-scope="scope">
                    <el-input size="small" v-model="scope.row.ConfirmResult" maxlength="400" :placeholder="$t('ReplyDeliveryTime.pleaseEnter')" :readonly="scope.row.rowReadOnly"></el-input>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('ReplyDeliveryTime.operation')">
                  <template slot-scope="scope" v-if="!scope.row.rowReadOnly">
                    <el-button size="mini" type="danger"
                      @click="handleDataDelete(scope.$index, parentdata.row.sonData)">
                      {{ $t('button.delete') }}
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
              <div class="ordermessage" style="justify-content: flex-end; width:60%; float: right;">
                <span style="float:right; padding-right:20px;padding-bottom:20px;margin-top:0px;">
                    <el-button type="primary" @click="handleDataAdd(parentdata)">{{ $t('button.addnew') }}</el-button>
                    <el-button type="primary" @click="submitForm(parentdata)" :disabled="!canSubmit" >{{ $t('button.affirm') }}</el-button>
                </span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="width:100%;display:flex;">
          <PageSizeChanger style="margin-left: auto;"  :pagesize.sync="pagesize"  :afterChange="pageSizeChange"></PageSizeChanger>
          <el-pagination ref="pagination" style="margin:2px auto 0 0;" @current-change="handleCurrentChange"
            layout="total,prev,pager,next,jumper" :pager-count="5" :current-page.sync="currentPage" :total="totalCount" :page-size="$store.state.pagesize">
          </el-pagination>
        </div>  
      </div>
      <el-dialog width="25%" :title="$t('ReplyDeliveryTime.tip')" :visible.sync="showMsg" :append-to-body="true">
        <div v-if="this.isSuccessSubmit && !this.isNullDate">{{ $t('ReplyDeliveryTime.successMsg') }}</div>
        <div v-if="!this.isSuccessSubmit && !this.isNullDate">{{ $t('ReplyDeliveryTime.dbNullMsg') }}</div>
        <div v-if="!this.isSuccessSubmit && this.isNullDate">{{ $t('ReplyDeliveryTime.dateNullMsg') }}</div>
        <div slot="footer" class="dialog-footer">
            <el-button @click="closeMsg()" type="primary">{{ $t('button.determine') }}</el-button>
        </div>
    </el-dialog>
    </Frame>
  </template>
  
  <script>
  import SvgIcon from '../components/SvgIcon'
  import { handleShortDate } from '../utils/handleDate.js'
  import { getTimeoutVal } from '../utils/utils.js'
  import PageSizeChanger from '@/components/PageSizeChanger.vue'
  export default {
    name: 'OrderDetailSearch',
    components: { SvgIcon,PageSizeChanger },
    data() {
      return {
        hasBack:false,
        confirmState1:true, // 未确认：对应后台【未变更】【供应商要求变更】
        confirmState2:true, // 不同意修改交期：对应后台【不同意变更】
        confirmState3:true, // 同意修改交期：对应后台【已变更】
        billNoList:'', //单号列表（从消息区传值过来）
        isDisabled: false, //复选框按钮不可编辑
        canSubmit:true,
        isExpansion: true,
        tableLoading: false,
        batchexport: false,
        batchconfirmation: false,
        tableHeight: window.innerHeight - 361,
        billno: '',
        materialName: '',
        materialSpec:'',
        compName:'',
        batchNo: '',
        mtoNo: '',
        billdate: '',
        timeDefault:'',
        radio: 'exportTextA',
        checked: '',
        startdate: '',
        enddate: '',
        unshipped: false,
        unconfirmedchanges: true,
        confirm: false,
        unsignedconfirmation: true,
        unshippe: true,
        partialDel: true,
        allshipments: false,
        currentPage: 1,
        totalCount: 0,
        pagesize: 10,
        tableData: [],
        selectData: [],
        pageindex: 0,
        confirmtext:'',
        showMsg: false, //是否显示弹窗信息
        isSuccessSubmit: false, // 提交成功状态
        isNullDate: false, // 回复日期是否为空
        message:'',
        dialogvisible: false,
        dialogloading: true,
        dialogtitle: '',
        printType: '',
        printtemp: '',
        typegather: [],
        progId: 'purPurchaseOrder',
        billOrgId: '',
        billTypeId: ''
      }
    },
    created() {
      if(this.$route.query.billNoList){
        this.billNoList = this.$route.query.billNoList;
        this.hasBack = true;
        var tempConfirmState = this.$route.query.confirmState;
        switch(tempConfirmState){
          case '0':
          case '1':
            this.confirmState1 = true;
            this.confirmState2 = false;
            this.confirmState3 = false;
            break;
          case '2':
            this.confirmState1 = false;
            this.confirmState2 = true;
            this.confirmState3 = false;
            break;
          case '3':
            this.confirmState1 = false;
            this.confirmState2 = false;
            this.confirmState3 = true;
            break;
        }
      }
      if (this.$route.query.partialDel && this.$route.query.unshippe) {
        this.partialDel = true;
        this.unshippe = true;
        this.unsignedconfirmation = false;
        this.unconfirmedchanges = false;
      }
      if (this.$route.query.unsignedconfirmation&&this.$route.query.unconfirmedchanges) {
        this.unsignedconfirmation = true;
        this.unconfirmedchanges = true;
        this.partialDel = false;
        this.unshippe = false;
      }
      if (this.$route.query.date && this.$route.query.startdate && this.$route.query.enddate) {
        this.billdate = JSON.parse(this.$route.query.date);
        this.startdate = this.$route.query.startdate;
        this.enddate = this.$route.query.enddate;
      }
      if(this.$route.query.BillNo){
        this.billno = this.$route.query.BillNo;
      }
      if (sessionStorage.getItem("OrderDetailSearch") && sessionStorage.getItem("OrderDetailSearch") != "null") {
        var history = JSON.parse(sessionStorage.getItem("OrderDetailSearch"));
        this.billno = history.billno;
        this.materialName = history.materialName;
        this.compName = history.compName;
        this.batchNo = history.batchNo;
        this.mtoNo = history.mtoNo;
        this.billdate = history.billdate;
        this.startdate = history.startdate;
        this.enddate = history.enddate;
        this.unshipped = history.unshipped;
        this.unconfirmedchanges = history.unconfirmedchanges;
        this.confirm = history.confirm;
        this.unsignedconfirmation = history.unsignedconfirmation;
        this.unshippe = history.unshippe;
        this.partialDel = history.partialDel;
        this.allshipments = history.allshipments;
        this.pageindex = history.pageindex;
        this.getorderdata(history.pageindex);
        sessionStorage.removeItem("OrderDetailSearch");
      }
      else this.getorderdata(0);
    },
    mounted() {
      let that = this;
      window.onresize = () => {
        if (that.resizeFlag) {
          clearTimeout(that.resizeFlag);
        }
        that.resizeFlag = setTimeout(() => {
          that.tableHeight = window.innerHeight - 361;
          that.resizeFlag = null;
        }, 100);
      }
      this.timeDefault =new Date();
      this.timeDefault.setMonth(new Date().getMonth()-1);
  
    },
    methods: {
      //关闭弹窗
      closeMsg(){
        this.showMsg = false;
      },
      //删除一行
      handleDataDelete(index, childRowData) {
      //  this.dataList.splice(index,1);
      childRowData.splice(index,1);
      },
      //插入一行
      handleDataAdd(parentData){
        let childRowData = parentData.row.sonData
        let obj = {};
        // let nowDate = new Date();
        // let tempDate = {
        //   year : nowDate.getFullYear(),
        //   month : nowDate.getMonth()+1,
        //   date : nowDate.getDate()
        // }
        // let newMonth = tempDate.month > 9? tempDate.month : '0' + tempDate.month;
        // let day = tempDate.date > 9 ? tempDate.date : '0' + tempDate.date
        obj.rownumber = childRowData.length + 1; //记录显示当前序号
        obj.InternalKey = Date.now(); // 新增时获取时间戳，用于作为保存数据时的主键字段唯一标识
        obj.FromBillNo = parentData.row.BillNo, // '',
        obj.FromRowCode = parentData.row.RowCode, // '',
        obj.ConfirmSQty = parentData.row.UnTransSQty, //'',
        obj.ConfirmDelDate = parentData.row.DeliveryDate.replace(/[^\d]/g,"").replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3"),  // '2023-05-17',
        obj.ConfirmResult = '',
        obj.rowReadOnly = false
        childRowData.push(obj);
      },
      // 提交
      submitForm (parentData) {
        this.canSubmit = false;
        this.isSuccessSubmit = false;
        this.isNullDate = false;
        // 对象拷贝
        const copyObj = (obj = {}) =>{
          let newObj = null;
          // 判断是否需要继续进行递归
          if(typeof(obj) == 'object' && obj != null) {
            if(obj instanceof Date) {
              newObj = new Date();
              newObj.setTime(obj.getTime());
            } else {
              newObj = obj instanceof Array ? [] : {};
            }
            for(let i in obj) {
              newObj[i] = copyObj(obj[i]);
            }
          } else { // 如果不是对象直接赋值
            newObj = obj;
          }
          return newObj;
        }
        let childRowData = copyObj(parentData.row.sonData);
        if(childRowData && childRowData.length > 0) {
          childRowData.forEach((v) => {
            // 日期为空是不允许提交并弹窗提示
            if(typeof(v.ConfirmDelDate) != 'undefined' && null != v.ConfirmDelDate && "" != v.ConfirmDelDate) {
              // 增加类型判断，时间格式才触发格式化
              if(typeof(v.ConfirmDelDate) == 'object') v.ConfirmDelDate = this.formatTime(v.ConfirmDelDate);
            }
            else {
              this.isNullDate = true;
              this.showMsg = true;
              return false; // 存在空日期的时候，直接退出
            }
          })
          if(this.showMsg){
            this.setSubmitBtn();
            return;
          } 
        this.axiosInvoke("/SupplierComm/Handler/OrderPurPurchaseHandler.ashx", {
          action: "submitdelivery",
          materialId : parentData.row.MaterialId,
          materialSpec : parentData.row.MaterialSpec,
          totalSQty : parentData.row.TotalSQty,
          UnTransSQty : parentData.row.UnTransSQty,
          childData : JSON.stringify(childRowData)
        },
          (res,errflag) => {
            let isSuccess = res.data.success;
            if(isSuccess&&!errflag){
              this.isSuccessSubmit = true; // "回复提交成功！";
              this.showMsg = true;
            }        
          })
        }
        else{ // '空数据，请维护后再提交！';
          this.showMsg = true;
        }
        this.setSubmitBtn();
      },
      setSubmitBtn(){
        clearTimeout(this.timer);
          this.timer = setTimeout(()=>{
            this.submitloading = false;
            this.canSubmit = true;
          },1000);
      },
      inputchange(val) {
        this.getorderdata(0);
      },
      getorderdata(pageindex) {
        var timeVal= getTimeoutVal(); //设置计时器统一时间 //控制复选框更新频率500毫秒
        this.isDisabled = true;
        setTimeout(() =>{ 
          this.isDisabled = false;
        },timeVal);
        this.currentPage = pageindex + 1;
        this.tableLoading = true;
        this.axiosInvoke("/SupplierComm/Handler/OrderPurPurchaseHandler.ashx", {
          action: "getorderdetail",
          confirmState1:this.confirmState1,
          confirmState2:this.confirmState2,
          confirmState3:this.confirmState3,
          billNoList:this.billNoList,  //单号列表（从消息区传值过来）
          //isDetial:true, //订单明细查询
          isReplyDeliveryDate : true, //交期回复--订单明细查询
          pageindex: pageindex,
          pagesize: this.pagesize,
          billno: this.billno,
          materialName: this.materialName,
          materialSpec:this.materialSpec,
          compName: this.compName,
          batchNo: this.batchNo,
          mtoNo: this.mtoNo,
          startdate: this.startdate,
          enddate: this.enddate,
          unshipped: this.unshipped,
          unconfirmedchanges: this.unconfirmedchanges,
          confirm: this.confirm,
          unsignedconfirmation: this.unsignedconfirmation,
          unshippe: this.unshippe,
          partialDel: this.partialDel,
          allshipments: this.allshipments,
          rowState1:true, // 仅过滤行项状态是正常的物料
          excel: ''
        },
          (res) => {
            var data = eval(res.data);
            this.totalCount = data[0].totalCount;
            data.shift(0);
            this.tableData = data;
            this.tableLoading = false;
          })
      },
      moneyclass({row,column,rowIndex,columnIndex}){
        // if(rowIndex==0&&columnIndex==3){
        //   return "padding-right:40px;"
        // }
      },
      reset() {//重置
        this.billno = "";
        this.materialName = "";
        this.materialSpec = "";
        this.compName = "";
        this.batchNo = "",
        this.mtoNo = "",
        this.billdate = "";
        this.startdate = "";
        this.enddate = "";
        this.unshipped = false;
        this.unconfirmedchanges = true;
        this.confirm = false;
        this.unsignedconfirmation = true;
        this.unshippe = true;
        this.partialDel = true;
        this.allshipments = false;
        this.confirmState1 = true;
        this.confirmState2 = true;
        this.confirmState3 = true;
        this.getorderdata(0);
      },
      query() {//查询
        console.log(this.billdate);
        this.getorderdata(0);
        this.currentPage = 1;
      },
      toggleRowExpansion(){
        this.isExpansion = ! this.isExpansion;
        this.toggleRowExpansionAll(this.isExpansion);
      },
      toggleRowExpansionAll(isExpansion){
      },
      handleExpandChange(){
        const isExpand = rows.some(r => r.id ===row.id)
        if(isExpand){

        } else{

        }
      },
      batchshipment() {//批量发货
        var billnos = [];
        if (this.selectData.length > 0) {
          this.selectData.forEach((v) => {
            billnos.push(v.BillNo);
          })
          sessionStorage.setItem("OrderToDeliverShipment", JSON.stringify(billnos));
          this.$router.push({
            path: "/Home/Deliver",
            query: {}
          })
        } else {
          this.$store.commit("TIPPOP",{popType:'warning',popMessage:this.$t("Deliver.warning")});
        }
      },
      //格式化时间
      formatTime(date){
        return handleShortDate(date);
      },
      //获取时间
      getTime() {
        if(this.billdate){
        this.startdate = handleShortDate(this.billdate[0], "From");
        this.enddate = handleShortDate(this.billdate[1], "To");
      }
      else{
        this.startdate = "";
        this.enddate = "";
      }
        this.getorderdata(0);
      },
      //翻页处理
      handleCurrentChange(val) {
        this.pageindex = val - 1;
        this.getorderdata(val - 1);
      },
      pageSizeChange(){
        this.getorderdata(0);
      }
    }
  }
  </script>
  
  <style scoped>

.ordermessage {
  display: flex;
  justify-content: left;
  width: 99%;
  padding: 10px 0.5%;
  }

  .detialmessage {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    color: #5d5d5d;
    font-size: 14px;
    width: 21%;
    padding:0 0.5%;
  }
  @media screen and (max-width:1600px) {
    .detialmessage{
      width: 23%;
    }
  }
  .detiallabel{
    margin: auto 0;
  }
  .detialmessage .el-checkbox{
    margin:auto 3% auto 0;
  }
  /* .moneyornumber {
    padding-right: 40px;
  } */
  .detialmessage .el-date-editor--daterange.el-input__inner{
  width: 100%;
  }
  .el-checkbox /deep/ .el-checkbox__label {
    color: #5d5d5d;
    font-size: 14px;
  }
  
  .stateborder {
    padding: 2px 8px;
    border-radius: 15px;
    border: 1px solid;
  }
  
  .el-table /deep/ th.el-table__cell {
    background-color: #f9f9f9;
  }
  
  .el-table /deep/ .el-table__body-wrapper {
    font-size: 13px;
  }
  
  .el-table /deep/ .cell {
    overflow: initial;
  }
  
  /* 调整【确认状态】栏位英文版显示文字换行导致样式错乱问题（当前分辨率：1440*900） */
  .el-table /deep/ thead {
    font-size: 14px;
    color: #5d5d5d;
  }
  
  .el-table /deep/ .el-table__header .cell .el-checkbox:first-child {
    padding-left: 5px;
  }
  
  .topbutton {
    color: #0177d5;
    font-size: 14px;
  }
  
  .topbutton:hover {
    color: #5aacff;
  }
  
  .img {
    padding-right: 8px;
    height: 12px;
    width: 12px;
  }
  
  .el-dialog__wrapper /deep/ .el-dialog .el-dialog__header {
    border-bottom: 1px solid rgb(235, 233, 233);
  }
  
  .el-dialog__wrapper /deep/ .el-dialog .el-dialog__body {
    border-bottom: 1px solid rgb(235, 233, 233);
  }
  
  .batchconfirmation /deep/ .el-dialog .el-dialog__header {
    display: none;
  }
  
  .batchconfirmation /deep/ .el-dialog .el-dialog__body {
    text-align: center;
  }
  </style>
  