<template>
    <Frame>
        <PageHeader :title="$t('comm.deliver')" :hasBack="false" />
        <div class="deliver_contain">
            <div class="deliver_filter">
                <div class="filter_row row_width">
                    <div class="filter_col filter_flex">
                        <span>{{ $t('Deliver.prodId') }}:&nbsp;&nbsp;</span>
                        <el-input v-model="prodId" :placeholder="$t('Deliver.inputpls')"
                            @change="inputchange(prodId)" size="small" clearable></el-input>
                    </div>
                    <div class="filter_col filter_flex" v-if="$store.state.ShowMTO">
                        <span>{{ $t('Deliver.mtoNo') }}:&nbsp;&nbsp;</span>
                        <el-input v-model="mtoNo" :placeholder="$t('Deliver.inputpls')"
                            @change="inputchange(mtoNo)" size="small" clearable></el-input>
                    </div>
                    <div class="filter_col filter_flex" v-if="!$store.state.ShowMTO">
                        <span>{{ $t('Deliver.compName') }}:&nbsp;&nbsp;</span>
                        <el-input v-model="compName" :placeholder="$t('Deliver.inputpls')"
                            @change="inputchange(compName)" size="small" clearable></el-input>
                    </div>
                    <div class="filter_col filter_flex">
                        <span>{{ $t('Deliver.batchNo') }}:&nbsp;&nbsp;</span>
                        <el-input v-model="batchNo" :placeholder="$t('Deliver.inputpls')"
                            @change="inputchange(batchNo)" size="small" clearable></el-input>
                    </div>
                    <!-- <div class="withtaxState"></div> -->
                </div>
                <div class="filter_row row_width" v-if="isLoadMore">
                    <div class="filter_col filter_flex">
                        <span>{{ $t('Deliver.prodName') }}:&nbsp;&nbsp;</span>
                        <el-input v-model="prodName" :placeholder="$t('Deliver.inputpls')"
                            @change="inputchange(prodName)" size="small" clearable></el-input>
                    </div>
                    <div class="filter_col filter_flex" v-if="$store.state.ShowMTO">
                        <span>{{ $t('Deliver.compName') }}:&nbsp;&nbsp;</span>
                        <el-input v-model="compName" :placeholder="$t('Deliver.inputpls')"
                            @change="inputchange(compName)" size="small" clearable></el-input>
                    </div>
                    <div class="filter_col filter_flex">
                        <!-- ADV202307170005 供应商平台-是否含税栏位显示控件优化 -->
                    </div>
                    <!-- <div class="withtaxState"></div> -->
                </div>
                <div class="filter_row row_width">
                    <div class="filter_col filter_flex">
                        <span>{{ $t('Deliver.ProdSpec') }}:&nbsp;&nbsp;</span>
                        <el-input v-model="prodSpec" :placeholder="$t('Deliver.inputpls')"
                            @change="inputchange(prodSpec)" size="small" clearable></el-input>
                    </div>
                    <div class="filter_col filter_flex">
                        <span>{{ $t('Deliver.address') }}:&nbsp;&nbsp;</span>
                        <el-input v-model="address" :placeholder="$t('Deliver.inputpls')" @change="inputchange(address)" size="small" clearable></el-input>
                    </div>
                    <!-- ADV202307170005 供应商平台-是否含税栏位显示控件优化 -->
                    <div class="filter_col filter_flex">
                        <span>{{ $t('comm.includetax') }}：</span>
                        <el-checkbox v-model="withTax" @change="changeTaxState();" :disabled="isDisabled" class="taxcheckBox"></el-checkbox><span style="padding:0 25px 0 10px">{{ $t('comm.btnyes') }}</span>
                        <el-checkbox v-model="withoutTax" @change="changeTaxState();" :disabled="isDisabled" class="taxcheckBox"></el-checkbox><span style="padding:0 25px 0 10px">{{ $t('comm.btnno') }}</span>
                    </div>
                </div>
                <div class="filter_row row_width">
                    <div class="filter_col filter_flex">
                        <span>{{ $t('Deliver.deliverDate') }}:&nbsp;&nbsp;</span>
                        <el-radio-group v-model="deliverDate" @change="getselecttime()">
                            <el-radio-button :label="$t('Deliver.nearthreeday')"></el-radio-button>
                            <el-radio-button :label="$t('Deliver.nearsevday')"></el-radio-button>
                            <el-radio-button :label="$t('Deliver.nearthirtyday')"></el-radio-button>
                        </el-radio-group>
                    </div>
                    <div class="filter_col filter_flex">
                        <span>{{ $t('Deliver.custom') }}:&nbsp;&nbsp;</span>
                        <el-date-picker v-model="myDate" type="daterange" size="small" range-separator="~"
                            :start-placeholder="$t('comm.startdate')" :end-placeholder="$t('comm.enddate')"
                            :unlink-panels="true" @change="gettime()" :default-value="timeDefault">
                        </el-date-picker>
                    </div> 
                    <div class="filter_col filter_flex">
                        <span>{{ $t('Deliver.loadAllBill') }}:&nbsp;&nbsp;</span>
                        <el-checkbox v-model="isLoadAllBill" @change="changeTaxState()" :disabled="isDisabled" class="taxcheckBox"></el-checkbox>
                    </div>                    
                    <!-- <div class="filter_col filter_flex"></div>
                    <div class="filter_col filter_flex"></div> -->
                </div>
                <div class="filter_row">
                    <div class="filter_col filter_flex"></div>
                    <div class="filter_col filter_flex"></div>
                    <div class="filter_col filter_btns" style="width:40%;justify-content:right;">
                        <el-button v-if="!ordertodeliver&&!isexcel" @click="reset">{{ $t('button.reset') }}</el-button>
                        <el-dropdown v-if="ordertodeliver||isexcel" @command="reset">
                            <p class="dropdown">
                                {{ $t('button.reset') }}
                            </p>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item class="clearfix" command="">
                                    {{ $t('Deliver.resetnow') }}
                                </el-dropdown-item>
                                <el-dropdown-item class="clearfix" command="default">
                                    {{ $t('Deliver.resetdefault') }}
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                        <el-button @click="query()" type="primary">{{ $t('button.query') }}</el-button>
                        <el-button @click="loadMore()" v-if="!isLoadMore">{{ $t('button.loadMore') }}</el-button>
                        <el-button @click="loadMore()" v-if="isLoadMore">{{ $t('button.hideMore') }}</el-button>
                        <el-button icon="el-icon-upload2" @click="downloadtem">{{ $t('button.uploadtem') }}</el-button>
                        <el-button icon="el-icon-upload2" @click="uploadtem">{{ $t('button.upload') }}</el-button>
                    </div>
                </div>
            </div>
            <div class="deliver_table">
                <el-table :data="tableData" ref="multipleTable" v-loading="tableLoading" :height="tableHeight"
                    @select-all="handleSelectAll" @select="handleSelect" @sort-change="sortchange" :default-sort="{prop:this.orderprop,order:this.sortorder}">
                    <el-table-column type="selection" key="selection" @click.="handleClick" :width="45"></el-table-column>
                    <el-table-column :width="WidthControl('BillNo',tableData,6)" label-class-name="sort-column" sortable="custom" prop="BillNo"  key="BillNo" :label="$t('Deliver.OrderNo')">
                    <template scope="scope">
                        <el-link :underline="false" @click=" ToOrderDetail(scope.row.BillNo)">
                            {{ scope.row.BillNo }}</el-link>
                    </template>
                    </el-table-column>
                    <el-table-column Key="HasAddCar" :width="45">
                        <template slot-scope="scope">
                            <svg-icon v-if="scope.row.HasAddCar" style="transform:scale(0.8)" icon-class="DeliverCar" />
                        </template>
                    </el-table-column>
                    <el-table-column :width="WidthControl('MaterialId',tableData)" prop="MaterialId" key="MaterialId" :label="$t('Deliver.ProdId')"></el-table-column>
                    <el-table-column :width="WidthControl('MaterialName',tableData)" key="MaterialName" prop="MaterialName" :label="$t('Deliver.ProdName')" ></el-table-column>
                    <el-table-column :width="WidthControl('MaterialSpec',tableData)" prop="MaterialSpec" key="MaterialSpec" :label="$t('Deliver.ProdSpec')"></el-table-column>
                    <el-table-column prop="CarQuantity" key="CarQuantity" align="right" width="150" :label="$t('Deliver.CarQuantity')">
                        <template slot-scope="scope">
                            <span v-if="scope.row.HasAddCar">{{ scope.row.CarQuantity }}&nbsp{{ scope.row.SUnitName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="SQuantity" key="SQuantity" align="right" :label="$t('Deliver.SQuantity')">
                        <template slot-scope="scope">
                            <span>{{ scope.row.SQuantity }}&nbsp{{ scope.row.SUnitName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :width="WidthControl('DeliveryDate',tableData,6)" label-class-name="sort-column" sortable="custom" prop="DeliveryDate" key="DeliveryDate" :label="$t('Deliver.DeliverDate')"></el-table-column>
                    <el-table-column prop="UnTransSQty" key="UnTransSQty" align="right" :label="$t('Deliver.UnTransSQty')">
                        <template slot-scope="scope">
                            <span>{{ scope.row.UnTransSQty }}&nbsp{{ scope.row.SUnitName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="Quantity" key="Quantity" width="150"  :label="$t('Deliver.Quantity')">
                        <template slot-scope="scope">
                            <div style="display:flex;justify-content: left;">
                                <el-input style="width: 65%;" v-model="scope.row.Quantity" :placeholder="$t('Deliver.inputpls')"
                                @change="handleQtyChange(scope.row, scope.$index,'ETOB')"></el-input>
                                <span style="margin:auto 0">{{ scope.row.SUnitName }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column v-if="$store.state.ShowBasicUnit" key="BQuantity" prop="BQuantity" width="150" :label="$t('Deliver.TotalQty')">
                        <template slot-scope="scope">
                            <div style="display:flex;justify-content: left;">
                                <el-input style="width: 65%;" v-model="scope.row.BQuantity" :placeholder="$t('Deliver.inputpls')"
                                @change="handleQtyChange(scope.row, scope.$index,'BTOE')"></el-input>
                                <span style="margin:auto 0">{{ scope.row.UnitName }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column  key="CompName" prop="CompName" :width="WidthControl('CompName',tableData,6,20)" :label="$t('Deliver.compName')"></el-table-column>
                    <el-table-column prop="IsPriceWithTax" :label="$t('comm.includetax')" :width="100">
                        <template scope="scope"><span style="padding-left:20px">{{ scope.row.IsPriceWithTax?$t('comm.btnyes'):$t('comm.btnno') }}</span></template>
                    </el-table-column>
                    <el-table-column v-if="$store.state.ShowMTO" prop="MTONo" :width="WidthControl('MTONo',tableData,5)" key="MTONo" :label="$t('Deliver.mtoNo')" ></el-table-column>
                    <el-table-column  prop="BatchNo" :width="WidthControl('BatchNo',tableData,5)" key="BatchNo" :label="$t('Deliver.batchNo')" ></el-table-column>
                    <el-table-column :min-width="WidthControl('Remark',tableData)" prop="Remark" key="Remark" :label="$t('Deliver.remark')"></el-table-column>
                    <el-table-column :width="WidthControl('Address',tableData,6,20)" prop="Address" key="Address" :label="$t('Deliver.address')" >
                        <template slot-scope="scope">
                            <div :class="$store.state.AllowEditAddress?'changeAddress':''" @click="ShowRowAddWindow(scope.row)">
                                <svg-icon v-if="$store.state.AllowEditAddress" class="changeIcon" icon-class="changeaddress-blue" style="width: 13px;height: 13px;" />
                                <span  style="margin:auto 0;width: 97%;">{{ scope.row.Address }}</span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>


            </div>
            <div v-show="tableData.length" class="bottom">
                <div class="allCheck">
                    <el-checkbox @change="toggleSelection" v-model="allCheck">{{ $t('Deliver.allCheck') }}</el-checkbox>
                </div>
                <el-button @click="ShowAddressWindow" v-if="!minscreen && $store.state.AllowEditAddress">
                    <svg-icon icon-class="changeaddress" style="width: 13px;height: 13px;" />
                {{ $t('button.modifyaddress') }}
                </el-button>
                <el-button @click="ShowAddressWindow" v-if="minscreen" :title="$t('button.modifyaddress')">
                    <svg-icon icon-class="changeaddress" style="width: 13px;height: 13px;" />
                </el-button>
                <div class="pagesizeselect" v-show="pagination">
                    <el-select v-model="$store.state.pagesize" size="small" @change="pageSizeChange">
                    <el-option
                        v-for="item in $store.state.pagesizes"
                        :key="item.pagesize"
                        :label="item.pagesize"
                        :value="item.pagesize"
                    >
                    </el-option>
                </el-select>
                <span style="font-size: 13px;">{{ $t('Deliver.pagesize') }}</span>
                </div>
             

                <el-pagination style="width:38%;" @current-change="handleCurrentChange"
                    layout="total,prev,pager,next,jumper" :pager-count.sync="pagecount" :current-page="currentPage" :page-size="$store.state.pagesize"
                    :total="totalCount" v-show="pagination">
                </el-pagination>
                <div class="checkInfo">
                    <div class="checkNum">
                        {{ $t('Deliver.total').format(selectData.length) }}
                        <!-- {{ $t('Deliver.checkNum') }}<span>{{ selectData.length }}</span>{{ $t('Deliver.number') }} -->
                    </div>
                    <el-button type="primary" icon="el-icon-shopping-cart-2" :disabled="toDeliverstate" @click="addToCar" :loading="btnloading&&!toDeliverstate">
                        {{ $t('Deliver.addToCar') }}</el-button>
                    <el-button type="primary" icon="el-icon-truck" @click="toDeliver" :disabled="addtocarstate" :loading="btnloading&&!addtocarstate">
                        {{ $t('Deliver.deliver') }}</el-button>
                </div>
            </div>
        </div>
        <el-dialog :title="$t('button.upload')" :visible.sync="dialogvisible" width="40%">
            <el-upload class="upload-demo" accept=".xlsx,.xls" :file-list="filelist" :http-request="uploadMethod"
                :show-file-list="false" action="" :before-upload="uploadExcel">
                <el-button size="small" type="primary">{{ $t('Deliver.upload') }}</el-button>
                <div slot="tip" class="el-upload__tip">{{ $t('Deliver.limitupload') }}</div>
            </el-upload>
        </el-dialog>
        <el-dialog :title="modifyAddress" :visible.sync="ChangeAdtag" width="40%">
            <el-select size="small"  v-model="AllUId" placeholder="" class="pop-address">
                <el-option v-if="item.OrgId == fillterOrgId" v-for="item in addresses" :key="item.Uid" :label="item.Address" :value="item.Uid" class="option">
                </el-option>
            </el-select>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancel()">{{ $t('button.cancel') }}</el-button>
                <el-button type="primary" @click="batchChangeAddress()">{{ $t('button.determine') }}</el-button>
            </span>
        </el-dialog>
        <DeliverSuccess ref="dialog" :billnoArray="billArray" :successCount="successCount" :selectCount="selectCount">
        </DeliverSuccess>
    </Frame>
</template>
<script>
import { handleShortDate } from '../utils/handleDate.js'
import { getTimeoutVal,BatchExportSession } from '../utils/utils.js'
import DeliverSuccess from '../components/DeliverSuccess.vue';
export default {
    name: "Deliver",
    components: {
        DeliverSuccess
    },
    data() {
        return {
            isDisabled: false, //复选框按钮不可编辑
            isLoadMore: false,
            ordertodeliver: false,
            pageindex: 0,
            billnos: [],
            progId:'purPurchaseOrder',
            prodId: "",
            prodName: "",
            prodSpec:"",
            compName:"",
            address: "",
            mtoNo: "",
            batchNo: "",
            myDate: "",
            deliverDate: "",
            tableData: [],
            allCheck: false,
            currentPage: 1,
            totalCount: 0,
            selectData: [],
            startdate: "",
            enddate: "",
            batchexport: false,
            radio: 'exportTextA',
            unshipped: false,
            unconfirmedchanges: true,
            confirm: false,
            unsignedconfirmation: true,
            unshippe: true,
            partialDel: true,
            allshipments: false,
            checkRowState:false,
            errorRowState :'',
            diforgbillnos: '',
            tableHeight: window.innerHeight - 362- 52,
            tableLoading: false,
            cartType: { DeliveryCartBizAttr: 20 },
            selectall: false,
            btnloading: false,
            dialogvisible: false,
            filelist: [],
            billArray: [],
            selectCount: 0,
            successCount: 0,
            pagination: true,//用于隐藏翻页工具
            timeDefault:'',
            minscreen:false,
            isexcel: false,//用于判断页面数据是否为excel导入文件的数据
            ExcelList:[],
            timer:null,
            pagecount:5,
            ChangeAdtag:false,
            AllUId:'',
            addresses:[],
            fillterOrgId:'',
            withTax:true,
            withoutTax:true,
            isLoadAllBill:true, // 是否加载所有单据【显示全转发货车订单行项】
            addtocarstate:false,
            toDeliverstate:false,
            modifyAddress:this.$t('button.modifyaddress'),
            addIsRow:false,
            CurRowTemp:{},
            sortorder:"ascending",
            orderprop:"DeliveryDate"
        }
    },
    created() {
        if(localStorage.getItem("DeliverSortOrder")){
            let sortinfo = JSON.parse(localStorage.getItem("DeliverSortOrder"));
            if(sortinfo.sortorder&&sortinfo.orderprop){
                this.sortorder = sortinfo.sortorder;
                this.orderprop = sortinfo.orderprop;
            }else{
                this.sortorder = "ascending";
                this.orderprop = "DeliveryDate";
            }
            debugger
        }
        if (sessionStorage.getItem("OrderToDeliverShipment")) {
            this.ordertodeliver = true;
            this.billnos = JSON.parse(sessionStorage.getItem("OrderToDeliverShipment"));
            // BUG202309070021  新版供应商，默认含税的情况下，在订单查询勾选订单操作[批量发货]转到发货页面，含税应该也要勾选，如下图：
            this.withTax = this.$route.query.withTax.toString().toLowerCase() == "true" ? true : false;
            this.withoutTax = this.$route.query.withoutTax.toString().toLowerCase() == "true" ? true : false;
            this.checkRowState = this.$route.query.checkRowState.toString().toLowerCase() == "true" ? true : false; 
        }        
        this.getSessionInform("Deliver",(v)=>{
            this.currentPage = v.currentPage;
            this.pageindex = v.pageindex;
            this.prodId = v.prodId;
            this.prodName = v.prodName;
            this.prodSpec = v.prodSpec;
            this.compName = v.compName;
            this.address = v.address;
            this.mtoNo = v.mtoNo;
            this.batchNo = v.batchNo;
            this.myDate = v.myDate;
            this.deliverDate = v.deliverDate;
            this.startdate = v.startdate;
            this.enddate = v.enddate;
            this.withTax = v.withTax;
            this.withoutTax = v.withoutTax;
            this.isexcel = v.isexcel;
            this.ExcelList = JSON.parse(v.ExcelList);
            this.billnos = JSON.parse(v.billnos);
            this.ordertodeliver = v.ordertodeliver;
            this.pagination = Boolean(v.pagination);
        })
    },
    beforeDestroy() {
        if (sessionStorage.getItem("OrderToDeliverShipment"))
            sessionStorage.removeItem("OrderToDeliverShipment");
    },
    mounted() {
        if(localStorage.getItem("iswithtaxRecord")){
            var iswithtaxRecord = JSON.parse(localStorage.getItem("iswithtaxRecord"));
            this.withTax = Boolean(iswithtaxRecord.withTax);
            this.withoutTax = Boolean(iswithtaxRecord.withoutTax);  
        }
        if(document.body.clientWidth<1450){
                    this.pagecount = 3;
                    this.minscreen = true;
                }else{
                    this.pagecount = 5;
                    this.minscreen = false;
                }
        let that = this
        window.onresize = () => {
            if (that.resizeFlag) {
                clearTimeout(that.resizeFlag);
            }
            that.resizeFlag = setTimeout(() => {
                if(document.body.clientWidth<1450){
                    this.pagecount = 3;
                    this.minscreen = true;
                }else{
                    this.pagecount = 5;
                    this.minscreen = false;
                }
                if(that.isLoadMore){
                    this.tableHeight = window.innerHeight - 414 - 52
                }else{
                    that.tableHeight = window.innerHeight - 362 - 52
                }
                that.resizeFlag = null;
            }, 100);
        }
        this.timeDefault =new Date();
        this.timeDefault.setMonth(new Date().getMonth()-1);
        this.getDeliver(this.pageindex);
        this.getAddresses("",true);
    },
    watch:{
        withTax:{
            handler:function(newval,oldval){
                var taxlist = {withTax:this.withTax,withoutTax:this.withoutTax};
                localStorage.setItem("iswithtaxRecord",JSON.stringify(taxlist));
            }
        },
        withoutTax:{
            handler:function(newval,oldval){
                var taxlist = {withTax:this.withTax,withoutTax:this.withoutTax};
                localStorage.setItem("iswithtaxRecord",JSON.stringify(taxlist));
            }
        },
        isLoadMore:{
            handler:function(newval,oldval){
                if(newval){
                    this.tableHeight = window.innerHeight - 414 - 52
                }else{
                    this.tableHeight = window.innerHeight - 362 - 52
                }
            }
        }
    },
    methods: {
        handleClick() { console.log("ss") },
        reset(v) {
            this.prodId = "";
            this.prodName = "";
            this.prodSpec = "";
            this.compName = "";
            this.address = "";
            this.mtoNo = "";
            this.batchNo = "";
            this.myDate = "";
            this.deliverDate = "";
            this.startdate = "";
            this.enddate = "";
            if (v == "default") {
                this.billnos = [];
                this.isexcel = false;
                this.ExcelList = [];
                sessionStorage.removeItem("OrderToDeliverShipment");
                this.ordertodeliver = false;
            }
            this.getDeliver(0);
        },
        handleCurrentChange(val) {
            this.getDeliver(val - 1);
            this.allCheck = false;
            this.pageindex = val - 1;
            this.selectData = [];
        },
        pageSizeChange(){
            localStorage.setItem("pagesize", this.$store.state.pagesize, "365D");
            this.getDeliver(0); 
            this.selectData = [];
            this.allCheck = false;
        },
        getDeliver(pageindex) {
            var timeVal= getTimeoutVal(); //设置计时器统一时间 //控制复选框更新频率500毫秒
            this.isDisabled = true;
            setTimeout(() =>{ 
                this.isDisabled = false;
            },timeVal);
            if(!this.isexcel){
                this.pagination = true;
            }
            this.tableLoading = true;
            this.btnloading = true;
            this.toDeliverstate = true;
            this.addtocarstate = true;
            this.pageindex = pageindex;
            this.selectData = [];
            this.allCheck = false;
            this.$store.commit("GETPAGESIZE",localStorage.getItem("pagesize")?Number(localStorage.getItem("pagesize")):10);
            this.axiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx",
                {
                    action: "getonwayorder",
                    pageindex: pageindex,
                    pagesize: this.$store.state.pagesize,
                    prodId: this.prodId,
                    prodName: this.prodName,
                    prodSpec: this.prodSpec,
                    compName:this.compName,
                    address: this.address,
                    mtoNo: this.mtoNo,
                    batchNo: this.batchNo,
                    startdate: this.startdate,
                    enddate: this.enddate,
                    billnos: JSON.stringify(this.billnos),
                    withTax:this.withTax,
                    withoutTax:this.withoutTax,
                    isLoadAllBill:this.isLoadAllBill, //【显示全转发货车订单行项】 ADV202310170004 发货增加显示全转发货车行项，同时若已转发货数量大于订单行项未转数量弹窗提醒
                    isexcel:this.isexcel,
                    ExcelList:JSON.stringify(this.ExcelList),
                    checkRowState:this.checkRowState,
                    sortorder:this.sortorder,
                    orderprop:this.orderprop
                },
                (res) => {
                    var data = eval(res.data);
                    this.errorRowState = data[0].errorRowState;
                    if(typeof(this.errorRowState)!='undefined') data.shift(0);
                    this.totalCount = data[0].totalCount;
                    this.currentPage = pageindex + 1;
                    console.log(data);
                    data.shift(0);
                    this.diforgbillnos = data[0].diforgbillnos;
                    if (this.errorRowState) {
                        this.$store.commit("TIPPOP",{popType:'warning',popMessage: this.errorRowState});
                        // ADV202309070016 新版供应商，发货页面行项状态提示信息增加控制，仅在订单清单批量发货时提示，其他的查询、添加发货车、直接发货后的数据刷新都不再检查和提示
                        // BUG202309070027 新版供应商，存在行项状态为正常、转单冻结、终止的订单直接发发货，提示不统一——订单链接跳转访问才检查行项状态和提示
                        this.checkRowState = false; // 订单链接访问提示后，不用再检查行项状态和提示
                    }else
                    if (this.diforgbillnos) {
                        this.$store.commit("TIPPOP",{popType:'warning',popMessage: this.$t("Deliver.unabledelivertip").replace("{0}", this.diforgbillnos)});
                    }
                    data.shift(0);
                    this.tableData = data;
                    this.tableLoading = false;
                    this.btnloading = false;
                    this.toDeliverstate = false;
                    this.addtocarstate = false;
                    if (this.ordertodeliver) {
                        this.$refs.multipleTable.toggleAllSelection();
                        this.tableData.forEach((v) => {
                            v.Quantity = v.UnTransSQty;
                            v.selected = true;
                        })
                        this.selectData = [...this.tableData];
                    }
                    if(this.isexcel){
                        this.ExcelQuantity();
                    }
                })
        },
        //根据发货数量控制行项勾�?
        handleQtyChange(row, index,changekind) {
            if ((changekind == "ETOB"&&(row.Quantity == ""))||
            changekind == "BTOE"&&(row.BQuantity == "")) {
                this.$refs.multipleTable.toggleRowSelection(row, false);
                row.selected = false;
                row.BQuantity = "";
                row.Quantity = "";
                return;
            }
            if(!row.Quantity)row.Quantity = "";
            if(!row.BQuantity)row.BQuantity = "";
            let Quantity = Number(row.Quantity.replace(/,/g,""))
            let SUnitId = row.SUnitId;
            if(changekind == "BTOE"){
                Quantity = Number(row.BQuantity.replace(/,/g,""))
                SUnitId = row.UnitId;
            }
            if (Quantity > 0 && Quantity < 1e10) {
                //请求服务端规格化数量的�?
                clearTimeout(this.timer);
                this.btnloading = true;
                this.timer = setTimeout(()=>{
                    this.unitExchange(Quantity,SUnitId,row,changekind);    
                },150)
                this.$refs.multipleTable.toggleRowSelection(row, true);
                row.selected = true;
                let isexist = false;
                this.selectData.some((item, index, arr) => {
                    if (row.BillNo == item.BillNo && row.FromRowCode == item.FromRowCode) {
                        isexist = true;
                    }
                })
                if (!isexist) {
                    this.selectData.push(this.tableData[index])
                }
            } else {
                if (Number.isNaN(Quantity) || Quantity < 0) {
                    this.$store.commit("TIPPOP",{popType:'error',popMessage: this.$t("Deliver.message")});
                    this.$set(this.tableData[index], "Quantity", "")
                    this.$set(this.tableData[index], "BQuantity", "")
                }
                this.$refs.multipleTable.toggleRowSelection(row, false);
                row.selected = false;
                this.selectData.some((item, index, arr) => {
                    if (row.BillNo == item.BillNo && row.FromRowCode == item.FromRowCode) {
                        arr.splice(index, 1)
                    }
                })
            }
        },
        //发货数量和发货基本数量单行联�?
        unitExchange(Quantity,SUnitId,row,changekind){
            this.btnloading = true;
            this.axiosInvoke("/SupplierComm/Handler/FormatHandler.ashx", {
                    action: 'formatquantity',
                    FormatValue: Quantity,
                    FormatFieldValue: SUnitId,
                }, (res) => {
                    var data = res.data;
                    console.log(data);
                    if(changekind == "ETOB"){
                        row.Quantity = data.result;
                        if(Number(row.TotalSQty.replace(/,/g,"")) == Number(row.UnTransSQty.replace(/,/g,"")) 
                        && Number(row.UnTransSQty.replace(/,/g,"")) == Number(row.Quantity.replace(/,/g,""))){
                            row.BQuantity = row.TotalQty;
                            this.btnloading = false;
                            return;
                        }
                    }else{
                        row.BQuantity = data.result;
                    }
                    row.ProgId = this.progId;
                    this.axiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx", {
                        action:'unitexchange',
                        changekind,
                        billArray:JSON.stringify([row])
                    },(res,err)=>{
                        this.btnloading = false;
                        var data2 = eval(res.data);
                        if(changekind == "ETOB"){
                            row.BQuantity = data2[0].resultQty;
                        }else{
                            if(err){
                                row.BQuantity = "";
                                this.$refs.multipleTable.toggleRowSelection(row,false);
                                if(this.selectData.indexOf(row)>-1){
                                    this.selectData.splice(this.selectData.indexOf(row),1);
                                }
                                row.selected = false;
                            }
                            else
                            row.Quantity = data2[0].resultQty;
                        }
                    })
                })
        },
        //打开批量导出的窗�?
        BatchExportWindow(){
            if(!this.selectData.length){
                this.$store.commit("TIPPOP",{popType:'warning',popMessage: this.$t("Deliver.warning")});
                return;
            }
            this.batchexport = true;
            var selectbillnos = [];
            this.selectData.forEach(v => {
                selectbillnos.push(v.BillNo);
            });
            BatchExportSession("default",selectbillnos,"purPurchaseOrder",this)
        },
        toggleSelection(val) {
            this.allCheck = val ? true : false;
            this.$refs.multipleTable.toggleAllSelection();
            this.selectData.forEach(v=>{
                v.selected = this.allCheck;
            })
        },
        handleSelect(val, row) {
            console.log(row);
            row.selected = !row.selected;
            if (!row.selected) {
                row.Quantity = "";
                row.BQuantity="";
            } else {
                let Quantity = Number(row.UnTransSQty.replace(/,/g,""));
                if(row.Quantity)Quantity = Number(row.Quantity.replace(/,/g,""));
                this.unitExchange(Quantity,row.SUnitId,row,'ETOB');
            }
            this.selectData = val;
            if (val.length != 0 && val.length % this.tableData.length == 0) {
                this.allCheck = true
            } else this.allCheck = false
        },
        handleSelectAll(val) {
            this.allCheck = val.length == this.tableData.length ? true : false;
            if (val.length) {
                let temprows = [];
                val.forEach((v) => {
                    v.ProgId = this.progId;
                    if(v.Quantity!="" ||v.Quantity!=0){
                        v.selected = true;
                        temprows.push(v);
                    }
                    if (v.Quantity == 0 || v.Quantity == "" || v.Quantity == null) {
                        temprows.push(v);
                        v.selected = true;
                        v.Quantity = v.UnTransSQty;
                    }

                })
                this.axiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx", {
                        action:'unitexchange',
                        changekind:"ETOB",
                        billArray:JSON.stringify(temprows)
                    },(res)=>{
                        var data = eval(res.data);
                        val.forEach((v)=>{
                            data.forEach((d)=>{
                                if(v.BillNo == d.BillNo&&v.FromRowCode == d.RowCode){
                                    if(Number(v.TotalSQty.replace(/,/g,"")) == Number(v.UnTransSQty.replace(/,/g,"")) 
                                    && Number(v.UnTransSQty.replace(/,/g,"")) == Number(v.Quantity.replace(/,/g,""))){
                                        v.BQuantity = v.TotalQty;
                                    }else{
                                        v.BQuantity = d.resultQty;
                                    }
                                }
                            })
                        })
                    })
                this.selectData = val;
            } else {
                this.tableData.forEach((v) => {
                    v.Quantity = "";
                    v.BQuantity = "";
                    v.selected = false;
                })
                this.selectData = [];
            }
        },
        getTableHeight() {
            this.tableHeight = window.innerHeight - 337
        },
        //添加到web车单�?
        addToCar() {
            if (this.selectData.length >= 1) {
                var temp = [];
                var tempbillno = this.selectData[0].BillNo;
                for (let i = 0; i < this.selectData.length; i++) {
                    let obj = {};
                    obj.FromSourceTag = "2601";
                    obj.FromBillNo = this.selectData[i].BillNo;
                    obj.FromRowCode = this.selectData[i].FromRowCode;
                    obj.OrgId = this.selectData[i].OrgId;
                    obj.MaterialId = this.selectData[i].MaterialId;
                    obj.Quantity = this.selectData[i].Quantity;
                    obj.UnTransSQty = this.selectData[i].UnTransSQty; // 增加未转数量传参，仅用于后台匹配检查，目前暂不支持更新到web车单据
                    obj.SUnitId = this.selectData[i].SUnitId;
                    obj.MTONo = this.selectData[i].MTONo; // ADV202307070003 供应商平�?发货车表身增加MTO、批号栏位，供用户再次核对当时挑选信�?
                    obj.BatchNo = this.selectData[i].BatchNo;
                    obj.Remark = this.selectData[i].Remark;
                    obj.AddrId = this.selectData[i].AddrId;
                    obj.Address = this.selectData[i].Address;
                    obj.ReceiveOrgId = this.selectData[i].ReceiveOrgId;
                    obj.ItemType = this.selectData[i].ItemType;
                    obj.FromTypeId = this.selectData[i].FromTypeId;
                    obj.IsPriceWithTax = this.selectData[i].IsPriceWithTax;
                    obj.BQuantity = this.selectData[i].BQuantity;
                    obj.UnitId = this.selectData[i].UnitId;
                    temp.push(obj);
                }
                this.tableLoading = true;
                this.btnloading = true;
                this.addtocarstate = true;
                this.$refs.multipleTable.clearSelection();
                this.axiosInvoke("/SupplierComm/Handler/CartHandler.ashx", {
                    action: "speaddcart", // ADV202310170004 发货增加显示全转发货车行项，同时若已转发货数量大于订单行项未转数量弹窗提醒——先执行添加时的检查，返回超出未转数量的数据
                    BizAttr: this.cartType.DeliveryCartBizAttr,
                    DetailInfoList: JSON.stringify(temp),
                    ForceAdd:false,
                }, (res,err) => {
                    this.tableLoading = false;
                    this.btnloading = false;
                    this.allCheck = false;
                    this.addtocarstate = false;
                    this.selectData = [];
                    var data = res.data;
                    this.$store.dispatch("getDeiverCarCount", this);
                    this.tableData.forEach((v) => {
                        v.Quantity = "";
                        v.BQuantity = "";
                        v.selected = false;
                    })
                    // 如果有存在警告信息，说明存在超出未转数量行项
                    if(data && typeof(data.WarningTip) != 'undefined' && data.WarningTip != ""){
                        // 弹窗提示对话框，用户再次确认是否继续添加
                        this.doComfirm(data);
                    }
                    else if(!err){
                        this.$store.commit("TIPPOP",{popType:'success',popMessage: this.$t("Deliver.success")});
                        this.getDeliver(this.pageindex);
                    }                 
                });
            } else {
                this.$store.commit("TIPPOP",{popType:'warning',popMessage: this.$t("Deliver.warning")});
            }
        },
        // 弹窗提示对话框——ADV202310170004 发货增加显示全转发货车行项，同时若已转发货数量大于订单行项未转数量弹窗提醒
        doComfirm(tempData){
            var carTip = tempData.WarningTip;
            var dataList = tempData.WarningDetailInfoList;
            const h = this.$createElement;
            var msgAy = new Array();
            carTip.forEach(tip =>{
                msgAy.push(h('p',tip)); // 组合显示信息
            })
            this.$confirm(this.$t('comm.tip'),{
                title:this.$t('comm.tip'),
                message:h('div',{class:'confirmDivBox'},msgAy),
                cancelButtonText: this.$t('button.Cancel'), //'取消',
                confirmButtonText: this.$t('button.repeatAddText'), // 继续添加
                type:'warning',
                customClass:'confirmBox', // 设置样式
            }).then(() =>{
                // 执行继续添加到发货车逻辑
                if(dataList && dataList.length > 0){
                    this.tableLoading = true;
                    this.btnloading = true;
                    this.addtocarstate = true;
                    this.axiosInvoke('/SupplierComm/Handler/CartHandler.ashx', // '/SupplierComm/Handler/BillInfo.ashx',
                    {
                        action: "speaddcart", // 强制执行添加到发货车（调用原来添加车逻辑，直接更新发货车对应行项信息）
                        BizAttr: this.cartType.DeliveryCartBizAttr,
                        DetailInfoList: JSON.stringify(dataList),
                        ForceAdd:true,
                    },
                    (res, err) => {
                        this.tableLoading = false;
                        this.btnloading = false;
                        this.allCheck = false;
                        this.addtocarstate = false;
                        this.tableData.forEach((v) => {
                            v.Quantity = "";
                            v.BQuantity = "";
                            v.selected = false;
                        });
                        this.$store.dispatch("getDeiverCarCount", this);
                        if(!err){
                            this.$store.commit("TIPPOP",{popType:'success',popMessage: this.$t("Deliver.success")});
                            this.getDeliver(this.pageindex);
                        }
                    });
                }
            }).catch(() =>{
                this.tableLoading = false;
                this.btnloading = false;
                this.allCheck = false;
                this.addtocarstate = false;
                this.selectData = [];
                this.tableData.forEach((v) => {
                    v.Quantity = "";
                    v.BQuantity = "";
                    v.selected = false;
                });
            });
        },
        //获取所有可选发货地址
        getAddresses(ReceiveOrgId,first){
            this.selectloading = true;
            this.addresses = [];
            this.axiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx", 
            {
                action:'getaddresses',
                OrgId:ReceiveOrgId,
                first:first
            },
            (res)=>{
                var data = eval(res.data);
                this.addresses = data;
                console.log(this.addresses);
                this.selectloading = false;
            });
        },
        ShowRowAddWindow(row){
            if(!this.$store.state.AllowEditAddress)return;
            this.AllUId = row.Uid;
            this.CurRowTemp = row;
            this.addIsRow = true;
            this.modifyAddress = this.$t('button.modifyRowAddress');
            let ReceiveOrgId = row.ReceiveOrgId;
            this.ChangeAdtag = true;
            this.fillterOrgId = ReceiveOrgId;
            this.getAddresses(ReceiveOrgId);
        },
            //批量修改地址弹窗
         ShowAddressWindow(){
            this.AllUId = "";
            this.addIsRow = false;
            this.modifyAddress = this.$t('button.modifyaddress');
            if(this.selectData.length){
                let ReceiveOrgId = this.selectData[0].ReceiveOrgId;
                let difforg = false;
                this.selectData.forEach((v)=>{
                    if(v.ReceiveOrgId!=ReceiveOrgId)difforg = true;
                })
                if(!difforg){
                    this.ChangeAdtag = true;
                    this.fillterOrgId = ReceiveOrgId;
                    this.getAddresses(ReceiveOrgId);
                }else{
                    this.$store.commit("TIPPOP",{popType:'warning',popMessage: this.$t("Deliver.difforgaddress")});
                }
            }else{
                this.$store.commit("TIPPOP",{popType:'warning',popMessage: this.$t("Deliver.warning")});
            }
        },
         //批量修改地址
         batchChangeAddress(){
            var Address = "";
            var AddrId = "";
            this.addresses.forEach((v)=>{
                if(v.Uid == this.AllUId){
                    AddrId = v.AddrId;
                    Address = v.Address;
                }
            })
            if(this.addIsRow){
                this.CurRowTemp.Uid = this.AllUId;
                this.CurRowTemp.AddrId = AddrId;
                this.CurRowTemp.Address = Address;
            }else{
                this.selectData.forEach((v)=>{
                    v.Uid = this.AllUId;
                    v.AddrId = AddrId;
                    v.Address = Address;
                })
            }

            this.ChangeAdtag = false;
        },
        cancel() {//取消
        this.ChangeAdtag = false;
        },
        //直接发货
        toDeliver(row) {
            if (this.selectData.length >= 1) {
                //...发货...//
                this.selectCount = this.selectData.length;
                this.tableLoading = true;
                this.toDeliverstate = true;
                this.btnloading = true;
                var temp = [];
                for (let i = 0; i < this.selectData.length; i++) {
                    let obj = {};
                    obj.FromSourceTag = "2601";
                    obj.FromBillNo = this.selectData[i].BillNo;
                    obj.FromRowCode = this.selectData[i].FromRowCode;
                    obj.OrgId = this.selectData[i].OrgId;
                    obj.MaterialId = this.selectData[i].MaterialId;
                    obj.Quantity = this.selectData[i].Quantity;
                    obj.BQuantity = this.selectData[i].BQuantity;
                    obj.SUnitId = this.selectData[i].SUnitId;
                    // obj.MtoNo = this.selectData[i].MtoNo;
                    obj.BatchNo = this.selectData[i].BatchNo;
                    obj.Remark = this.selectData[i].Remark;
                    obj.AddrId = this.selectData[i].AddrId;
                    obj.Address = this.selectData[i].Address;
                    obj.ReceiveOrgId = this.selectData[i].ReceiveOrgId;
                    obj.ItemType = this.selectData[i].ItemType;
                    obj.FromTypeId = this.selectData[i].FromTypeId;
                    obj.IsPriceWithTax = this.selectData[i].IsPriceWithTax;
                    obj.DeliverDate = this.selectData[i].DeliverDate;
                    obj.IsTaxByOrder = this.selectData[i].IsTaxByOrder;
                    obj.TaxId = this.selectData[i].TaxId;
                    obj.PersonId = this.selectData[i].PersonId;
                    obj.VaryState = this.selectData[i].VaryState;
                    obj.DemandOrgId = this.selectData[i].DemandOrgId;
                    obj.DebtorOrgId = this.selectData[i].DebtorOrgId;
                    obj.BodyTaxId = this.selectData[i].BodyTaxId;
                    temp.push(obj);
                }
                this.$refs.multipleTable.clearSelection();
                this.axiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx", {
                    action: "directdeliver",
                    billArray: JSON.stringify(temp),
                    iscar:false
                }, (res) => {
                    this.tableLoading = false;
                    this.btnloading = false;
                    this.toDeliverstate = false;
                    this.allCheck = false;
                    this.selectData = [];
                    this.tableData.forEach(v => {
                        v.Quantity = "";
                        v.BQuantity = "";
                        v.selected = false;
                    });
                    console.log(res.data);
                    let data = res.data;
                    if (res.datalist) {
                        data = res.datalist[0];
                    }
                    if (data[0].errflag) {
                        data.shift();
                        let errbillnos = "";
                        data.forEach(v => {
                            errbillnos += v + " ";
                        })
                        this.$store.commit("TIPPOP",{popType:'warning',popMessage: this.$t("Deliver.unabledelivertip2").replace("{0}", errbillnos)});
                    } else {
                        this.getDeliver(this.pageindex);
                        this.$nextTick(()=>{
                            this.successCount = data.pop().successCount;
                            this.$refs.dialog.visible = true;
                        })
                        this.billArray = data;
                    }

                })


            } else {
                this.$store.commit("TIPPOP",{popType:'warning',popMessage: this.$t("Deliver.warning")});
            }
        },
        inputchange(val) {
            this.getDeliver(0);
        },
        //获取时间
        gettime() {
            if(this.myDate){
                this.startdate = handleShortDate(this.myDate[0], "From");
                this.enddate = handleShortDate(this.myDate[1], "To");
            }
            else{
                this.startdate = "";
                this.enddate = "";
            }
            this.deliverDate = "";
            this.getDeliver(0);
        },
        changeTaxState(){
            this.getDeliver(0);
        },
        changeIsLoadAllBill(){ // ADV202310170004 发货增加显示全转发货车行项，同时若已转发货数量大于订单行项未转数量弹窗提醒
            this.isLoadAllBill = ! this.isLoadAllBill;
            this.getDeliver(0);
        },
        //获取3,7,30天时�?
        getselecttime() {
            var date = new Date();
            let dateItem = null;
            this.startdate = handleShortDate(new Date(), "From");
            if (this.deliverDate.indexOf("3") > -1 && this.deliverDate.indexOf("30") == -1)dateItem = new Date(date.getTime() + 2 * 3600 * 24 * 1000);
            else if (this.deliverDate.indexOf("7") > -1)dateItem = new Date(date.getTime() + 6 * 3600 * 24 * 1000);
            else if (this.deliverDate.indexOf("30") > -1)dateItem = new Date(date.getTime() + 29 * 3600 * 24 * 1000);
            this.enddate = handleShortDate(dateItem, "From");
            this.myDate = "";
            this.getDeliver(0);
        },
        //导入模板下载
        downloadtem() {
            var url = "/SupplierComm/Upload/File/templet.xlsx";
            var domA = document.createElement("a");
            domA.setAttribute("download", ""); //a标签设置download属�?
            domA.setAttribute("href", url); //a标签herf属性赋值要下载的文�?
            domA.click(); //下载
        },
        uploadtem() {
            this.dialogvisible = true;
        },
        query() {
            this.getDeliver(0);
        },
        //展开/收缩更多信息
        loadMore(){
            this.isLoadMore = !this.isLoadMore;
        },
        //上传Excel文件模板 且读取其内容
        uploadExcel(file, filelist) {
            console.log(file, filelist);
            if (!/\.(xls|xlsx)$/.test(file.name.toLowerCase())) {
                this.$store.commit("TIPPOP",{popType:'warning',popMessage: this.$t("Deliver.excellimittip")});
                return new Promise((res,rej)=>{return rej(false)});
            }
        },
        //替换原有的el-upload中的upload方法
        uploadMethod(param) {
            this.tableLoading = true;
            this.selectData = [];
            this.allCheck = false;
            let actionUrl = '/SupplierComm/Handler/VueSysHandler.ashx?action=uploadtemplate&withTax='+this.withTax+'&withoutTax='+this.withoutTax+'&ShowSaleAndPresentQty='+this.$store.state.ShowSaleAndPresentQty
            this.axiosInvoke(actionUrl, param, (res,errflag) => {
                if(errflag){
                    this.tableLoading = false;
                    this.reset("default");
                    this.dialogvisible = false;
                    return;
                }
                let data = eval(res.data);
                //清空所有搜索条件
                this.ordertodeliver = false;
                this.billnos = [];
                this.prodId = "";
                this.prodName = "";
                this.prodSpec = "";
                this.compName = "";
                this.address = "";
                this.mtoNo = "";
                this.batchNo = "";
                this.myDate = "";
                this.deliverDate = "";
                this.startdate = "";
                this.ExcelList = [];
                this.enddate = "";
                if (data.length>0&&data[0].length == 0&&data[1].length == 0) {
                    this.$store.commit("TIPPOP",{popType:'warning',popMessage: this.$t("Deliver.excelemptytip")});
                    this.tableLoading = false;
                } else {
                    this.dialogvisible = false;
                    this.tableLoading = false;
                    this.tableData = data[0];
                    for(let i=0;i<data[0].length;i++){
                        if(isNaN(data[0][i].Quantity.replace(/,/g,""))||data[0][i].Quantity.replace(/,/g,"")<0){
                            data[0][i].Quantity = 0;
                        }else{
                           if(data[0][i].Quantity.replace(/,/g,"")>0){
                            data[0][i].selected = true;
                            this.selectData.push(data[0][i]);
                            this.$nextTick(()=>{
                                this.$refs.multipleTable.toggleRowSelection(data[0][i],true);
                                data[0][i].selected = true;
                            })
                           }
                        }
                        this.ExcelList.push({BillNo:data[0][i].BillNo,RowCode:data[0][i].FromRowCode,Quantity:data[0][i].Quantity});
                        this.billnos.push({BillNo:data[0][i].BillNo});
                    }
                    if(this.selectData.length == this.tableData.length){
                        this.$nextTick(()=>{
                            this.allCheck = true;
                        })
                    }

                    if(data[1].length){
                        console.log(data[1]);
                        var ExcelNo = '';
                        var IncompleteNo = '';
                        var Incomplete = false;
                        data[1].forEach(v => {
                            if(v.Incomplete){
                                Incomplete =true;
                                IncompleteNo +=this.$t("Deliver.excelerrno").replace("{0}",v.ExcelNo);
                            }else{
                                ExcelNo +=this.$t("Deliver.excelerrno").replace("{0}",v.ExcelNo);
                            }
                        });

                        var info = this.$t("Deliver.exceldataerr").replace('{0}',ExcelNo);
   						if(this.withTax != this.withoutTax)info+= this.$t("Deliver.taxstateerr");
                        if(Incomplete) info += this.$t("Deliver.excelincomplete").replace('{0}',IncompleteNo);
                            var dialog = {
                                isShow: true,
                                title: this.$t('comm.tip'), 
                                message: this.$t("Deliver.excelerrtitle"),
                                showInfo:info,
                                copyInfo:info,
                                errorlist:[]
                            }
                        this.$store.commit("ERRORTIP", dialog);
                    
                        if(data[0].length==0){
                            this.isexcel = false;
                            this.getDeliver(0);
                            return;
                        }
                    }
                    
                    this.pagination = false;
                    this.allCheck = false;
                    this.isexcel = true;
                }
            }, true);
        },
        //跳转到orderDetail
        ToOrderDetail(billno){
            this.recoredPageToJump({
                pageindex:this.pageindex,
                currentPage: this.currentPage,
                pagesize: this.pagesize,
                prodId: this.prodId,
                prodName: this.prodName,
                prodSpec:this.prodSpec,
                compName: this.compName,
                address: this.address,
                startdate: this.startdate,
                enddate: this.enddate,
                myDate:this.myDate,
                deliverDate:this.deliverDate,
                batchNo:this.batchNo,
                mtoNo:this.mtoNo,
                withTax:this.withTax,
                withoutTax:this.withoutTax,
                isexcel:this.isexcel,
                ExcelList:JSON.stringify(this.ExcelList),
                billnos:JSON.stringify(this.billnos),
                pagination:this.pagination,
                ordertodeliver:this.ordertodeliver
            },"Deliver",billno,"OrderDetail");
        },
        //加载el-tooltip信息，鼠标聚焦时显示完整信息
        renderTitle(h, { column }) {
            let titleValue = '';
            let columnRealWidth = column.realWidth;
            switch (column.property) {
                case 'BillNo':
                    titleValue = this.$t("Deliver.OrderNo");
                    break;
                case 'CompName':
                    titleValue = this.$t("Deliver.compName");
                    break;
                case 'MaterialId':
                    titleValue = this.$t("Deliver.ProdId");
                    break;
                case 'MaterialName':
                    titleValue = this.$t("Deliver.ProdName");
                    break;
                case 'MaterialSpec':
                    titleValue = this.$t("Deliver.ProdSpec");
                    break;
                case 'SQuantity':
                    titleValue = this.$t("Deliver.SQuantity");
                    break;
                case 'TransSQty':
                    titleValue = this.$t("Deliver.TranQty");
                    break;
                case 'DeliveryDate':
                    titleValue = this.$t("Deliver.DeliverDate");
                    break;
                case 'UnTransSQty':
                    titleValue = this.$t("Deliver.UnTransSQty");
                    break;
                case 'Quantity':
                    titleValue = this.$t("Deliver.Quantity");
                    break;
                case 'MTONo':
                    titleValue = this.$t("Deliver.mtoNo");
                    break;
                case 'UnitName':
                    titleValue = this.$t("Deliver.UnitName");
                    break;
                case 'BatchNo':
                    titleValue = this.$t("Deliver.batchNo");
                    break;
                case 'Remark':
                    titleValue = this.$t("Deliver.remark");
                    break;
                case 'Address':
                    titleValue = this.$t("Deliver.address");
                    break;
                case 'BQuantity':
                    titleValue = this.$t("Deliver.TotalQty");
                    break;
                default:
                    titleValue = '默认栏位信息（测试）'
                    break;
            }
            return this.renderTooltip(h, titleValue, columnRealWidth)
        },
        cancel() {//取消
        this.batchexport = false;
        this.ChangeAdtag = false;
        },
        changeAddress(row){
            this.addresses.forEach((v)=>{
                if(v.Uid == row.Uid){
                    row.AddrId = v.AddrId
                    row.Address = v.Address
                }
            })
        },
        changeRadio(val) {
        this.radio = val;
        },
        //当导入的excel内容去触发搜索时，自动填入数量的方法。
        ExcelQuantity(){
            this.tableData.forEach((v)=>{
                this.ExcelList.forEach((Ev)=>{
                    if(v.BillNo == Ev.BillNo && v.FromRowCode == Ev.RowCode){
                        v.progId = this.progId;
                        v.Quantity = Ev.Quantity;
                        return;
                    }
                })
            })
            this.axiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx", {
                        action:'unitexchange',
                        changekind:"ETOB",
                        billArray:JSON.stringify(this.tableData)
                    },(res)=>{
                        var data = eval(res.data);
                        this.tableData.forEach((v)=>{
                            data.forEach((d)=>{
                                if(v.BillNo == d.BillNo&&v.FromRowCode == d.RowCode){
                                    v.BQuantity = d.resultQty;
                                }
                            })
                            if(Number(v.Quantity.replace(/,/g,""))>0){
                                this.selectData.push(v);
                                this.$refs.multipleTable.toggleRowSelection(v,true);
                                v.selected = true;
                            }
                        })
                        if(this.selectData.length == this.tableData.length){
                            this.$nextTick(()=>{
                                this.allCheck = true;
                            })
                        }
            })
        },
        //单据重新排序
        sortchange(param){
            debugger
            if(param.order&&param.prop){
                this.sortorder = param.order;
                this.orderprop = param.prop;
            }
            else{
                this.sortorder = "";
                this.orderprop = "";
            }
            this.getDeliver(0);
            localStorage.setItem("DeliverSortOrder",JSON.stringify({sortorder:this.sortorder,orderprop:this.orderprop}));
        }
    }
}
</script>

<style scoped>
.deliver_contain {
    font-size: 14px;
}

.deliver_filter {
    padding: 15px 25px 0 25px;
}

.filter_row {
    display: flex;
    justify-content: left;
    flex-wrap: nowrap;
    margin-bottom: 10px;
}
.pagesizeselect .el-select /deep/ .el-input--small .el-input__inner{
    width: 70px;
}
.dropdown {
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #FFF;
    border: 1px solid #DCDFE6;
    color: #606266;
    /* -webkit-appearance: none; */
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    transition: .1s;
    font-weight: 500;
    padding: 8px 15px;
    user-select: none;
    font-size: 14px;
    border-radius: 4px;
}

.dropdown:hover {
    color: #409EFF;
    border-color: #c6e2ff;
    background-color: #ecf5ff;
}

.dropdown:active {
    color: #3a8ee6;
    border-color: #3a8ee6;
    outline: 0;
}

.filter_col {
    display: flex;
    width: 20%;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
}

.filter_col span {
    margin: auto 0;
    white-space: nowrap;
}


.deliver_contain /deep/ .el-radio-button .el-radio-button__inner {
    height: 32px;
    line-height: 5px;
    padding: 12px 20px;
}

.el-table /deep/ .el-table__header .cell {
    overflow: hidden;
    white-space: nowrap;
}

.el-table /deep/ .el-table__header .cell .el-checkbox:first-child {
    padding-left: 5px;
}
.option /deep/ span{
    min-width: 150px;
    max-width: 33vw;
    display: flex;
    word-break: break-all;
    height: auto;
    white-space: pre-wrap;
}
.el-select-dropdown__item{
    height: auto;
}
.filter_btns {
    display: flex;
    justify-content: space-between;
}

.deliver_contain /deep/ .el-button {
    padding: 8px 10px;
}
.filter_flex{
    width: 27%;
    padding-left: 10px;
    padding-right: 10px;
}

.withtaxState{
    display: flex;
    width: 5%;
    height: 32px;
    min-width: 55px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
    margin: auto 0;
    white-space:nowrap;
    
}

.row_width{
    justify-content: left;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner{
    width: 100%;
}
.el-input /deep/ .el-input__inner{
    width: 100%;
}
.deliver_contain .checkInfo {
    width: 28%;
    display: flex;
    justify-content: space-between;
    margin-right: 2%;
}
.deliver_contain .bottom {
    font-size: 16px;
    position: relative;
    /* bottom: 0; */
    /* left: 0; */
    width: calc(100% - 28px);
    padding: 6px 14px;
    z-index: 999;
    background-color: white;
    display: flex;
    justify-content: space-between;
    line-height: 30px;
    box-shadow: 0 2px 4px rgb(0 0 0), 0 0 6px rgb(0 0 0 / 4%);
    /* flex-wrap: wrap; */
}
@media screen and (max-width: 1500px) {
  
    .deliver_contain .el-button{
        padding: 8px 7px;
    } 
    .el-button+.el-button{
        margin-left: 5px;
    }
    .deliver_contain /deep/ .el-radio-button .el-radio-button__inner {
        padding: 12px 10px;
    }
    .deliver_contain .bottom{
        padding: 6px;
        width: calc(100% - 12px);
    }
    .row_width{
    width: 100%;
    }
    .deliver_contain .checkInfo{
        width: 30%;
        margin-right:0; 
    }
    .el-pagination /deep/ .number{
        padding: 0 1px;
    }
    .filter_flex{
    width: 30%;
}
}

.deliver_contain /deep/ .el-table th.el-table__cell {
    background-color: #f9f9f9;
}

.deliver_contain /deep/ .el-table th.el-table__cell>.cell {
    /* color: black; */
    color: #5d5d5d;
}

.deliver_contain /deep/ .el-table .el-input__inner {
    width: 80px;
    height: 30px;
}
.deliver_contain /deep/ .el-table .el-select{
    width: 80%;
    min-width: 100px;
}
.deliver_contain /deep/ .el-table .el-select .el-input__inner{
    width: 100%;
}
.pop-address{
    width: 90%;
    margin: 0 5%;
}

.deliver_contain /deep/ .el-checkbox__label {
    font-size: 16px;
}

.deliver_contain .checkNum {
    width: 80px;
}
.deliver_contain .changeAddress{
    display: flex;
    min-height: 30px;
    margin: auto 0;
    flex-wrap: nowrap;
}
.deliver_contain .changeAddress:hover{
    color: #409EFF;
    cursor: pointer;
}
.deliver_contain .changeIcon{
    filter: grayscale(1);
    margin: auto 2px;
    width: 3%;
}
.deliver_contain .el-table /deep/ .cell{
    font-size: 13px;
}
.deliver_contain .changeAddress:hover .changeIcon{
    filter: grayscale(0);
}
.el-table /deep/ .sort-column .caret-wrapper{
    height: 15px;
    zoom: 1.2;
}
.el-table /deep/ .sort-column .ascending{
    top:-4px;
}
.el-table /deep/ .sort-column .descending{
    top: 8px;
}
.taxcheckBox{
    margin: auto 0;
}
/* .deliver_contain /deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner{
    border: 2px solid #409EFF;
    color: #409EFF;
} */
</style>

<style>
    .confirmBox{
        width: 700px ;
    }
    .confirmDivBox{
        max-height: 200px;
        overflow: auto;
    }
    /* 取消 */
    .confirmBox .el-message-box__btns button:nth-child(1){
        background: #409EFF;
        border-color: #409EFF;
        color: #FFFFFF;
        float: right;
        margin: 0 20px;
    }
    .confirmBox .el-message-box__btns button:nth-child(1):hover{
        background: #66b1ff;
        border-color: #66b1ff;
        color: #FFF;
    }

    /* 继续添加 */
    .confirmBox .el-message-box__btns button:nth-child(2){
        background: #FFF;
        border: 1px solid #DCDFE6;
        color: #606266;
    }
    .confirmBox .el-message-box__btns button:nth-child(2):hover{
        color: #409EFF;
        border-color: #c6e2ff;
        background-color: #ecf5ff;
    }
</style>