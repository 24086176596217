<template>
  <Frame>
    <PageHeader :title="$t('comm.reconciliationdetail')" :hasBack="true" detailType="ReconciliationDetail" />
    <div class="reconciliationdetail" style="padding:10px;">
      <div class="contain">
        <div class="message" style="width:100%;">
          <ul>
            <li class="messagetitle">{{ $t('comm.messagetitle') }}</li>
            <li style="width: 20%;"></li>
            <li class="button option-icon" id="Export" @click="Export()" v-if="$store.state.exportExcelDetailState"> <svg-icon
                icon-class="export" />{{ $t('button.export') }}</li>
            <li class="button option-icon" id="print" @click="printdialog()"><svg-icon
                icon-class="print" />{{ $t('button.print') }}</li>
          </ul>
          <ul style="padding:0 20px;display: flex;">
            <li class="message">{{ $t('ReconciliationDetail.messagenumber') }}:{{ billNo }}</li>
            <li class="message">{{ $t('ReconciliationDetail.messagedate') }}：{{ billdate }}</li>
            <li class="message">{{ $t('ReconciliationSearch.compName') }}：{{ compName }}</li>
            <li class="message">{{ $t('ReconciliationDetail.purchaser') }}：{{ purchaser }}</li>
          </ul>
          <ul style="padding:0 20px;display: flex;">
            <li class="message">{{ $t('ReconciliationDetail.amount') }}：{{ amount }}</li>
            <li class="message">{{ $t('ReconciliationDetail.taxes') }}：{{ taxes }}</li>
            <li class="message">{{ $t('ReconciliationDetail.taxamount') }}：{{ taxamount }}</li>
            <li class="message"></li>
          </ul>
          <ul style="padding:0 20px;display: flex;">
            <li class="message">{{ $t('ReconciliationDetail.conditionName') }}：{{ conditionName }}</li>
            <li class="message">{{ $t('ReconciliationDetail.remark') }}：{{ remark }}</li>
            <li class="message accessory">
              <div class="button option-icon upLoad" id="print" @click="upLoadAccessory(progId,billNo)">
                <svg-icon icon-class="uploadFileSelected" /> {{ $t('comm.uploadFile') }}
              </div>              
              <div class="accessoryBox" id="accessoryBox">
                <span v-for="(item) in accessData">
                  <label @click="downLoadAccessory(item.fileId,item.fileName)">{{ item.fileName }}</label>
                  <i class=" el-icon-close closeIcon" @click="deleteAccessory(item.fileId,item.fileName)"></i>
                </span>
              </div>
            </li>
            <li class="message"></li>
          </ul>
        </div>
      </div>
        <div class="messagetitle" style="padding: 0 16px 20px;">{{ $t('comm.detailtitle') }}</div>
      <el-table :data="tableData" :height="tableHeight" style="margin:0;width:100%" v-loading="tableLoading">
        <el-table-column prop="Number" width="60" :label="$t('ReconciliationDetail.number')"></el-table-column>
        <el-table-column :width="WidthControl('MaterialId',tableData)"  prop="MaterialId" :label="$t('ReconciliationDetail.codeid')"></el-table-column>
        <el-table-column :width="WidthControl('MaterialName',tableData)"  prop="MaterialName" :label="$t('ReconciliationDetail.name')"></el-table-column>
        <el-table-column :width="WidthControl('MaterialSpec',tableData)"  prop="MaterialSpec" :label="$t('ReconciliationDetail.specifications')"></el-table-column>
        <el-table-column prop="SQuantity" align="right" :min-width="WidthControl('SQuantity&&SUnitName',tableData)" :label="$t('ReconciliationDetail.pricenumber')">
          <template slot-scope="scope">
            <span>{{ scope.row.SQuantity }}&nbsp{{ scope.row.SUnitName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="PresentQuantity" align="right" :min-width="WidthControl('PresentQuantity&&SUnitName',tableData)"  :label="$t('ReconciliationDetail.presentQuantity')">
          <template slot-scope="scope">
            <span>{{ scope.row.PresentQuantity }}&nbsp{{ scope.row.SUnitName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="SPrice" align="right"  :label="$t('ReconciliationDetail.price')"></el-table-column>
        <el-table-column prop="TaxName" :label="$t('ReconciliationDetail.taxName')"></el-table-column>
        <el-table-column prop="TaxRate" :label="$t('ReconciliationDetail.taxRate')"></el-table-column>
        <el-table-column :width="WidthControl('OAmountWithTax',tableData,6)" prop="OAmountWithTax" align="right"  :label="$t('ReconciliationDetail.taxamount')"></el-table-column>
        <el-table-column :width="WidthControl('FromSourceTag',tableData,6)" prop="FromSourceTag" :label="$t('ReconciliationDetail.sourcetype')"></el-table-column>
        <el-table-column :width="WidthControl('FromBillNo',tableData,6)" prop="FromBillNo" :label="$t('ReconciliationDetail.sourcenumber')"></el-table-column>
        <el-table-column :width="WidthControl('FromPurPurchaseOrder',tableData,6)" prop="FromPurPurchaseOrder" :label="$t('ReconciliationDetail.purnumber')"></el-table-column>
        <el-table-column :width="WidthControl('Remark',tableData)" prop="Remark" :label="$t('ReconciliationDetail.remark')"></el-table-column>
      </el-table>
    </div>
    <el-dialog width="25%" :title="$t('OrderDetail.tip')" :visible.sync="printvisible">
      <el-select style="width:100%" class="dialogselect" v-model="printType"
        :placeholder="typegather.length != 0 ? $t('OrderDetail.placeholder') : $t('OrderDetail.noneholder')">
        <el-option v-for="item in typegather" :key="item.value" :label="item.name" :value="item.value">
        </el-option>
      </el-select>
      <div slot="footer" class="dialog-footer">
        <el-button @click="print()" type="primary">{{ $t('button.determine') }}</el-button>
      </div>
    </el-dialog>
    <!-- 引入附件上传子组件 -->
    <upLoaadBox :base="base" :uploadDate="uploadDate" :allowFileTypeListTxt="allowFileTypeListTxt" :allowFileTypeList="allowFileTypeList"></upLoaadBox>
  </Frame>
</template>

<script>
import {Serialize} from '../utils/utils'
import SvgIcon from '../components/SvgIcon'
import upLoaadBox from "@/components/DialogUpLoad.vue"
import { PrintReport } from "@/utils/ReportPreView"
export default {
  name: 'ReconciliationDetail',
  components: { 
    SvgIcon,
    upLoaadBox
  },
  data() {
    return {
      allowFileTypeListTxt:'', // 允许上传附件后缀名列表显示
      allowFileTypeList:'', // 允许上传附件后缀名列表
      accessoryTypeName:'', // 附件类型名称
      isCanEdit: false, // 附件是否审核后可异动
      permitStateName:'', // 审核状态名称（后台传回）
      progId:'ApPurCheckReqBill',
      tableHeight: window.innerHeight - 440,
      billNo: '',
      internalId: '',
      billdate: '',
      amount: '',
      taxes: '',
      taxamount: '',
      compName:'',
      purchaser: '',
      remark: '',
      conditionName:'',
      tableLoading: false,
      tableData: [],
      typegather:[],
      printvisible:false,
      printType: '',
      printtemp: '',
      accessData:'',
      uploadDate:'',
      base:this,
      showUpload:false
    }
  },
  created() {
    this.billNo = this.$route.query.DelBillNo;
    this.internalId = this.$route.query.InternalId;
  },
  mounted() {
    let that = this;
    window.onresize = () => {
      if (that.resizeFlag) {
        clearTimeout(that.resizeFlag);
      }
      that.resizeFlag = setTimeout(() => {
        that.tableHeight = window.innerHeight - 440;
        that.resizeFlag = null;
      }, 100);
    }
    that.getDetail();
  },
  methods: {
    Export() {//导出
      let action = 'exportexcel';
      let progId = this.progId; // 'ApPurCheckReqBill'
      //取回当前查询表进行导出
      this.exportExcel(action, progId, this.tableData)
    },
    print() {//打印
      PrintReport(this);
    },
    // 获取单据附件
    getbillaccessorylist(){
      this.axiosInvoke("/SupplierComm/Handler/AccessoryHandler.ashx", // "/SupplierComm/Handler/VueSysHandler.ashx",
        {
          action:"getlist",//  "getbillaccessorylist",
          InternalId: this.internalId,
          ProgId: this.progId, //"ApPurCheckReqBill",
          isVuePage: true
        },
        (res) => {
          if(res.data){
            this.accessData = eval(res.data);
          }else{
            this.accessData = '';
          }
        });
    },
    UpdateAccOpInfo(operation,accessoryName){
      this.axiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx",{
            action:'updateaccopinfo',
            ProgId:this.progId,
            BillNo:this.billNo,
            AccessoryName:accessoryName,
            operation:operation
          })
        },
    // 获取附件类型（供应商平台配置）
    getAccessOryType(){
      this.axiosInvoke("/SupplierComm/Handler/AccessoryHandler.ashx",
        {
          action:"getaccessorytype"  // BUG202310270106  新版供应商，对账查询，打开详情报错"数据库服务器[192.168.89.50MSSQLSERVER2014,1443]不存在数据库[NBSnull],请联系系统管理员处理"
        },
        (res) => {
          if(res.data != ''){
            let data = res.data;
            // this.accessoryTypeId = data.AccessoryTypeId;
            this.accessoryTypeName = data.AccessoryTypeName;
            this.getModifyHascChecked(data.AccessoryTypeId); // 附件是否审核后可异动
          }
        });
    },
    // 附件是否审核后可异动
    getModifyHascChecked(accessoryTypeId){
      this.axiosInvoke("/SupplierComm/Handler/AccessoryHandler.ashx",
        {
          action:"modifyhaschecked",
          accessoryTypeId: accessoryTypeId  // BUG202310270106  新版供应商，对账查询，打开详情报错"数据库服务器[192.168.89.50MSSQLSERVER2014,1443]不存在数据库[NBSnull],请联系系统管理员处理"
        },
        (res) => {
          if(res.data != ''){
            let data = res.data;
            this.isCanEdit = data.ModifyHasChecked;
            this.allowFileTypeList = data.FileExtension;
            this.allowFileTypeListTxt = data.FileExtension == "*.*" ? this.$t('comm.allFileType') : data.FileExtension;
          }
        });
    },
    // 下载附件——同步套装经销商下载逻辑
    downLoadAccessory(accessoryId, accessoryName) {
      accessoryId = encodeURIComponent(accessoryId);
      var AccessoryName = encodeURIComponent(accessoryName);
      // 下载附件时增加权限检查
      this.axiosInvoke('/SupplierComm/Handler/BillInfo.ashx', // '/SupplierComm/Handler/BillInfo.ashx',
        {
          action: "download",
          billno: this.billNo,
          ProgId: this.progId,
          // AccessoryList: JSON.stringify(this.accessData),
          InternalId: this.internalId,
          isVuePage: true,
          AccessState:'download' // 附件状态：新增、删除、下载
        },
        (res) => {
   		this.UpdateAccOpInfo("download",accessoryName);
          var data = res.data;
          if(data && data.download){
            var url = '/SupplierComm/Handler/AccessoryHandler.ashx?action=download&accessoryId=' + accessoryId + '&accessoryName=' + AccessoryName +'&progId='+ this.progId +'&AccessState=download';
            window.open(url, 'DownloadFile');
          }
        });
    },
    //  ADV202310180019 新版供应商，增加【维护附件类型】配置信息检查和控制，当单据已审核，供应商平台根据已配置【维护的附件类型】的【审核后可异动】进行控制是否允许附件的上传功能
    // 上传附件
    upLoadAccessory(){
      if(!this.isCanEdit && this.permitStateName == this.$t('ReconciliationDetail.PermitState_2')){
        let tipMsg= this.$t('ReconciliationDetail.bill')+this.$t('ReconciliationDetail.PermitState_2')+","+this.$t('ReconciliationDetail.accessoryType')+"("+this.accessoryTypeName+")"+this.$t('ReconciliationDetail.canNotAddAccess');
        this.$alert(tipMsg, this.$t('comm.tip'), { confirmButtonText: this.$t('comm.confirm') });
        return;
      }
      this.uploadDate = new Date().toLocaleString();
      this.printvisible = false;
      // 触发弹窗，选择上传附件
      this.showUpload = true;      
    },
    // 删除附件
    deleteAccessory(accessoryId,accessoryName){
      if(!this.isCanEdit && this.permitStateName == this.$t('ReconciliationDetail.PermitState_2')){
        let tipMsg= this.$t('ReconciliationDetail.bill')+this.$t('ReconciliationDetail.PermitState_2')+","+this.$t('ReconciliationDetail.accessoryType')+"("+this.accessoryTypeName+")"+this.$t('ReconciliationDetail.canNotDeleteAccess')+"("+accessoryName+")";
        this.$alert(tipMsg, this.$t('comm.tip'), { confirmButtonText: this.$t('comm.confirm') });
        return;
      }
      this.$confirm(this.$t('comm.deleteFileTip'),this.$t('comm.tip'),{
        confirmButtonTextText:this.$t('comm.confirm'),
        concelButtonText:this.$t('comm.cancelText'), //'取消',
        type:'warning'
      }).then(() =>{
        // 执行删除附件逻辑
        // 然后再执行保存逻辑，调用套装方法执行删除
        if(this.accessData && this.accessData.length > 0){
          this.accessData.forEach(access =>{
            if(access.fileId == accessoryId){
              access.state = 3; // 附件状态修改为:3 删除
              // 执行保存（删除）
              this.tableLoading = true;
              this.axiosInvoke('/SupplierComm/Handler/BillInfo.ashx', // '/SupplierComm/Handler/BillInfo.ashx',
              {
                  action: "save",
                  billNo: this.billNo,
                  progId: this.progId,
                  AccessoryList: JSON.stringify(this.accessData),
                  InternalId: this.internalId,
                  isVuePage: true,
                  AccessState:'delete' // 附件状态：新增、删除、下载
              },
              (res) => {
                this.UpdateAccOpInfo("delete",accessoryName);
                this.tableLoading = false;
                var data = res.data;
                if(data && data.isDoPostFile){ // 删除成功后的逻辑
                  //执行后的提示
                  this.$message({
                    type:'success',
                    message:this.$t('comm.deleteSuccess'), //'删除成功！'
                  });
                  // 重新获取附件
                  this.getbillaccessorylist();
                }
              })
            }
          });
        }
      }).catch(() =>{
        this.$message({
          type:'info',
          message:this.$t('comm.cancelDelete'), //'已取消删除'
        })
      });
    },
    printdialog() {
      this.showUpload = false;
      this.printvisible = true;
      this.axiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx",
        {
          action: "doprintbill",
          BillNo: this.billNo,
          ProgId: this.progId, //"ApPurCheckReqBill",
          lang: localStorage.getItem("language") == "CHS"?"zh-CHS":"zh-CHT"
        },
        (res) => {
          var data = eval(res.data);
          this.printtemp = data[0];
          this.typegather = data[0].files;
          this.printType = decodeURIComponent(data[0].printType);
          var printExist = false;
          this.typegather.forEach(v=>{
            if(v.value == this.printType){
              printExist = true;
              return;
            }
          })
          if(!printExist)this.printType = "";
        })
    },
    getDetail() {
      this.tableLoading = true;
      this.axiosInvoke(
        "/SupplierComm/Handler/VueSysHandler.ashx", {
        action: 'getreconciliationdetail',
        billno: this.billNo
      }, (res) => {
        var data = eval(res.data);
        this.progId = data[0].ProgId;
        this.billdate = data[0].BillDate;
        this.compName = data[0].CompName;
        this.purchaser = data[0].PersonName;
        this.amount = data[0].OAmount;
        this.taxes = data[0].OTax;
        this.taxamount = data[0].OAmountWithTax;
        this.remark = data[0].Remark;
        this.conditionName = data[0].ConditionName;
        this.permitStateName = data[0].PermitState;
        data.shift(0);
        this.tableData = data;
        this.tableLoading = false;
        console.log(data);
        this.getAccessOryType(); // 附件类型（供应商平台配置）
        this.getbillaccessorylist();
      }
      );
    }
  },
  watch:{
  }
}
</script>

<style scoped>
.message ul {
  list-style: none;
  padding: 0;
  display: flex;
}

.message li {
  text-align: left;
  list-style: none;
}

.messagetitle {
  color: #5d5d5d;
  font-size: 16px;
  width: 60%;
  padding: 0 20px;
}

.button {
  color: #5d5d5d;
  font-size: 15px;
  cursor: pointer;
}

.message {
  color: #777777;
  font-size: 14px;
  width: 30%;
  padding-right: 2%;
}

.el-table /deep/ th.el-table__cell {
  background-color: #f9f9f9;
}

.el-table /deep/ .el-table__body-wrapper {
  font-size: 13px;
}

.el-table /deep/ thead {
  font-size: 14px;
  color: #777777;
}

.el-table /deep/ .el-table_1_column_1 .cell:first-child {
  padding: 0 0 0 30px;
}

.el-table /deep/ .cell:first-child {
  font-weight: normal;
}
.option-icon{
  filter: grayscale(100%);
  margin:0 2.5%;
}
.option-icon:hover{
  filter: grayscale(0%);
}
.button:hover {
  cursor: pointer;
  color: #0177d5;
}
.upLoad{
  width: 10%;
  min-width: 120px;
  padding-right: 20px;
}
/* .accessory {
  width: 30%;
} */
.accessory span{
  padding: 0 10px;
  white-space:nowrap;
  display: inline-block;
  cursor: pointer;
}
.accessoryBox span :hover{  
  color:#0177d5;
}
.closeIcon{
  cursor: pointer;
  /* color: red; */
}
</style>
