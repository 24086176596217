<script>
    import { Tooltip } from 'element-ui';
    export default{
        name:"X-el-tooltip",
        extends:Tooltip,
        props:{
            ...Tooltip.props,
            overflow:Boolean
        },
        methods:{
            show(){
                if(this.overflow){
                    if(this.$el.scrollWidth<=this.$el.clientWidth)return;
                }
                Tooltip.methods.show.call(this);
            }
        }
    }
</script>