<template>
  <Frame>
    <PageHeader :title="$t('comm.ARSearch')" :hasBack="false" />
    <div class="ARSearch" style="padding:10px 0 0;">
      <div class="ARmessage">
        <span class="detialmessage"><span style="margin: auto 0;">{{ $t('ARSearch.messagedate') }}：</span><el-date-picker v-model="date" type="daterange"
            style="min-width: 215px; color:#bbbbbb;width: 100%;" range-separator="~" :start-placeholder="$t('comm.startdate')" :default-value="timeDefault"
            :unlink-panels="true" :end-placeholder="$t('comm.enddate')" @change="gettime()" size="small"></el-date-picker></span>
        <span class="detialmessage"><span style="margin: auto 0;">{{ $t('ARSearch.compName') }}：</span><el-input :placeholder="$t('ARSearch.inputpls')" v-model="compName" 
          clearable style="color:#bbbbbb;" @change="search(0)" size="small"></el-input></span>
        <span class="checkbox-group">
          <el-checkbox v-model="showPreRecAmount" :disabled="isDisabled" @change="changeTaxState()" :label="$t('ARSearch.preRecOAmountCheckText')"></el-checkbox>
          <el-checkbox v-model="ShowUnZeroItem" :disabled="isDisabled" @change="changeTaxState()" :label="$t('ARSearch.ShowUnZeroItem')"></el-checkbox>
          <el-checkbox v-model="ShowUnZeroEnd" :disabled="isDisabled" @change="changeTaxState()" :label="$t('ARSearch.ShowUnZeroEnd')"></el-checkbox>
        </span>   
        <span class="querybtn">
          <el-button type="primary" @click="query()" style="padding: 8px 20px;">{{ $t('button.query') }}</el-button>
        </span>
      </div>
      <div class="ARmessage" v-if="WOByOrder">
        <span class="detialmessage" style="min-width: 257px;"><span style="margin: auto 0;">{{ $t('ARSearch.FromBillNo') }}：</span><el-input :placeholder="$t('ARSearch.inputpls')" v-model="OrderBillNo" 
          clearable style="color:#bbbbbb;" @change="search(0)" size="small"></el-input></span>
      </div>
      <el-table ref="multipleTable" :data="tableData" :height="tableHeight" v-loading="tableLoading"
        :summary-method="getSummaries" show-summary style="margin:24px 0 0;width:100%" tooltip-effect="dark">
        <el-table-column prop="CompName" :label="$t('ARSearch.compName')" ></el-table-column>
        <el-table-column v-if="WOByOrder" prop="OrderBillNo" :label="$t('ARSearch.FromBillNo')" ></el-table-column>
        <el-table-column prop="billtype" :label="$t('ARSearch.documenttype')" ></el-table-column>
        <el-table-column prop="billno" :label="$t('ARSearch.messagenumber')" ></el-table-column>
        <el-table-column prop="billdate" :width="WidthControl('billdate',tableData,6,-20)" :label="$t('ARSearch.date')" ></el-table-column>
        <el-table-column v-if="showPreRecAmount" prop="preRecOAmount" align="right" :label="$t('ARSearch.preRecOAmount')" >
          <template slot-scope="scope">
            <span>{{ scope.row.preRecOAmount | MoneyFormat }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="amountreceivable" align="right" :label="$t('ARSearch.amountreceivable')" >
          <template slot-scope="scope">
            <span>{{ scope.row.amountreceivable | MoneyFormat }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="currentamount" align="right" :label="$t('ARSearch.currentamount')" >
          <template slot-scope="scope">
            <span>{{ scope.row.currentamount | MoneyFormat }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="discountamount" align="right" :label="$t('ARSearch.discountamount')" >
          <template slot-scope="scope">
            <span>{{ scope.row.discountamount | MoneyFormat }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="unoffsetoamount" align="right" :label="$t('ARSearch.lateamount')" >
          <template slot-scope="scope">
            <span>{{ scope.row.unoffsetoamount | MoneyFormat }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </Frame>
</template>

<script>
import { getTimeoutVal } from '../utils/utils.js'
import { handleShortDate } from '../utils/handleDate.js'
import SvgIcon from '../components/SvgIcon'
export default {
  name: 'ARSearch',
  components: { SvgIcon },
  data() {
    return {
      OrderBillNo:'',
      WOByOrder:false,
      isDisabled: false, //复选框按钮不可编辑
      tableLoading: false,
      tableHeight: window.innerHeight - 314,
      date: '',
      compName:'',
      startdate: '',
      enddate: '',
      showPreRecAmount: false,
      ShowUnZeroEnd:false,
      ShowUnZeroItem:true,
      timeDefault:'',
      tableData: [{
        billtype: '',
        billno: '',
        billdate: '',
        amountreceivable: '',
        currentamount: '',
        discountamount: '',
        unoffsetoamount: ''
      }]
    }
  },
  mounted() {
    let that = this;
    window.onresize = () => {
      if (that.resizeFlag) {
        clearTimeout(that.resizeFlag);
      }
      that.resizeFlag = setTimeout(() => {
        if(that.WOByOrder){
          that.tableHeight = window.innerHeight - 314;
        }else{
          that.tableHeight = window.innerHeight - 264;
        }
        that.resizeFlag = null;
      }, 100);
    }
    this.timeDefault =new Date();
    this.timeDefault.setMonth(new Date().getMonth()-1);
    //同旧版 进入页面时默认取近一个月的数据
    this.startdate = handleShortDate(new Date(new Date().getTime() - 30 * 3600 * 24 * 1000), "From");
    this.enddate = handleShortDate(new Date(), "From");
    this.date = [new Date(new Date().getTime() - 30 * 3600 * 24 * 1000), new Date()];
    this.getinfo();
  },
  methods: {
    search(){
      this.getinfo();
    },
    gettime() {
      if(!this.date) {
        this.startdate = "00000000";
        this.enddate = "99999900";
        this.getinfo();
        return;
      }
      this.startdate = handleShortDate(this.date[0], "From");
      this.enddate = handleShortDate(this.date[1], "To");
      this.getinfo();
    },
    getinfo() {
      //获取页面数据
      var timeVal= getTimeoutVal(); //设置计时器统一时间 //控制复选框更新频率500毫秒
      this.isDisabled = true;
      setTimeout(() =>{ 
                this.isDisabled = false;
            },timeVal);
      this.tableLoading = true;
      this.axiosInvoke("/SupplierComm/Handler/OrderPurPurchaseHandler.ashx",
        {
          action: "getapfunddetailrpt",
          dateRangeEnd: this.enddate,
          dateRangeBegin: this.startdate,
          showPreRecAmount: this.showPreRecAmount,
          compName:this.compName,
          ShowUnZeroEnd:this.ShowUnZeroEnd,
          ShowUnZeroItem:this.ShowUnZeroItem,
          OrderBillNo:this.OrderBillNo
        },
        (res,errflag) => {
        if(errflag) { this.tableLoading = false; this.tableData = null; return;}
        var data = eval(res.data);
        if(data.length){
          this.WOByOrder = data[0].WOByOrder;
          data.shift();
          if(!this.WOByOrder){
            this.tableHeight = window.innerHeight - 264;
          }
        }
        for(var i=0;i<data.length;i++){
          if(data[i].billtype == "") data[i].billtype = this.$t('ARSearch.billTypeAuto') // "期初应付";
          if(data[i].billtype.indexOf(this.$t('ARSearch.typeNameYF')) >= 0 ){
            data[i].billtype = data[i].billtype.replace(this.$t('ARSearch.typeNameYF'),this.$t('ARSearch.typeNameYS'));
          }
          if(data[i].billtype.indexOf(this.$t('ARSearch.typeNameYuF')) >= 0){
            data[i].billtype = data[i].billtype.replace(this.$t('ARSearch.typeNameYuF'),this.$t('ARSearch.typeNameYuS'));
          }
        }
        this.tableData = data;
        this.tableLoading = false;
      }
      )
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.$t("ARSearch.summary");
          return;
        }
        if (index == 1 || index == 2 || index == 3 || (this.WOByOrder && index == 4)) return;
        sums[index] = 0
        var decimallength = 0;
        if(data.length){
          var numlist = data[0][column.property].split('.');
          if(numlist && numlist.length>1){
            decimallength = numlist[1].length;
          }
        }
        data.forEach(v=>{
          if(v[column.property] != "-----") {
            sums[index] += Number(v[column.property].replace(/,/g,""));
          }
        })
        let sumstr = String(Number(sums[index]).toFixed(decimallength));
        sums[index] = sumstr.substring(0,sumstr.indexOf(".")).replace(/\B(?=(\d{3})+(?!\d))/g,",")+sumstr.substring(sumstr.indexOf(".")); 
      });
      return sums;
    },
    changeTaxState(){
      // if(this.$route.query.hasOwnProperty('showPreRecAmount')){
      //     if(String(this.showPreRecAmount) == this.$route.query.showPreRecAmount){
      //         this.billnos = sessionStorage.getItem("OrderToDeliverShipment");
      //     }else{
      //         this.billnos = [];
      //     }
      // }
        this.getinfo();
    },
    query() {//查询
      this.getinfo();
    },
    //加载el-tooltip信息，鼠标聚焦时显示完整信息
    renderTitle (h,{column}) {
      let titleValue = '';
      let columnRealWidth = column.realWidth;
      switch(column.property){
          case'billtype':
              titleValue = this.$t("ARSearch.documenttype");
          break;
          case'billno':
              titleValue = this.$t("ARSearch.messagenumber");
          break;
          case'CompName':
              titleValue = this.$t("ARSearch.compName");
          break;
          case'billdate':
              titleValue = this.$t("ARSearch.date");
          break;
          case'amountreceivable':
              titleValue = this.$t("ARSearch.amountreceivable");
          break;
          case'preRecOAmount':
              titleValue = this.$t("ARSearch.preRecOAmount");
          break;
          case'currentamount':
              titleValue = this.$t("ARSearch.currentamount");
          break;
          case'discountamount':
              titleValue = this.$t("ARSearch.discountamount");
          break;
          case'unoffsetoamount':
              titleValue = this.$t("ARSearch.lateamount");
          break;
          default:
              titleValue = 'ARSearch默认栏位信息（测试）'
              break;
      }
      return this.renderTooltip(h,titleValue,columnRealWidth)
    }
  }
}
</script>

<style scoped>
.detialmessage {
  color: #5d5d5d;
  font-size: 14px;
  width: 20%;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  min-width: 245px;
}

.ARmessage {
  padding: 10px 20px;
  width: 100%;
  display: flex;
}

.el-checkbox /deep/ .el-checkbox__label {
  color: #5d5d5d;
  font-size: 14px;
}

.el-table /deep/ .el-table__header .cell {
    overflow: hidden;
    white-space: nowrap;
}

.el-table /deep/ th.el-table__cell {
  background-color: #f9f9f9;
}

.el-table /deep/ .el-table__body-wrapper {
  font-size: 13px;
}

.el-table /deep/ thead {
  font-size: 14px;
  color: #5d5d5d;
}

.topbutton {
  color: #777777;
  font-size: 14px;
}
.checkbox-group{
  padding-left:50px;
}
.querybtn{
  padding-left:120px;
}
@media screen and (max-width: 1600px) {
  .querybtn{
    padding-left:30px;
  }
  .checkbox-group{
  padding-left:15px;
 }
 .checkbox-group .el-checkbox{
  margin-right: 15px;
 }
}
@media screen and (max-width: 1350px) {
  .querybtn{
    padding-left:0px;
  }
  .ARmessage{
    padding: 10px 0;
  }
  .checkbox-group{
  padding-left:0px;
 }
 .checkbox-group .el-checkbox{
  margin-right: 5px;
 }
}
</style>
