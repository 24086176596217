<template>
    <el-dialog :visible.sync="visible" width="33%">
        <span slot="title"><i style="color:#409EFF" class="el-icon-warning"></i>{{$t('comm.tip')}}</span>
        <p>{{ $t('DeliverSuccess.totalcount').replace("{0}",selectCount)  }}</p>
        <p>{{ $t('DeliverSuccess.successcount').replace("{0}",successCount)  }}</p>
        <div style="display:flex;"><span>{{$t('DeliverSuccess.billno')}}:</span><el-link @click="toDeliverSearch(billno)" style="margin-left:5px"
                v-for="billno in billnoArray">{{ billno }}</el-link></div>
        <p style="color:#959595">{{$t('DeliverSuccess.texttip')}}</p>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="visible = false">{{ $t('comm.confirm') }}</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "DeliverSuccess",
    props: {
        selectCount: Number,
        successCount: Number,
        billnoArray: Array
    },
    data() {
        return {
            visible: false,
        }
    },
    methods: {
        toDeliverSearch(DelBillNo) {
            this.$router.push({
                path: "/Home/DeliverSearchDetail",
                query: {
                    DelBillNo
                }
            })
        }
    }
}
</script>

<style scoped>
.el-dialog__body{
    padding: 0 20px;
}
</style>

