<template>
    <div class="padd">
        <div v-if="!hasBack" class="header">
            <div class="border"></div><span class="title">{{title}}</span>
        </div>
        <div v-if="hasBack" class="header">
             <span class="back" @click="goBack"><i class="el-icon-arrow-left"></i>返回</span><span class="title">{{title}}</span>
        </div>
    </div>
</template>
<script>
export default {
    name:"PageHeader",
    props:{
        detailType:String,
        title:{
            required:true,
        },
        hasBack:{
            required:true,
        },
        OpreationMenu:Function
    },
    methods:{
        goBack(){
            debugger
            if(this.OpreationMenu){
                this.OpreationMenu();
                return
            }
            if(this.detailType){
                //根据传来的detailType来判断当前打开的是什么页面 由此来读取并转承session中保存的页面搜索历史
                sessionStorage.setItem(this.detailType,(sessionStorage.getItem(this.detailType.substring(0,this.detailType.indexOf("Detail")))));
                //将原本父页面传来的清除
                sessionStorage.removeItem(this.detailType.substring(0,this.detailType.indexOf("Detail")));
            }
            this.$router.back();
        }
    }
}
</script>

<style scoped>
.padd {
    min-height:50px;
    word-break: break-all;
    font-size: 20px;
    color:#5d5d5d;
    line-height: 50px;
    padding: 0 10px;
}
.header{
    border-bottom:1px solid rgb(235,233,233);
}
.border{
    display: inline-block;
    width: 15px;
    height: 23px;
    border-left: 3px solid #518cdd;
    vertical-align: sub;
}
.back{
    border-right: 1px solid #b5b5b5;
    color:#0177d5;
    margin-right: 10px;
    padding-right: 10px;
}
.back:hover{
    cursor:pointer;
}
</style>

