<template>
  <div id="app">
    <router-view></router-view>
    <el-dialog class="errordialog" :visible="dialog.isShow" :before-close="handleClose" :title="dialog.title">
      <el-collapse v-model="activeNames" @change="handlechange">
        <el-collapse-item v-if="dialog.errorlist&&dialog.errorlist.length>1"  v-for="err in dialog.errorlist" class="errorBox" :name="err.Number">
          <template slot="title">
            <div class="errormsg" v-html="err.message"></div>
          </template>
          <div class="dialoginfo" v-html="err.showInfo"></div>
        </el-collapse-item>
        <el-collapse-item v-if="!dialog.errorlist||(dialog.errorlist&&dialog.errorlist.length<=1)" class="errorBox" name="1">
          <template slot="title">
            <div class="errormsg" v-html="dialog.message"></div>
          </template>
          <div class="dialoginfo" v-html="dialog.showInfo"></div>
        </el-collapse-item>
      </el-collapse>
      <div>
        <el-button class="copyBtn" id="copyBtn" type="text" :data-clipboard-text="dialog.copyInfo" @click="doCopy();">{{$t('comm.copyClick')}}</el-button>
      </div>
    </el-dialog>
    <TipPop></TipPop>
  </div>
</template>

<script>
import { mapState,mapMutations } from "vuex"
import Clipboard from "clipboard"
export default {
  name: 'App',
  computed:{
    ...mapState(["dialog"])
  },
  data(){
    return{
      activeNames:["0"]
    }
  },
  methods:{
    handlechange(val){
    },
    handleClose(){
      this.activeNames=["0"];
      if(this.dialog.timer){
        clearTimeout(this.dialog.timer);
        this.$router.push(
          {path:'/Login'}
        );
      }
      this.$store.commit("ERRORTIP",false)
    },
    doCopy(){
      const clipboard = new Clipboard('#copyBtn');
      clipboard.on('success',e => {
        // debugger
        // var text= e.text;
        // this.textCopy(text);
        clipboard.destroy(); // 清理
        //执行后的提示
        this.$message({
          type:'success',
          message: this.$t('comm.copyFinished'), //'已复制到剪切板！'
        });
      });
      clipboard.on('error',e => {
        clipboard.destroy(); // 清理
        //执行后的提示
        this.$message({
          type:'warning',
          message: this.$t('comm.copyError'), //'当前浏览器暂不支持自动复制，请手动选择复制！'
        });
      });
    }
    // 当前clipboard也能格式化到文本带\r\n内容，故以下代码注释
    // ,
    // textCopy(text) { // 通过创建textarea暂存文本带\r\n内容格式化成换行显示
    //   return new Promise(resolve => { 
    //   // 如果复制多行，用textarea 
    //   const textarea = document.createElement("textarea");  // document.getElementById("dialogInfo"); 
    //   // 创建一个textarea框获取需要复制的文本内容 
    //   textarea.value = text; 
    //   // app就是页面已存在的id选择器，主要目的是把textarea放入 
    //   // const appDiv = document.getElementById("app"); 
    //   // appDiv.appendChild(textarea); 
    //   textarea.select();
    //   document.execCommand("Copy"); 
    //   textarea.remove(); 
    //   resolve(true);
    //   }); 
    // }
  },
  watch:{
    "$route.path":{
      handler:function(newval,oldval){
        sessionStorage.setItem("frompath",oldval);
      }
    }
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.errorBox .el-collapse-item__header{
  height: auto;
  max-height: 60vh;
  line-height:normal;
}

.errormsg{
  width: 95%;
  height: 100%;
  display: block;
  overflow: auto;
  /* white-space: nowrap; */
  /* text-overflow: ellipsis; */
}
.dialoginfo{
  max-height: 60vh;
  overflow: auto;
}
.copyBtn{
  color:#0177d5;
}
.copyBtn:hover{
  cursor: pointer;
}
body{
    margin: 0;
    padding: 0;
    font-family: "siyuanregular";
    background-color: #E9ECF3;
}
body,html{
  height: 100%;
}
@font-face {
    font-family: "siyuanmedium";
    src: url(./assets/fonts/siyuanCN-Medium.otf);
    font-family: "siyuanregular";
    src:url(./assets/fonts/siyuanCN-Regular.otf)
}
@media screen and (max-width: 1160px){
  body{
    width: 1160px;
  }
}

.el-table__body-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}
.el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #a1a3a9;
  border-radius: 3px;
}
.el-table__body-wrapper{
  width: 100%;
}
.el-table::before{
  height: 0;
}
.el-table .el-table__cell {
  padding: 10px 0;
}
.MaterialIdClass{
  background: rebeccapurple;
  min-width: 150px!important;
}
.MaterialSpecAndNameClass{
  background: red;
}
.RemarkClass{
  background: green;
}
</style>
