<template>
  <el-dialog :title="$t('Home.changepassword')" :destroy-on-close="true" :visible="dialogFormVisible"
    @close="handleClose" :center="true" width="35%" @opened="opened = true">
    <el-form class="dialogForm" :rules="rules" :model="form" ref="form" label-position="right" :inline="true">
      <el-form-item prop="oldPwd" :label="$t('Home.oldpassword')" >
        <el-input :readonly="form.oldRead" :type="form.oldType" v-model="form.oldPwd" @blur="changeType('old',true)"
        @focus="changeType('old',false)"  maxlength="20" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item prop="newPwd" :label="$t('Home.newpassword')" >
        <el-input :readonly="form.newRead" :type="form.newType" v-model="form.newPwd" @blur="changeType('new',true)"
        @focus="changeType('new',false)"  maxlength="20" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item prop="confirmPwd" :label="$t('Home.confirmpassword')" >
        <el-input :readonly="form.confirmRead" :type="form.confirmType" v-model="form.confirmPwd" @blur="changeType('confirm',true)"
        @focus="changeType('confirm',false)"  maxlength="20" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" >
      <el-button type="primary" ref="confirmbtn" @click="ChangePassword()">{{ $t('button.determine') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { decrypt, encrypt } from "../utils/encrypt";
export default {
  props:{
    changeSucess:Function,
    userId:String,
    groupId:String
  },
  mounted(){
    this.onKeyup();
  },
  data() {
    var validOldPwd = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('Home.oldpswpls')));
      } else if (value.indexOf("<") > -1 || value.indexOf(">") > -1) {
        callback(new Error(this.$t('Home.symbolerr')));
      }
      else {
        callback();
      }
    };
    var validNewPwd = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('Home.newpswpls')));
      } else if (value.indexOf("<") > -1 || value.indexOf(">") > -1) {
        callback(new Error(this.$t('Home.symbolerr')));
      }else if(this.form.confirmPwd!=''){
        this.$refs.form.validateField('confirmPwd');
      }else callback();
    };
    var validConPwd = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('Home.againpsw')));
      } else if (value !== this.form.newPwd) {
        callback(new Error(this.$t('Home.errpsw')));
      } else if (value.indexOf("<") > -1 || value.indexOf(">") > -1) {
        callback(new Error(this.$t('Home.symbolerr')));
      }
      else {
        callback();
      }
    };
    return {
      dialogFormVisible: false,
      isPwdRemind: false,
      form: {
        oldPwd: "",
        oldRead:true,
        oldType:"password",
        newPwd: "",
        newRead:true,
        newType:"password",
        confirmPwd: "",
        confirmRead:true,
        confirmType:"password",
        opened:false,

      },
      rules: {
        oldPwd: [
          { validator: validOldPwd, trigger: 'blur' }
        ],
        newPwd: [
          { validator: validNewPwd, trigger: 'blur' }
        ],
        confirmPwd: [
          { validator: validConPwd, trigger: 'blur' }
        ]
      }
    }
  },
  watch:{
    dialogFormVisible:{
      handler:function(newval,oldval){
        if(newval){
          this.$refs.confirmbtn.$el.focus();
        }
      }
    }
  },
  methods: {
    onKeyup(){
      document.onkeyup = ev => {
        if (typeof(ev.key)!='undefined' && ev.key.toLowerCase() === 'enter' &&  this.dialogFormVisible == true && this.opened == true) //enter键触发登录
        {
          console.log(this.$route.path);
          this.ChangePassword();
        }
      }
    },
    ChangePassword() {
      this.axiosInvoke('/SupplierComm/handler/VueSysHandler.ashx',{action:'getuserconfig'},(res)=>{
        var data = eval(res.data);
        if(this.form.confirmPwd == this.form.newPwd && this.form.oldPwd!="" && this.form.newPwd!=""){
        this.axiosInvoke("/SupplierComm/handler/SysHandler.ashx",
         {
          action:'changepassword',
          platform:'supplier',
          groupId: this.groupId,
          userId: this.userId,
          isPwdRemind: this.isPwdRemind, //登录前修改密码此属性为true，登录后则为false
          oldPassword: this.form.oldPwd,
          newPassword: this.form.newPwd
          },
          (res) => {
            if(res.data){
              console.log(res);
              //===========根据返回结果判断是否修改成功或其他情况============
              if (res.data.success) {
                // 修改密码成功后提示:成功修改密码
                this.$alert(this.$t('comm.updatePassword'),this.$t('comm.tip'),{
                  showClose: false,
                  confirmButtonText: this.$t('button.determine'), //确定按钮
                  customClass:'alertPrompt'
                }).then(()=>{
                  this.dialogFormVisible = false;
                  if(this.isPwdRemind){
                  this.changeSucess(this.form.newPwd);
                }
                })
                console.log(obj);
              }             
            }
          }
        )
      }else{
         if(this.form.confirmPwd != this.form.newPwd){
          //两次新密码输入不一致！
          this.$store.commit("TIPPOP",{popType:'error',popMessage:this.$t('Home.errpsw')});
        }else if(this.form.oldPwd=="" || this.form.newPwd==""){
          //密码框内内容不得为空
          this.$store.commit("TIPPOP",{popType:'error',popMessage:this.$t('Home.oldpwderror')});
        }
      }
      })  
    },
    changeType(type,blur){
      if(blur){
        switch(type){
          case "old":
            this.form.oldRead = true;
            this.form.oldType = "password";
            break;
          case "new":
            this.form.newRead = true;
            this.form.newType = "password";
            break;
          case "confirm":
            this.form.confirmRead = true;
            this.form.confirmType = "password";
            break;
        }
      }else{
        switch(type){
          case "old":
            this.form.oldType = "text";
            this.form.oldRead = false;
            break;
          case "new":
            this.form.newType = "text";
            this.form.newRead = false;
            break;
          case "confirm":
            this.form.confirmType = "text";
            this.form.confirmRead = false;
            break;
        }
      }
    },
    handleClose() {
      this.form.oldPwd = ""
      this.form.newPwd = ""
      this.form.confirmPwd = ""
      this.opened = false;
      this.dialogFormVisible = false
    }
    // ,
    // handlerDetail(path) {
    //   if (path.indexOf("Detail") > -1) {
    //     path = path.slice(0, path.indexOf("Detail"));
    //   }
    //   return path
    // }
  }
}
</script>

<style scoped>
.el-dialog .dialogForm{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-right: 6%;
}
.el-form /deep/ .el-form-item__label{
  width:100px;
}
.el-form--inline .el-form-item{
  display: flex;
    width: 100%;
    justify-content: center;
    margin-right: 10px;
    vertical-align: top;
}
</style>