<template>
  <el-dialog :title="dialogtitle" :visible.sync="dialogvisible"> 
      <el-table v-loading="dialogloading" :data="dialogdata" @select-all="handleSelectionChange"
        @selection-change="handleSelectionChange" height="50vh" ref="dialogview">
        <el-table-column type="selection"></el-table-column>
        <el-table-column property="Number" :label="$t('comm.number')" width="100"></el-table-column>
        <el-table-column property="accessoryName" :label="$t('Download.accessoryname')"></el-table-column>
        <el-table-column property="date" :label="$t('Download.recupdate')"></el-table-column>
        <el-table-column prop="option" :label="$t('Download.operation')" width="100">
          <template slot-scope="scope">
            <el-link v-if="!isBillAccess" :underline="false" @click="DownLoadPrint(scope.$index, scope.row)" type="primary">
              {{ $t('Download.clickdownload') }}
            </el-link>
            <el-link v-if="isBillAccess" :underline="false" @click="DownLoadBillAccess(scope.row)" type="primary">
              {{ $t('Download.clickdownload') }}
            </el-link>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="DownLoadSelect">{{ $t('Download.downloadall') }}</el-button>
      </div>
    </el-dialog>
</template>
<script>
import { uploaddowninfo, downloadprint } from '../utils/utils'
export default{
    data(){
        return{
            dialogdata:[],
            diaselect:[],
            dialogtitle:'',
            dialogvisible:false,
            dialogloading:false,
            BillNo:'',
            ProgId:'',
            InternalId:'',
        }
    },
    methods:{
        handleSelectionChange(val) {
            this.diaselect = val;
        },
        GetAccList(BillNo,ProgId,InternalId,GetType,isBillAccess){
            let action="";
            let getAllAcc = false;
            this.dialogvisible = true;
            this.BillNo = BillNo;
            this.ProgId = ProgId;
            this.InternalId = InternalId;
            this.isBillAccess = isBillAccess;
            if(!this.isBillAccess){
                //图纸走这里
                action=GetType;
                var handleUrl = "/SupplierComm/Handler/DownprintHandler.ashx";

                if(GetType == "viewall"){
                this.dialogtitle = this.$t('Download.viewalldrawings') + "(" + this.BillNo + ")";
                }
                else if (GetType == "viewchange"){
                    this.dialogtitle = this.$t('Download.viewchangedrawings') + "(" + this.BillNo + ")";
                }
            }
          
            else{
                //附件走这里
                action = "getlist";
                handleUrl = "/SupplierComm/Handler/AccessoryHandler.ashx"; // 调用单据附件查询，传参内部标识号
                this.dialogtitle = this.$t('OrderDetail.downloadBillAccess') + "(" + this.BillNo + ")";
                if(GetType == "All")getAllAcc = true;

            }

            //下面都是共用
            this.axiosInvoke(handleUrl, {
                action: action,
                billNo:BillNo,
                InternalId,
                ProgId,
                isVuePage: true,
                getAllAcc
            },
            (res) => {
                var data = eval(res.data);
                for (let i = 0; i < data.length; i++) {
                data[i].Number = i + 1;
                }
                this.dialogdata = data;
                this.dialogloading = false;
                this.$refs.dialogview.toggleAllSelection();
                this.diaselect = this.dialogdata;
                console.log(data);
            })
        },

        DownLoadBillAccess(row){
            this.dialogloading = true;
            let tempAccessoryId = encodeURIComponent(row.accessoryId); // encodeURIComponent(accessoryId);
            let tempAccessoryName = encodeURIComponent(row.accessoryName); // encodeURIComponent(accessoryName);
            if(this.billAccessDownloadPower){
                this.DownloadFile(tempAccessoryId,tempAccessoryName,row);
            }
            else{
                // 下载附件时增加权限检查
                this.axiosInvoke('/SupplierComm/Handler/BillInfo.ashx', // '/SupplierComm/Handler/BillInfo.ashx',
                {
                    action: "download",
                    billno: this.BillNo,
                    ProgId: this.ProgId, // 采购订单
                    // AccessoryList: JSON.stringify(this.accessData),
                    InternalId: this.InternalId, // 单据内部标识号
                    isVuePage: true,
                    AccessState:'download' // 附件状态：新增、删除、下载
                },
                (res) => {
                    this.dialogloading = false;
                    var data = res.data;
                    if(data && data.download){
                    this.billAccessDownloadPower = true;
                    this.DownloadFile(tempAccessoryId,tempAccessoryName,row);
                    }
                });
            }
        },
            DownloadFile(tempAccessoryId,tempAccessoryName,row){ // 下载附件
            this.axiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx",{
                action:'updateaccopinfo',
                ProgId:this.ProgId,
                BillNo:this.BillNo,
                AccessoryName:row.accessoryName,
                operation:'download'
            },(res)=>{
                let iframe = document.createElement("iframe");
                iframe.src ='/SupplierComm/Handler/AccessoryHandler.ashx?action=download&accessoryId=' + encodeURIComponent(tempAccessoryId) + '&accessoryName=' + encodeURIComponent(tempAccessoryName) +'&progId='+ this.progId +'&AccessState=download';
                iframe.style.display = "none";
                document.body.appendChild(iframe);
                this.dialogloading = false;
            })
    
        },
        DownLoadSelect() {
            let that = this;
            if (that.diaselect.length > 0) {
                debugger
                for (let i = 0; i < that.diaselect.length; i++) {
                    if(that.isBillAccess){
                        this.DownLoadBillAccess(that.diaselect[i]);
                    }
                    else{
                        downloadprint(that.diaselect[i].accessoryId, that.diaselect[i].accessoryName);
                        uploaddowninfo(that.BillNo, that.diaselect[i].accessoryId, that.diaselect[i].accessoryName, that);
                    }
                }
            } else 
            this.$store.commit("TIPPOP",{popType:'warning',popMessage: this.$t('comm.unselectwarning')});
            },

        handleSelectionChange(val) {
            this.diaselect = val;
        },
        DownLoadPrint(index, row) {
            //下载图纸
            let that = this;
            downloadprint(row.accessoryId, row.accessoryName);
            uploaddowninfo(this.BillNo, row.accessoryId, row.accessoryName, that);
        },
    }
}
</script>
<style>
</style>