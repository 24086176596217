<template>
    <Frame>
        <div class="deliverCar">
            <div class="loadingtext" v-if="loadingtext"><span>{{loadinginform}}</span></div>
            <PageHeader :title="$t('comm.delivercar')" :hasBack="false" />
            <el-table :data="tableData" ref="multipleTable" v-loading="tableLoading" :height="tableHeight" row-key="RowCode"
                @select="handleSelect" @select-all="handleSelect" v-loadmore="loadmore">
                <el-table-column fixed key="selection" type="selection" min-width="50"></el-table-column>
                <el-table-column fixed :width="WidthControl('FromBillNo',tableData,6)" key="FromBillNo" prop="FromBillNo" min-width="150" :label="$t('DeliverCar.OrderNo')"></el-table-column>
                <el-table-column fixed :width="WidthControl('MaterialId',tableData)"  key="MaterialId" prop="MaterialId" :label="$t('DeliverCar.ProdId')"></el-table-column>
                <el-table-column :fixed="bigscreen" :width="WidthControl('MaterialName',tableData)"  key="MaterialName" prop="MaterialName" :label="$t('DeliverCar.ProdName')"></el-table-column>
                <el-table-column :fixed="bigscreen" :width="WidthControl('MaterialSpec',tableData)"  key="MaterialSpec" prop="MaterialSpec" min-width="150" :label="$t('DeliverCar.ProdSpec')"></el-table-column>
                <el-table-column :fixed="bigscreen" key="UnTransSQty" prop="UnTransSQty" min-width="150" align="right"
                    :label="$t('DeliverCar.UnTransSQty')">
                    <template slot-scope="scope">
                        <span>{{ scope.row.UnTransSQty }}&nbsp{{ scope.row.SunitName }}</span>
                    </template>
                </el-table-column>
                <el-table-column :fixed="bigscreen" width="150" key="Quantity" prop="Quantity" :label="$t('Deliver.Quantity')">
                    <template slot-scope="scope">
                        <div style="display: flex;">
                            <el-input style="width: 70%;" v-model="scope.row.Quantity" class="qty_input" @change="quantitiyChange(scope.row,'ETOB')"
                                :placeholder="$t('DeliverCar.inputpls')"></el-input>
                            <span style="margin: auto 0;">{{ scope.row.SunitName }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column :fixed="bigscreen" width="150" key="BQuantity"  prop="BQuantity" :label="$t('Deliver.TotalQty')" v-if="ShowBasicUnit">
                    <template slot-scope="scope">
                        <div style="display: flex;">
                            <el-input style="width: 70%;" v-model="scope.row.BQuantity" class="qty_input" @change="quantitiyChange(scope.row,'BTOE')"
                            :placeholder="$t('Deliver.inputpls')"></el-input>
                            <span style="margin: auto 0;">{{ scope.row.UnitName }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column key="MTONo" prop="MTONo" width="150" :label="$t('DeliverCar.MTONo')" v-if="ShowMTO">
                </el-table-column>
                <el-table-column key="BatchNo" prop="BatchNo" width="150" :label="$t('DeliverCar.BatchNo')">
                </el-table-column>
                <el-table-column width="150" key="Remark" prop="Remark" :label="$t('DeliverCar.remark')">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.Remark" class="Remark" :placeholder="$t('DeliverCar.inputpls')"
                            @change="modifyRemark(scope.row)"></el-input>
                    </template>
                </el-table-column>
                <el-table-column :width="WidthControl('Address',tableData,5,100)" key="Address" prop="Address" :label="$t('DeliverCar.address')">
                    <template slot-scope="scope">
                        <div :class="$store.state.AllowEditAddress?'changeAddress':''" @click="ShowRowAddWindow(scope.row)">
                            <svg-icon v-if="$store.state.AllowEditAddress" class="changeIcon" icon-class="changeaddress-blue" style="width: 13px;height: 13px;" />
                            <span  style="margin:auto 0;width: 97%;">{{ scope.row.Address }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column key="operation" :label="$t('DeliverCar.operation')" width="70">
                    <template slot-scope="scope">
                        <span class="deleteBtnSpan" @click="handleDelete(scope.row, scope.$index)" type="text">{{$t('button.delete')}}</span>
                    </template>
                </el-table-column>
            </el-table>
            <div v-show="tableData.length" class="bottom">
                <div class="bottom__header">
                    <span class="allCheck">
                        <el-checkbox @change="toggleSelection" v-model="allCheck">{{ $t('DeliverCar.allCheck')}}</el-checkbox>
                    </span>
                    <span class="deleteSelect">
                        <el-button @click="handleDeleteSel" class="deleteBtn" type="text">
                            {{ $t('button.deletechoose') }}</el-button>
                    </span>
                    <span class="deleteSelect" style="padding-left:10px">
                        <el-button v-if="$store.state.AllowEditAddress" @click="ShowAddressWindow" class="deleteBtn" type="text">
                            <svg-icon icon-class="changeaddress-blue" style="width: 13px;height: 13px; color: aquamarine;" />
                            {{ $t('button.modifyaddress') }}
                        </el-button> 
                    </span>
                </div>
                <div style="width: 40%;"></div>
                <div class="checkInfo">
                    <div class="checkNum">
                        {{ $t('DeliverCar.checkNum') }}<span>{{ selectData.length }}</span>{{ $t('DeliverCar.number') }}
                    </div>
                    <el-button type="primary" icon="el-icon-truck" :loading="btnloading" :disabled="btndisabled" @click="toDeliver">
                        {{ $t('DeliverCar.deliver') }}</el-button>
                </div>
            </div>
        </div>
        <el-dialog :title="modifyAddress" :visible.sync="ChangeAdtag" width="40%" custom-class="modifyaddress">
            <el-select size="small"  v-model="AllUId" placeholder="" class="pop-address">
                <el-option v-if="item.OrgId == fillterOrgId"  v-for="item in addresses" :key="item.Uid" :label="item.Address" class="option" :value="item.Uid">
                </el-option>
            </el-select>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancel()">{{ $t('button.cancel') }}</el-button>
                <el-button type="primary" @click="batchChangeAddress()">{{ $t('button.determine') }}</el-button>
            </span>
        </el-dialog>
        <DeliverSuccess ref="dialog" :billnoArray="billArray" :successCount="successCount" :selectCount="selectCount"></DeliverSuccess>
    </Frame>
</template>
<script>
import { mapState, mapMutations } from "vuex"
import {getWebSupplierConfig} from "@/utils/utils"
import DeliverSuccess from '../components/DeliverSuccess.vue';
export default {
    name: "DeliverCar",
    components:{
        DeliverSuccess
    },
    directives:{
       loadmore: {
            bind(el, binding) {
                const selectWrap = el.querySelector(".el-table__body-wrapper");
                selectWrap.addEventListener("scroll", function () {
                    const scrollDistance =
                        this.scrollHeight - this.scrollTop - this.clientHeight;
                    if (scrollDistance <= 0.5) {
                      binding.value()//执行在使用时绑定的函数，在这里即loadMorePerson方法
                    }
                });
            }
        },
    },
    computed: {
    ...mapState(["ShowMTO", "ShowBasicUnit"])
    },
    data() {
        return {
            tableData: [],
            tableHeight: window.innerHeight - 201,
            tableLoading: false,
            loadingtext:false,
            selectData: [],
            allCheck: false,
            totalCount: 0,
            pagesize: 100,
            btnloading: false,
            currentPage: 1,
            pageindex: 0,
            billArray:[],
            successCount:0,
            selectCount:0,
            timer:null,
            addresses:[],
            ChangeAdtag:false,
            AllUId:'',
            selectloading:false,
            fillterOrgId:'',
            pageindex:0,
            shaker:true,
            loadinginform:'',
            btndisabled:false,
            modifyAddress:this.$t('button.modifyaddress'),
            bigscreen:true,
        }
    },
    created() {
       
        },
    mounted() {
        var fun = ()=>{
            this.$nextTick(()=>{
            console.log(this.$store.state);
            this.$refs.multipleTable.doLayout();this.getTable(0)})
        }
        this.tableLoading = true;
        getWebSupplierConfig(this,fun )
        let that = this
        this.bigscreen = document.body.clientWidth>1650?true:false;
        window.onresize = () => {
            if (that.resizeFlag) {
                clearTimeout(that.resizeFlag);
            }
            that.resizeFlag = setTimeout(() => {
                that.tableHeight = window.innerHeight - 201
                this.bigscreen = document.body.clientWidth>1650?true:false;
            }, 100);
        }
        this.getAddresses("",true);
    },
    methods: {
        ...mapMutations({
            addToCar: "ADDTOCAR",
            deleteCarRow: "DELETECARROW"
        }),
        getTable(pageindex) {
            this.shaker = false;
            this.tableLoading = true;
            this.btndisabled = true;
            this.currentPage = pageindex + 1;
            this.pageindex = pageindex;
            this.axiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx",
                {
                    action: "getdelivercar",
                    pagesize: this.pagesize,
                    pageindex
                },
                (res) => {
                    var data = eval(res.data);
                    if (data) {
                        this.totalCount = data[0].totalCount;
                        data.shift(0);    
                        console.log(data);          
                        if(pageindex == 0){
                            this.tableData = [];//若判断入口为0则为车首 先做一次清�?防止残余数据导致数据不正�?
                        }
                        //this.tableData = [...this.tableData,...data];
                        this.tableData = this.tableData.concat(data);
                        if(pageindex == 0){
                            this.$refs.multipleTable.toggleAllSelection();
                            this.allCheck = true;
                            this.selectData = [...this.tableData];//前几笔默认先全�?
                        }else{
                            if(this.selectData.length){
                                this.selectData = [...this.selectData,...data];
                                this.$nextTick(()=>{
                                    this.selectData.forEach((v)=>{
                                        this.$refs.multipleTable.toggleRowSelection(v,true);
                                    })
                                })
                                if(this.selectData.length == this.tableData.length){
                                    this.allCheck = true;
                                }else this.allCheck = false;
                            }
                        }
                        // this.selectData = data;
                    } else this.tableData = [];
                    this.tableLoading = false;
                    this.pageindex = Math.ceil(this.tableData.length/100)-1;
                    this.pagesize = 100;
                    this.btndisabled = false;
                    this.shaker = true;
                })

        },
        toggleSelection(val) {
            this.$refs.multipleTable.toggleAllSelection();
        },
        handleCurrentChange(val) {
            this.getTable(val - 1);
            this.pageindex = val - 1;
            this.allCheck = false;
            this.selectData = [];
        },
        handleSelect(val,row) {
            this.selectData = val;
            this.allCheck = val.length != this.tableData.length ? false : true;
        },
        getTableHeight() {
            this.tableHeight = window.innerHeight - 337
        },
        changeAddress(row){
            this.addresses.forEach((v)=>{
                if(v.Uid == row.Uid){
                    row.AddrId = v.AddrId;
                    row.Address=v.Address;
                    let temp = [
                        {RowCode:row.RowCode,FieldName:"AddrId",FieldValue:row.AddrId},
                        {RowCode:row.RowCode,FieldName:"Address",FieldValue:row.Address},
                    ]
                    this.UpdateAddress(temp);
                }
            })
        },
        handleDelete(row, index) {
            this.tableLoading =true;
            this.axiosInvoke("/SupplierComm/Handler/CartHandler.ashx",
                {
                    action: "deletecart",
                    RowCode: row.RowCode,
                    BizAttr: 20
                }, (res) => {
                    this.$store.dispatch("getDeiverCarCount", this);
                    this.selectData = [];
                    this.pagesize = Math.ceil(this.tableData.length/100) * 100;//由于删除后，滑动轴高度是不变的，设定取数页大小，回到删除的那个位�?
                    if(this.pagesize == 0)this.pagesize = 100;
                    this.pageindex = 0;
                    this.tableLoading =false;
                    this.$store.commit("TIPPOP",{popType:'success',popMessage: this.$t("DeliverCar.deleteMessage")});
                    this.getTable(0);
                })
        },
        //获取被选中的行项的rowcode
        checkRowCode() {
            let rowcodes = ""
            this.selectData.forEach((row) => {
                rowcodes += row.RowCode + ","
            })
            rowcodes = rowcodes.substring(0, rowcodes.lastIndexOf(","));
            return rowcodes;
        },
        UpdateAddress(list){
            this.loadinginform = this.$t('DeliverCar.changeaddress');
            this.loadingtext = true;
            this.tableLoading = true;
            this.axiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx",
                {
                    action:"updateaddress",
                    billArray:JSON.stringify(list)
                },(res)=>{
                    this.tableLoading = false;
                    this.loadingtext = false;
                });
        },
         //发货数量和发货基本数量单行联�?
         async unitExchange(Quantity,SUnitId,row,changekind){
            row.ProgId = "purPurchaseOrder";
            await this.asyncaxiosInvoke("/SupplierComm/Handler/FormatHandler.ashx", {
                    action: 'formatquantity',
                    FormatValue: Quantity,
                    FormatFieldValue: SUnitId,
                }, (res) => {
                    var data = eval(res.data);
                    console.log(data);
                    if(changekind == "ETOB"){
                        row.Quantity = data.result;
                    }else{
                        row.BQuantity = data.result;
                    }
                })
            await this.asyncaxiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx", {
                    action:'unitexchange',
                    changekind,
                    billArray:JSON.stringify([row])
                },(res,err)=>{
                    var data2 = eval(res.data);
                    if(changekind == "ETOB"){
                        row.BQuantity = data2[0].resultQty;
                    }else{
                        if(err){
                            this.$refs.multipleTable.toggleRowSelection(row,false);
                            if(this.selectData.indexOf(row)>-1){
                                this.selectData.splice(this.selectData.indexOf(row),1);
                            }
                            row.BQuantity = "";
                        }
                        row.Quantity = data2[0].resultQty;
                    }
                })
            
        },
        loadmore(){
            if(this.tableData.length<this.totalCount&&this.shaker){
                this.getTable(++this.pageindex);
            }
        },
        //当笔数改变时触发的更新车单据事件

        /**监听输入框改变时的方�?
         * @param {object} row 行项
         * @param {String} changekind “ETOB”是（Exchange）交易数�?>（basic）基本数量；“BTOE是基本数�?>交易数量�?
         */
        async quantitiyChange(row,changekind) {
            if ((isNaN(row.Quantity.replace(/,/g,""))&&changekind == "ETOB")||(isNaN(row.BQuantity.replace(/,/g,""))&&changekind == "BTOE")) {
                this.$store.commit("TIPPOP",{popType:'warning',popMessage: this.$t('Deliver.message')});
                row.Quantity = "";
                row.BQuantity = "";
                return;
            }else{
                if ((row.Quantity.replace(/,/g,"") * 1 >= 0 && row.Quantity.replace(/,/g,"") <= 1e10&&changekind == "ETOB")||(row.BQuantity.replace(/,/g,"") * 1 >= 0 && row.BQuantity.replace(/,/g,"") <= 1e10&&changekind == "BTOE")) {
                    console.log(row.Quantity);
                    let Quantity = row.Quantity.replace(/,/g,"");
                    let SunitId = row.SunitId;
                    if(changekind == "BTOE"){
                        Quantity = row.BQuantity.replace(/,/g,"");
                        SunitId = row.UnitId;
                    }
                    clearTimeout(this.timer);
                    this.btnloading = true
                    this.timer = setTimeout(async()=>{
                        await this.unitExchange(Quantity,SunitId,row,changekind);
                        this.btnloading = false;
                        this.axiosInvoke("/SupplierComm/Handler/CartHandler.ashx", {
                            action: 'updatecart',
                            BizAttr: 20,
                            RowCode: row.RowCode,
                            FieldName: 'Quantity',
                            FieldValue: row.Quantity
                        }, (res) => {
                            if(row.BQuantity!=""){
                                this.axiosInvoke("/SupplierComm/Handler/CartHandler.ashx", {
                                    action: 'updatecart',
                                    BizAttr: 20,
                                    RowCode: row.RowCode,
                                    FieldName: 'BQuantity',
                                    FieldValue: row.BQuantity
                                }, (res) => {
                                    console.log(res.data);
                                })
                            }
                        })
                    },150);
                      
                } else {
                    this.$store.commit("TIPPOP",{popType:'warning',popMessage: this.$t('Deliver.message')});
                    row.Quantity = "";
                    row.BQuantity = "";
                }
            }
        },
        //修改发货车备注信�?
        modifyRemark(row) {
            //限制长度
            this.axiosInvoke("/SupplierComm/Handler/CartHandler.ashx", {
                action: 'updatecart',
                BizAttr: 20,
                RowCode: row.RowCode,
                FieldName: 'remark',
                FieldValue: row.Remark
            }, (res) => {
                console.log(res.data);
            })
        },
        //删除被选中的行�?
        handleDeleteSel() {
            this.tableLoading = true;
            if (this.selectData.length) {
                var rowcodes = this.checkRowCode();
                this.axiosInvoke("/SupplierComm/Handler/CartHandler.ashx",
                    {
                        action: "deletecartlist",
                        RowCodes: rowcodes,
                        BizAttr: 20
                    }, (res) => {
                        var that = this;
                        this.selectData.forEach((t)=>{
                            for(let i=0;i<that.tableData.length;i++){
                                if(that.tableData[i].RowCode == t.RowCode){
                                    that.tableData.splice(i,1);
                                    break;
                                }
                            }
                        })
                        this.pagesize = Math.ceil(this.tableData.length/100) * 100;//由于删除后，滑动轴高度是不变的，设定取数页大小，回到删除的那个位�?
                        if(this.pagesize == 0)this.pagesize = 100;
                        this.pageindex = 0;
                        this.selectData = [];
                        this.$store.dispatch("getDeiverCarCount", this);
                        this.tableLoading = false;
                        this.allCheck = false;
                        this.$store.commit("TIPPOP",{popType:'success',popMessage:  this.$t("DeliverCar.deleteMessage")});
                        this.getTable(0);
                    })
            } else {
                this.$store.commit("TIPPOP",{popType:'warning',popMessage: this.$t("DeliverCar.message")});
                this.tableLoading = false;
            }

        },
        toDeliver() {
            this.tableLoading = true;
            this.loadinginform = this.$t('DeliverCar.deliverloading');
            this.loadingtext = true;
            this.btnloading = true;
            if (this.selectData.length) {
                this.selectCount = this.selectData.length;
                let rowcodes = this.checkRowCode();
                let billno = "";
                let emptybillno = "";
                this.selectData.forEach((v) => {
                    if (v.UnTransSQty == 0) {
                        billno += v.FromBillNo + ",";
                    }
                    if(v.Quantity == "" || v.Quantity == 0){
                        emptybillno += v.FromBillNo + ",";
                    }
                })
                if (billno != "") {
                    this.$alert(this.$t('DeliverCar.zerotip').replace('{0}',billno), this.$t('comm.tip'), { confirmButtonText: this.$t('comm.confirm') });
                    this.btnloading = false;
                    this.tableLoading = false;
                    this.loadingtext = false;
                    return;
                }
                if (emptybillno != "") {
                    this.$alert(this.$t('DeliverCar.qzerotip').replace('{0}',emptybillno), this.$t('comm.tip'), { confirmButtonText: this.$t('comm.confirm') });
                    this.btnloading = false;
                    this.tableLoading = false;
                    this.loadingtext = false;
                    return;
                }

                var temp = [];
                for (let i = 0; i < this.selectData.length; i++) {
                    let obj = {};
                    obj.FromSourceTag = "2601";
                    obj.FromBillNo = this.selectData[i].FromBillNo;
                    obj.FromRowCode = this.selectData[i].FromRowCode;
                    obj.RowCode = this.selectData[i].RowCode;
                    obj.OrgId = this.selectData[i].OrgId;
                    obj.MaterialId = this.selectData[i].MaterialId;
                    obj.Quantity = this.selectData[i].Quantity;
                    obj.BQuantity = this.selectData[i].BQuantity;
                    obj.SUnitId = this.selectData[i].SUnitId;
                    // obj.MtoNo = this.selectData[i].MtoNo;
                    obj.BatchNo = this.selectData[i].BatchNo;
                    obj.Remark = this.selectData[i].Remark;
                    obj.AddrId = this.selectData[i].AddrId;
                    obj.Address = this.selectData[i].Address;
                    obj.ReceiveOrgId = this.selectData[i].ReceiveOrgId;
                    obj.ItemType = this.selectData[i].ItemType;
                    obj.FromTypeId = this.selectData[i].FromTypeId;
                    obj.IsPriceWithTax = this.selectData[i].IsPriceWithTax;
                    obj.DeliverDate = this.selectData[i].DeliverDate;
                    obj.IsTaxByOrder = this.selectData[i].IsTaxByOrder;
                    obj.TaxId = this.selectData[i].TaxId;
                    obj.PersonId = this.selectData[i].PersonId;
                    obj.VaryState = this.selectData[i].VaryState;
                    obj.DemandOrgId = this.selectData[i].DemandOrgId;
                    obj.DebtorOrgId = this.selectData[i].DebtorOrgId;
                    obj.BodyTaxId = this.selectData[i].BodyTaxId;
                    temp.push(obj);
                }

                this.axiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx",
                    {
                        action: "directdeliver",
                        chk: rowcodes,
                        billArray: JSON.stringify(temp),
                        BizAttr: 20,
                        iscar:true
                    }, (res,errflag) => {
                        console.log(res.data);
                        debugger
                        let data = res.data;
                        if (res.datalist) {
                            data = res.datalist[0];
                        }
                        this.tableLoading = false;
                        this.loadingtext = false;
                        this.btnloading = false;
                        debugger
                        if(!data)return;
                        this.$store.dispatch("getDeiverCarCount", this);
                        this.allCheck = false;
                        this.getTable(0);
                        this.selectData = [];
                        this.$nextTick(()=>{
                          this.successCount = data.pop().successCount;
                          this.$refs.dialog.visible = true;
                        })
                        this.billArray = data;
                    });
            } else {
                this.$store.commit("TIPPOP",{popType:'warning',popMessage: this.$t("DeliverCar.message")});
                this.tableLoading = false;
                this.loadingtext = false;
                this.btnloading = false;
            }

        },
         //批量修改地址
         batchChangeAddress(){
            var Address = "";
            var AddrId = "";
            this.addresses.forEach((v)=>{
                if(v.Uid == this.AllUId){
                    AddrId = v.AddrId;
                    Address = v.Address;
                }
            })
            let list = [];
            if(this.addIsRow){
                this.CurRowTemp.Uid = this.AllUId;
                this.CurRowTemp.AddrId = AddrId;
                this.CurRowTemp.Address = Address;
                let tempAddrId = {RowCode:this.CurRowTemp.RowCode,FieldName:"AddrId",FieldValue:AddrId};
                    list.push(tempAddrId);
                    let tempAddress = {RowCode:this.CurRowTemp.RowCode,FieldName:"Address",FieldValue:Address};
                    list.push(tempAddress);
            }else{
                this.selectData.forEach((v)=>{
                    v.Uid = this.AllUId;
                    v.AddrId = AddrId;
                    v.Address = Address;
                    let tempAddrId = {RowCode:v.RowCode,FieldName:"AddrId",FieldValue:v.AddrId};
                    list.push(tempAddrId);
                    let tempAddress = {RowCode:v.RowCode,FieldName:"Address",FieldValue:v.Address};
                    list.push(tempAddress);
                })
            }
            this.UpdateAddress(list);
            this.ChangeAdtag = false;
        },
        cancel() {//取消
        this.ChangeAdtag = false;
        },
        ShowRowAddWindow(row){
            if(!this.$store.state.AllowEditAddress)return;
            this.AllUId = row.Uid;
            this.CurRowTemp = row;
            this.addIsRow = true;
            this.modifyAddress = this.$t('button.modifyRowAddress');
            let ReceiveOrgId = row.ReceiveOrgId;
            this.ChangeAdtag = true;
            this.fillterOrgId = ReceiveOrgId;
            this.getAddresses(ReceiveOrgId);
        },
         //批量修改地址弹窗
         ShowAddressWindow(){
            this.AllUId = "";
            this.addIsRow = false;
            this.modifyAddress = this.$t('button.modifyaddress');
            if(this.selectData.length){
                let ReceiveOrgId = this.selectData[0].ReceiveOrgId;
                let difforg = false;
                this.selectData.forEach((v)=>{
                    if(v.ReceiveOrgId!=ReceiveOrgId)difforg = true;
                })
                if(!difforg){
                    this.ChangeAdtag = true;
                    this.fillterOrgId = ReceiveOrgId;
                    this.getAddresses(ReceiveOrgId);
                }else{
                    this.$store.commit("TIPPOP",{popType:'warning',popMessage: this.$t("Deliver.difforgaddress")});
                }
            }else{
                this.$store.commit("TIPPOP",{popType:'warning',popMessage: this.$t("Deliver.warning")});
            }
        },
         //获取所有可选发货地址
         getAddresses(OrgId,firstloading){
            this.selectloading = true;
            this.addresses = [];
            this.axiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx", 
            {
                action:'getaddresses',
                OrgId:OrgId,
                first:firstloading
            },
            (res)=>{
                var data = eval(res.data);
                this.addresses = data;
                this.selectloading = false;
            });
        },
        EditRow(row,scope){
            if(row.Edit)return;
            this.tableData.forEach(v=>{
                if(v.Edit){
                    v.Edit = false;
                    return;
                }
            })
            row.Edit = true;
        },
        RowCode(row){
            return row.RowCode;
        }
    }
}
</script>
<style scoped>
.loadingtext{
    position: absolute;
    z-index: 99999;
    top: 50vh;
    width: 100%;
    color:#409EFF;
    display: flex;
    justify-content: center;
}
.deliverCar {
    position: relative;
    height: 100%;
}

.deliverCar /deep/ .el-table_fixed{
    height: auto !important;
}
.deliverCar /deep/ .el-table__body-wrapper{
    z-index: 3;
}

.deliverCar /deep/ .el-button.deleteBtn {
    padding: 0
}
.deleteBtnSpan{
    color:#409EFF;
}
.deleteBtnSpan:hover{
    cursor:pointer;
    color:#409EFF;
}
.deliverCar /deep/ .el-button {
    padding: 8px 15px;
}

.deliverCar /deep/ .el-table .el-input {
    width: 80px;
    height: 35px;
    border-radius: 2px;
}
.deliver_contain .el-table /deep/ .cell{
    font-size: 13px;
}
.deliverCar /deep/ .el-table .el-select {
    width: 80%;
}
.deliverCar /deep/ .el-table .el-select .el-input {
    width: 100%;
    min-width: 100px;
}

.deliverCar .el-table /deep/ .el-table__fixed::before{
    background-color: rgba(0, 0, 0, 0);
}
.Remark {
    width: 100% !important;
}

.Remark /deep/ .el-input__inner {
    width: 100%;
    height: 35px;
    border-radius: 2px;
}

.qty_input /deep/ .el-input__inner {
    width: 80px;
    height: 35px;
    border-radius: 2px;
}

.deliverCar .bottom {
    font-size: 16px;
    width: calc(100% - 20px);
    bottom: 0;
    left: 0;
    padding: 10px;
    z-index: 999;
    background-color: white;
    display: flex;
    justify-content: space-between;
    line-height: 32px;
    box-shadow: 0 2px 4px rgb(0 0 0), 0 0 6px rgb(0 0 0 / 4%);
}
.el-select-dropdown__item{
    height: auto;
}
.option /deep/ span{
    min-width: 150px;
    max-width: 33vw;
    display: flex;
    word-break: break-all;
    height: auto;
    white-space: pre-wrap;
}
.deliverCar .bottom .allCheck {
    margin-right: 20px;
    padding-left: 3px;
}

.checkInfo {
    width: 250px;
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
}
.EditBox{
    width: 100%;
}
.deliverCar /deep/ .el-checkbox__label {
    font-size: 16px;
}
.deliverCar .changeAddress{
    display: flex;
    min-height: 30px;
    margin: auto 0;
    flex-wrap: nowrap;
}
.deliverCar .changeAddress:hover{
    color: #409EFF;
    cursor: pointer;
}
.deliverCar .changeIcon{
    filter: grayscale(1);
    margin: auto 2px;
    width: 3%;
}
.deliverCar .changeAddress:hover .changeIcon{
    filter: grayscale(0);
}
.el-table /deep/ .el-table__header .cell .el-checkbox:first-child {
    padding-left: 5px;
}
.pop-address{
    width: 90%;
    margin: 0 5%;
}
.checkNum span {
    margin: 0 5px;
    font-size: 18px;
}
</style>
