<template>
    <el-main class="frame">
        <slot></slot>
    </el-main>
</template>

<script>
export default {
    name:"Frame",
    data(){
        return{}
    },
}
</script>

<style scoped>
.frame{
    margin:25px;
    background: white;
}
</style>

