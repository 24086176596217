<template>
  <Frame>
    <PageHeader :title="$t('comm.orderexecution')" :hasBack="false" />
    <div class="orderexecution" style="padding:15px 25px 0 25px;">
      <div class="detialmessage filter_row row_width">
          <div class="filter_col filter_flex">
              <span>{{ $t('OrderExecution.messagedate') }}:&nbsp;&nbsp;</span>
              <el-radio-group v-model = "daterange" size="small" @change="radioGetTime()" >
                <el-radio-button label="thismonth">{{$t('OrderExecution.thismonth')}}</el-radio-button>
                <el-radio-button label="thisseason">{{ $t('OrderExecution.thisseason') }}</el-radio-button>
                <el-radio-button label="thisyear">{{ $t('OrderExecution.thisyear') }}</el-radio-button>
              </el-radio-group>
          </div>
          <div class="filter_col filter_flex">
              <span>{{ $t('OrderExecution.custom') }}:&nbsp;&nbsp;</span>
              <el-date-picker v-model="myDate" type="daterange" size="small" range-separator="~"
                :start-placeholder="$t('comm.startdate')" :end-placeholder="$t('comm.enddate')" @change="gettime()"
                :unlink-panels="true" :default-value="timeDefault" >
              </el-date-picker>
          </div>
          <div class="filter_col filter_flex">
              <span>{{ $t('OrderExecution.order') }}:&nbsp;&nbsp;</span>
              <el-input
                :placeholder="$t('OrderExecution.inputpls')" v-model="billno" clearable
                style="color:#bbbbbb;" @change="search(0)" size="small" ></el-input>
          </div>
        
      </div>
      <div class="detialmessage filter_row row_width">
          <div class="filter_col filter_flex">
              <span>{{ $t('OrderExecution.product') }}:&nbsp;&nbsp;</span>
              <el-input
                :placeholder="$t('OrderExecution.inputPls')" v-model="material" clearable
                style="color:#bbbbbb;" @change="search(0)" size="small"></el-input>
          </div>
          <div class="filter_col filter_flex" v-if="$store.state.ShowMTO">
              <span>{{ $t('Deliver.mtoNo') }}:&nbsp;&nbsp;</span>
              <el-input v-model="mtoNo" :placeholder="$t('Deliver.inputpls')"
                  @change="search(0)" size="small" clearable></el-input>
          </div>
          <div class="filter_col filter_flex">
              <span>{{ $t('Deliver.batchNo') }}:&nbsp;&nbsp;</span>
              <el-input v-model="batchNo" :placeholder="$t('Deliver.inputpls')"
                  @change="search(0)" size="small" clearable></el-input>
          </div>
          <div class="filter_col filter_flex" v-if="!$store.state.ShowMTO">
              <span>{{ $t('OrderExecution.specifications') }}:&nbsp;&nbsp;</span>
              <el-input v-model="materialSpec" :placeholder="$t('Deliver.inputpls')"
                  @change="search(0)" size="small" clearable></el-input>
          </div>
      </div>
      <div class="detialmessage filter_row row_width" v-if="$store.state.ShowMTO">
        <div class="filter_col filter_flex" >
              <span>{{ $t('OrderExecution.specifications') }}:&nbsp;&nbsp;</span>
              <el-input v-model="materialSpec" :placeholder="$t('Deliver.inputpls')"
                  @change="search(0)" size="small" clearable></el-input>
          </div>
      </div>
      <div class="executionmessage">
        <span style="float:right;padding-right:20px;padding-bottom:20px;">
          <el-button type="reset" @click="reset()" style="padding: 8px 20px;">{{ $t('button.reset') }}</el-button>
          <el-button type="primary" @click="search(0);" style="padding: 8px 20px;">{{ $t('button.query') }}</el-button>
        </span>
      </div>
      <el-table ref="multipleTable" :data="tableData" :height="tableHeight" style="margin:0;width:100%"
        tooltip-effect="dark" v-loading="tableLoading" @selection-change="handleSelectionChange">
        <el-table-column prop="BillDate" :label="$t('OrderExecution.messagedate')" fixed :width="130"
          ></el-table-column>
        <el-table-column prop="BillNo" :label="$t('OrderExecution.order')" fixed :width="120">
          <template scope="scope">
            <el-link :underline="false" @click=" ToOrderDetail(scope.row.BillNo)">
            {{ scope.row.BillNo }}</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="MaterialId" :label="$t('OrderExecution.codeid')" fixed :width="120"></el-table-column>
        <el-table-column prop="MaterialName" :label="$t('OrderExecution.name')" fixed :width="120"></el-table-column>
        <el-table-column prop="MaterialSpec" :label="$t('OrderExecution.specifications')" fixed :width="120"></el-table-column>
        <el-table-column v-if="$store.state.ShowMTO" prop="MTONo" :label="$t('OrderExecution.MTONo')" :width="120"></el-table-column>
        <el-table-column prop="BatchNo" :label="$t('OrderExecution.BatchNo')" :width="120"></el-table-column>
        <el-table-column prop="SQuantity" :label="$t('OrderExecution.changenumber')" align="right" :width="120">
        <template slot-scope="scope">
          <span>{{ scope.row.SQuantity }}&nbsp{{ scope.row.SUnitName }}</span>
        </template>
        </el-table-column>
        <el-table-column prop="PresentSQty" :label="$t('OrderExecution.freenumber')" align="right" :width="120">
          <template slot-scope="scope">
          <span>{{ scope.row.PresentSQty }}&nbsp{{ scope.row.SUnitName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="TotalSQty" :label="$t('OrderExecution.allnumber')" align="right" :width="120">
          <template slot-scope="scope">
          <span>{{ scope.row.TotalSQty }}&nbsp{{ scope.row.SUnitName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="SPrice" :label="$t('OrderExecution.price')" align="right" :width="120"
          ></el-table-column>
        <el-table-column prop="OAmount" :label="$t('OrderExecution.amount')" align="right" :width="120"
          ></el-table-column>
        <el-table-column prop="OAmountWithTax" :label="$t('OrderExecution.taxamount')" align="right" :width="120"
          ></el-table-column>
        <el-table-column prop="DeliveryDate" :label="$t('OrderExecution.deliverydate')" :width="120"></el-table-column>
        <el-table-column prop="ReceivingSQty" :label="$t('OrderExecution.receivingSQty')" align="right" :width="120">
          <template slot-scope="scope">
          <span>{{ scope.row.ReceivingSQty }}&nbsp{{ scope.row.SUnitName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="StoreConfirmSQty" :label="$t('OrderExecution.stockquantity')" align="right" :width="120">
          <template slot-scope="scope">
          <span>{{ scope.row.StoreConfirmSQty }}&nbsp{{ scope.row.SUnitName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="StoreConfirmBackSQty" :label="$t('OrderExecution.cancelnumber')" align="right">
          <template slot-scope="scope">
          <span>{{ scope.row.StoreConfirmBackSQty }}&nbsp{{ scope.row.SUnitName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="UnChkAmt" :label="$t('OrderExecution.shippedamount')" align="right" :width="140"
          ></el-table-column>
        <el-table-column prop="HadChkAmtWT" :label="$t('OrderExecution.reconciliationamount')" align="right"
          :width="120" ></el-table-column>
        <el-table-column prop="DetainOAmountWithTax" :label="$t('OrderExecution.discountamount')" align="right"
          :width="120" ></el-table-column>
        <el-table-column prop="APOAmount" :label="$t('OrderExecution.amountreceivable')" align="right" :width="120"></el-table-column>
      </el-table>
      <div>
        <el-pagination style="padding-left:50%;width:50%;" @current-change="handleCurrentChange"
                    layout="total,prev,pager,next,jumper" :pager-count="5" :current-page="currentPage"
                    :total="totalCount">
                </el-pagination>
      </div>
    </div>
  </Frame>
</template>

<script>
import SvgIcon from '../components/SvgIcon'
import { handleShortDate } from '@/utils/handleDate';
import { getThisMonth,getThisSeason,getWebSupplierConfig } from '@/utils/utils';
export default {
  name: 'OrderExecution',
  components: { SvgIcon },
  data() {
    return {
      batchexport: false,
      radio: 'exportTextA',
      tableHeight: window.innerHeight - 362,
      startdate:'0',
      enddate:'0',
      billno: '',
      mtoNo:'',
      batchNo:'',
      myDate:'',
      material: '',
      materialSpec: '',
      pageindex: 0,
      pagesize: 10,
      currentPage: 1,
      totalCount: 0,
      tableLoading: false,
      tableData: [],
      selectdata:[],
      timeDefault:'',
      checked:false,
      daterange:'thismonth',
      heightchanged:false
    }
  },
  created(){
    this.getSessionInform("OrderExecution",(v)=>{
                this.pageindex=v.pageindex;
                this.currentPage=v.currentPage;
                this.pagesize=v.pagesize;
                this.startdate=v.startdate;
                this.enddate=v.enddate;
                this.material=v.material;
                this.materialSpec = v.materialSpec;
                this.billno = v.billno;
                this.batchNo = v.batchNo;
                this.mtoNo = v.mtoNo;
                this.myDate=v.myDate;
        })
  },
  async mounted() {
    let that = this;
    window.onresize = () => {
      if (that.resizeFlag) {
        clearTimeout(that.resizeFlag);
      }
      that.resizeFlag = setTimeout(() => {
        that.tableHeight = window.innerHeight - 362;
        if(this.heightchanged)that.tableHeight = window.innerHeight - 422;
        that.resizeFlag = null;
      }, 100);
    }
    this.timeDefault =new Date();
    this.timeDefault.setMonth(new Date().getMonth()-1);
    var monthlist = getThisMonth(false);
    this.startdate = monthlist[0];
    this.enddate = monthlist[1];
    this.tableLoading = true;
    await getWebSupplierConfig(this);
    this.search(0);
   
  },
  methods: {
    reset() {//重置
      this.material = "";
      this.materialSpec = "";
      this.myDate = "";
      var monthlist = getThisMonth(false);
      this.daterange = "thismonth";
      this.startdate = monthlist[0];
      this.enddate = monthlist[1];
      this.billno = "";
      this.mtoNo = "";
      this.batchNo = "";
      this.search(0);
    },
    gettime() {
      if(this.myDate){
          this.startdate = handleShortDate(this.myDate[0], "From");
          this.enddate = handleShortDate(this.myDate[1], "To");
      }
      else{
          this.startdate = "";
          this.enddate = "";
      }
      this.daterange = "";
      this.search(0);
    },
    radioGetTime(){
      switch(this.daterange){
        case "thismonth":
          var monthlist = getThisMonth(false);
          this.startdate = monthlist[0];
          this.enddate = monthlist[1];
          break;
        case "thisseason":
          var monthlist = getThisSeason(false);
          this.startdate = monthlist[0];
          this.enddate = monthlist[1];
          break;
        case "thisyear":
          var date = new Date();
          this.startdate = date.getFullYear() + "0101";
          this.enddate = date.getFullYear() + "1231";
          break;
      }
      this.search(0);
    },
    handleCurrentChange(val){
      this.search(val-1);
    },
    search(pageindex) {
      this.pageindex = pageindex;
      this.tableLoading = true;
      this.axiosInvoke("/SupplierComm/Handler/VueReportHandler.ashx", {
        action: 'purorderanalysisrpt',
        startdate: this.startdate,
        enddate: this.enddate,
        material: this.material,
        //ADV202401310011 供应商平�?报表增加产品规格栏位供搜�?
        materialSpec:this.materialSpec,
        billno: this.billno,
        mtoNo:this.mtoNo,
        batchNo:this.batchNo,
        pagesize: this.pagesize,
        pageindex,
        excel:""
      }, (res,errflag) => {
        if(errflag) {this.tableLoading = false; this.tableData= null; return;}
        var data = eval(res.data);
        this.totalCount = data[0].totalCount;
        this.currentPage = pageindex + 1;
        data.shift();
        this.tableData = data;
        this.tableLoading = false;
        if(this.$store.state.ShowMTO && !this.heightchanged){
          this.tableHeight -= 60;
          this.heightchanged = true;
        }
      })
    },
    query() {//查询
      this.tableData;
      for (var i = 0; i < this.tableData.length; i++) {
      }
    },
    cancel() {//取消
      this.batchexport = false;
    },
    handleSelectionChange(val) {
      this.selectdata = val;
      console.log(this.selectdata);
      if (val.length == this.tableData.length) {
        this.checked = true;
      } else {
        this.checked = false;
      }
    },
      //跳转到orderDetail
      ToOrderDetail(billno){
            this.recoredPageToJump({
                pageindex:this.pageindex,
                currentPage: this.currentPage,
                pagesize: this.pagesize,
                startdate: this.startdate,
                enddate: this.enddate,
                material: this.material,
                //ADV202401310011 供应商平�?报表增加产品规格栏位供搜�?
                materialSpec: this.materialSpec,
                mtoNo:this.mtoNo, // 跳转单据详情时需要加mtoNo、batchNo传值，否则返回时会导致�?个取值undefined异常
                batchNo:this.batchNo,
                billno: this.billno,
                myDate:this.myDate,
            },"OrderExecution",billno,"OrderDetail");
        },
    //加载el-tooltip信息，鼠标聚焦时显示完整信息
    renderTitle(h, { column }) {
      let titleValue = '';
      let columnRealWidth = column.realWidth;
      switch (column.property) {
        case 'BillDate':
          titleValue = this.$t("OrderExecution.messagedate");
          break;
        case 'BillNo':
          titleValue = this.$t("OrderExecution.order");
          break;
        case 'MaterialId':
          titleValue = this.$t("OrderExecution.codeid");
          break;
        case 'MaterialName':
          titleValue = this.$t("OrderExecution.name");
          break;
        case 'MaterialSpec':
          titleValue = this.$t("OrderExecution.specifications");
          break;
        case 'MTONo':
          titleValue = this.$t("OrderExecution.MTONo");
          break;
        case 'BatchNo':
          titleValue = this.$t("OrderExecution.BatchNo");
          break;
        case 'SQuantity':
          titleValue = this.$t("OrderExecution.changenumber");
          break;
        case 'PresentSQty':
          titleValue = this.$t("OrderExecution.freenumber");
          break;
        case 'TotalSQty':
          titleValue = this.$t("OrderExecution.allnumber");
          break;
        case 'SPrice':
          titleValue = this.$t("OrderExecution.price");
          break;
        case 'OAmount':
          titleValue = this.$t("OrderExecution.amount");
          break;
        case 'OAmountWithTax':
          titleValue = this.$t("OrderExecution.taxamount");
          break;
        case 'DeliveryDate':
          titleValue = this.$t("OrderExecution.deliverydate");
          break;
        case 'StoreConfirmSQty':
          titleValue = this.$t("OrderExecution.stockquantity");
          break;
        case 'StoreConfirmBackSQty':
          titleValue = this.$t("OrderExecution.cancelnumber");
          break;
        case 'UnChkAmt':
          titleValue = this.$t("OrderExecution.shippedamount");
          break;
        case 'HadChkAmtWT':
          titleValue = this.$t("OrderExecution.reconciliationamount");
          break;
        case 'DetainOAmountWithTax':
          titleValue = this.$t("OrderExecution.discountamount");
          break;
        case 'APOAmount':
          titleValue = this.$t("OrderExecution.amountreceivable");
          break;
        default:
          titleValue = 'ARSearch默认栏位信息（测试）'
          break;
      }
      return this.renderTooltip(h, titleValue, columnRealWidth)
    }
  }
}
</script>

<style scoped>
.executionmessage {
  padding:10px;
  white-space: nowrap;
}
.detialmessage {
  color: #5d5d5d;
  font-size: 14px;
}
.filter_row {
    display: flex;
    justify-content: left;
    flex-wrap: nowrap;
    margin-bottom: 10px;
}
.row_width{
    justify-content: left;
}

.filter_col {
    display: flex;
    /* width: 20%; */
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
}
.filter_col span {
    margin: auto 0;
    white-space: nowrap;
}
.filter_flex{
    width: 28%;
    min-width: 285px;
    padding-left: 10px;
    padding-right: 10px;
}

.el-date-editor.el-input, .el-date-editor.el-input__inner{
    width: 100%;
}
.el-input /deep/ .el-input__inner{
    width: 100%;
}

.el-table /deep/ .el-table__body-wrapper {
  z-index: 2;
}

.el-checkbox /deep/ .el-checkbox__label {
  color: #5d5d5d;
  font-size: 14px;
}

.el-table /deep/ .el-table__header .cell {
  overflow: hidden;
  white-space: nowrap;
}

.el-table /deep/ th.el-table__cell {
  background-color: #f9f9f9;
}
.el-table /deep/ .cell{
    font-size: 13px;
}
.el-table /deep/ .el-table__body-wrapper {
  font-size: 14px;
}

.el-table /deep/ thead {
  font-size: 14px;
  color: #5d5d5d;
}

.el-table /deep/ .el-table__header .cell .el-checkbox:first-child {
  padding-left: 5px;
}

.topbutton {
  color: #777777;
  font-size: 14px;
}

.img {
  padding-right: 8px;
}

.el-dialog__wrapper /deep/ .el-dialog .el-dialog__header {
  border-bottom: 1px solid rgb(235, 233, 233);
}

.el-dialog__wrapper /deep/ .el-dialog .el-dialog__body {
  border-bottom: 1px solid rgb(235, 233, 233);
}
.el-radio-button /deep/ .el-radio-button__inner{
  padding: 9px 20px;
}
</style>
