/**
 * 处理时间格式（年月日时分秒） 将Date类的对象转为字符串 例如2023年03月24日---20230324000000；
 * @param {date} date 
 * @param {string} type “From”则为今日开始的时间 “To”则为今日结束的时间
 * @returns 时间字符串
 */
function handleDate(date, type) {
    if (date == "" || date == null) {
        return "";
    }
    var datestr = "";
    if (type == "From") {
        datestr = date.getFullYear() * 10000000000 + (date.getMonth() + 1) * 100000000 + date.getDate() * 1000000;
    } else if (type == "To") {
        datestr = date.getFullYear() * 10000000000 + (date.getMonth() + 1) * 100000000 + date.getDate() * 1000000 + 999999;
    }
    return datestr;
}

/**
 * 处理时间格式（年月日）将Date类的对象转为字符串 例如2023年03月24日---20230324；
 * @param {date} date 
 * @returns 时间字符串
 */
function handleShortDate(date){
    if (date == "" || date == null) {
        return "";
    }
    var datestr = "";
    datestr = date.getFullYear() * 10000 + (date.getMonth() + 1) * 100 + date.getDate();
    return datestr;
}
export{ handleDate, handleShortDate }
