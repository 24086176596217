<template>
  <Frame>
    <PageHeader :title="$t('comm.orderdetail')" :hasBack="true" detailType="OrderDetail" />
    <div class="orderdetail" style="padding:10px;">
      <div class="contain" ref="headcontain">
        <div class="message" style="width:100%;">
          <ul style="display:flex">
            <li class="messagetitle">{{ $t('comm.messagetitle') }}</li>
            <div class="buttonArray">
              <li v-if="operable"  class="button-confirm" id="confirm" @click="confirm()">
              <svg-icon icon-class="confirm" />{{$t('button.confirm')}}</li>
              <li v-if="operable"  class="button option-icon" id="veto" @click="veto()">
              <svg-icon  icon-class="deny" />{{$t('button.veto')}}</li>

              <li class="button option-icon" id="" @click="getVaryData()">  
              <svg-icon  icon-class="QueryDeliveryTime" />{{$t('OrderVary.searchVaryData')}}</li>
              <li  v-show="SCM78" class="button option-icon" id="download" @click="download('viewall',false)">
              <svg-icon  icon-class="downloaddraw" />{{ $t('button.download') }}</li>
              <li  v-show="SCM78" class="button option-icon" id="downloadchange" @click="download('viewchange',false)">
              <svg-icon  icon-class="downloadchange" />{{ $t('button.downloadchange') }}</li>
              <li  class="button option-icon" id="downloadBillAccess" @click="download('All',true)">
                <svg-icon  icon-class="downloadBillAccess" />{{ $t('button.downloadBillAccess') }}</li>
              <li  class="button option-icon" id="Export" @click="Export()" v-if="$store.state.exportExcelDetailState">
              <svg-icon  icon-class="export" />{{$t('button.export')}}</li>
              <li  class="button option-icon" id="print" @click="printdialog()">
              <svg-icon  icon-class="print" />{{$t('button.print')}}</li>
            </div>
          </ul>
          <ul style="padding:0 20px;display: flex;">
            <li class="message">{{ $t('OrderDetail.messagenumber') }}:{{ billno }}</li>
            <li class="message">{{ $t('OrderDetail.messagedate') }}：{{ billdate }}</li>
            <li class="message">{{ $t('OrderDetail.compName') }}：{{ compName }}</li>
            <li class="message">{{ $t('OrderDetail.amount') }}：{{ amount }}</li>
          </ul>
          <ul style="padding:0 20px;display: flex;">
            <li class="message">{{ $t('OrderDetail.checkstate') }}：
            <span id="checkstate" v-if="SignSt=='0'" style="color:#f9463f">{{$t('Order.unsignedconfirmation')}}</span>
            <span id="checkstate" v-if="SignSt=='1'" style="color:#777777">{{$t('Order.confirm')}}</span>
            <span id="checkstate" v-if="SignSt=='2'" style="color:#f9463f">{{$t('Order.unconfirmedchanges')}}</span>
            <span id="checkstate" v-if="SignSt=='3'" style="color:#f9463f">{{$t('button.veto')}}</span>
            </li>
            <li class="message">{{ $t('OrderDetail.goodsstate') }}：<span id="goodsstate" style="color:#f9463f">{{goodsstate}}</span></li>
            <li class="message">{{ $t('OrderDetail.purchaser') }}：{{ purchaser }}</li>
            <li class="message">{{ $t('OrderDetail.OAmountWithTax') }}：{{ oAmountWithTax }}</li>
          </ul>
          <ul style="padding:0 20px;display: flex;">
            <li class="message">{{ $t('OrderDetail.remark') }}：{{ remark }}</li>
          </ul>
        </div>
      </div>
      <ul>
        <li class="messagetitle">{{ $t('comm.detailtitle') }}</li>
      </ul>
      <el-table :data="tableData" style="margin:0;width:100%;color:#777777;font-size:14px;" :height="tableHeight"
        v-loading="tableLoading">
        <el-table-column prop="number" width="60" :label="$t('OrderDetail.rowNo')"></el-table-column>
        <el-table-column :width="WidthControl('MaterialId',tableData)" prop="MaterialId" :label="$t('OrderDetail.codeid')"></el-table-column>
        <el-table-column :width="WidthControl('MaterialName',tableData)" prop="MaterialName" :label="$t('OrderDetail.name')"></el-table-column>
        <el-table-column :width="WidthControl('MaterialSpec',tableData)" prop="MaterialSpec" :label="$t('OrderDetail.specifications')"></el-table-column>
        <el-table-column prop="SQuantity" align="right" :label="$t('OrderDetail.changenumber')" :min-width="WidthControl('SQuantity&&SUnitName',tableData)">
          <template slot-scope="scope">
            <span>{{ scope.row.SQuantity }}&nbsp{{ scope.row.SUnitName }}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="$store.state.ShowBasicUnit" prop="Quantity" align="right" :label="$t('OrderDetail.Quantity')" :min-width="WidthControl('Quantity&&UnitName',tableData)" >
          <template slot-scope="scope">
            <span>{{ scope.row.Quantity }}&nbsp{{ scope.row.UnitName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="PresentSQty" align="right" :min-width="WidthControl('TotalSQty&&SUnitName',tableData)"  :label="$t('OrderDetail.freenumber')">
          <template slot-scope="scope">
            <span>{{ scope.row.PresentSQty }}&nbsp{{ scope.row.SUnitName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="UnTransSQty" align="right" :min-width="WidthControl('UnTransSQty&&SUnitName',tableData)" :label="$t('OrderDetail.nonumber')">
          <template slot-scope="scope">
            <span>{{ scope.row.UnTransSQty }}&nbsp{{ scope.row.SUnitName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="SPrice" :width="WidthControl('SPrice',tableData,5)" align="right" :label="$t('OrderDetail.price')"></el-table-column>
        <el-table-column prop="OAmountWithTax" :width="WidthControl('OAmountWithTax',tableData,5)" align="right" :label="$t('OrderDetail.taxamount')"></el-table-column>
        <el-table-column :width="WidthControl('DeliveryDate',tableData,6)" prop="DeliveryDate" :label="$t('OrderDetail.deldate')"></el-table-column>
        <el-table-column v-if="$store.state.ShowMTO" :width="WidthControl('MTONo',tableData,5)" prop="MTONo" :label="$t('OrderDetailSearch.txtMTONo')"></el-table-column>
        <el-table-column prop="BatchNo" :width="WidthControl('BatchNo',tableData,5)" :label="$t('OrderDetailSearch.txtBatchNo')"></el-table-column>
        <el-table-column prop="Remark" :width="WidthControl('Remark',tableData)" :label="$t('OrderDetail.remark')"></el-table-column>
        <el-table-column v-if="SCM43" prop="ReceiveOrgName" :width="WidthControl('ReceiveOrgName',tableData,5)" :label="$t('OrderDetail.receiveOrgName')"></el-table-column>
        <el-table-column prop="Address" :width="WidthControl('Address',tableData,5)" :label="$t('OrderDetail.address')"></el-table-column>
        <el-table-column prop="RowState" :label="$t('OrderDetail.rowState')"></el-table-column>
      </el-table>
    </div>
    <el-dialog width="25%" :title="$t('OrderDetail.tip')" :visible.sync="printvisible" :append-to-body="true">
      <el-select style="width:100%" class="dialogselect" v-model="printType"
        :placeholder="typegather.length != 0 ? $t('OrderDetail.placeholder') : $t('OrderDetail.noneholder')">
        <el-option v-for="item in typegather" :key="item.value" :label="item.name" :value="item.value">
        </el-option>
      </el-select>
      <div slot="footer" class="dialog-footer">
        <el-button @click="print()" type="primary">{{ $t('button.determine') }}</el-button>
      </div>
    </el-dialog>
    <BillAccDialog ref="BillAccDialog"></BillAccDialog>
  </Frame>
</template>

<script>
import SvgIcon from '../components/SvgIcon'
import BillAccDialog from '@/components/BillAccDialog.vue'
import { getWebSupplierConfig } from "@/utils/utils";
import { PrintReport } from '@/utils/ReportPreView'
export default {
  name: 'OrderDetail',
  components: { SvgIcon,BillAccDialog },
  data() {
    return {
      progId:'purPurchaseOrder', // 采购订单
      tableLoading: true,
      tableHeight: window.innerHeight - 390,
      compName: '',
      billno: '',
      billdate: '',
      oAmountWithTax:'',
      amount: '',
      checkstate: '',
      goodsstate: '',
      purchaser: '',
      SignSt:'',
      remark: '无',
      tableData: [],
      printvisible: false,
      dialogvisible: false,
      dialogloading: true,
      dialogtitle: '',
      printType: '',
      printtemp: '',
      dialogdata: [],
      typegather: [],
      diaselect: [],
      SCM43:false,
      SCM78:false,
      operable:false,
      billAccessDownloadPower:false, // 是否有单据附件下载权限
      internalId:'', // 单据内部标识号
      isBillAccess:false // 是否单据附件
    }
  },
  created() {
    this.billno = this.$route.query.DelBillNo;
    if(this.$route.query.operable){
      this.operable = eval(this.$route.query.operable);
    }
    getWebSupplierConfig(this);
    this.loadpage();
  },
  mounted() {
    let that = this;
    that.tableHeight = window.innerHeight - 264 - this.$refs.headcontain.offsetHeight;
    window.onresize = () => {
      if (that.resizeFlag) {
        clearTimeout(that.resizeFlag);
      }
      that.resizeFlag = setTimeout(() => {
        that.tableHeight = window.innerHeight - 264 - this.$refs.headcontain.offsetHeight;
        that.resizeFlag = null;
      }, 100);
    }
  },
  methods: {
    loadpage(){
      this.axiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx",
      {
        action: "getorderdetail",
        billno: this.billno,
      }, (res) => {
        console.log(res.data);
        var data = eval(res.data);
        // this.tableData = data;
        this.internalId = data[0].InternalId; // 单据内部标识号
        this.compName = data[0].CompName;
        this.billdate = data[0].billdate;
        this.oAmountWithTax = data[0].OAmountWithTax;
        this.amount = data[0].amount;
        this.checkstate = data[0].checkstate;
        this.operable = data[0].operable;
        this.SignSt = data[0].SignSt;
     
        if (data[0].goodsstate == 0) this.goodsstate = this.$t('Order.unshipped');
        else if (data[0].goodsstate == 1) this.goodsstate = this.$t('Order.partialDel');
        else this.goodsstate = this.$t('Order.shipped');
        this.purchaser = data[0].purchaser;
        this.remark = data[0].remark;
        // ADV202401040013 供应商平台-订单表身增加收货组织、发货单表头增加收货组织
        if(data[0].SCM43){
          this.SCM43 = true;
        }
        if(data[0].SCM78){
          this.SCM78 = true;
        }
        data.shift(0);
        this.tableData = data;
        this.tableLoading = false;
        console.log(data);
      })
    },
    confirm() {//确认签收
      let billnos = [this.billno];
      let SignSt = [this.SignSt];
      this.axiosInvoke("/SupplierComm/Handler/OrderPurPurchaseHandler.ashx", {
        action: "comfirmorder",
        ay: JSON.stringify(billnos),
        say:JSON.stringify(SignSt)
        },
        (res) => {
          let data = eval(res.data);
          console.log(data);
          if(data[0][0]==this.billno){
            this.operable = false;
            this.loadpage();
            this.$store.commit("TIPPOP",{popType:'success',popMessage:this.$t("OrderDetail.orderconfirmed")});
          }
        })
    },
    veto() {//否决
      let billnos = [this.billno];
      this.$prompt(this.$t("button.vetoreason"), this.$t("button.orderveto"), {
        confirmButtonText: this.$t("button.determine"),
        cancelButtonText: this.$t("button.cancel"),
      }).then(({ value }) => {
        console.log(value);
        this.tableLoading = true;
        this.axiosInvoke("/SupplierComm/Handler/OrderPurPurchaseHandler.ashx", {
        action: "denyorder",
        supReply:value,
        billno: this.billno,
        orgSignSt:this.SignSt
        },res=>{
          let data = eval(res.data);
          console.log(data);
          if(data[0].billno==this.billno){
            this.loadpage();
            this.tableLoading = false;
            this.$store.commit("TIPPOP",{popType:'success',popMessage:this.$t("Order.vetoorder")});
          }
        });
      });
    },
    getVaryData(){
      sessionStorage.setItem("OrderDetail", JSON.stringify({
        pageindex: this.pageindex,
        billno: this.billno,
        hasBack: true,
        isDetialHref: true // 通过详情页访问，标识为true,用于后台查询作区分过滤条件
      }))
      this.$router.push({
        path: "/Home/OrderChangeSearch"
      })
    },
    Export() {
      //导出Excel
      let action = 'exportexcel'
      let progId = 'purPurchaseOrder'
      //取回当前查询表进行导出
      this.exportExcel(action, progId, this.tableData)
    },
    printdialog() {
      this.printvisible = true;
      this.axiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx",
        {
          action: "doprintbill",
          BillNo: this.billno,
          ProgId: "purPurchaseOrder",
          lang: localStorage.getItem("language") == "CHS"?"zh-CHS":"zh-CHT"
        },
        (res) => {
          var data = eval(res.data);
          this.printtemp = data[0];
          this.typegather = data[0].files;
          this.printType = decodeURIComponent(data[0].printType);
          var printExist = false;
          this.typegather.forEach(v=>{
            if(v.value == this.printType){
              printExist = true;
              return;
            }
          })
          if(!printExist)this.printType = "";
        })
    },
    print() {//打印
      PrintReport(this);
    },
    download(action,isBillAccess){
      this.$refs.BillAccDialog.GetAccList(this.billno,this.progId,this.internalId,action,isBillAccess);
    }
  }
}
</script>

<style scoped>
ul {
  padding: 0;
}

li {
  list-style: none;
  text-align: left;
}

.el-select-dropdown__item {
  display: flex;
}

.messagetitle {
  color: #5d5d5d;
  font-size: 16px;
  width: 20%;
  padding: 0 20px;
}

.button {
  color: #5d5d5d;
  font-size: 15px;
  cursor: pointer;
  white-space: nowrap;
}
.buttonArray{
  width: calc( 70% - 40px );
    justify-content: flex-end;
    display: flex;
}
.button-confirm{
  color: #0177d5;
  font-size: 15px;
  cursor: pointer;
  margin:0 2.5%;
  white-space: nowrap;
}
.button:hover {
  cursor: pointer;
  color: #0177d5;
}

.message {
  color: #777777;
  font-size: 14px;
  width: 24%;
  padding-right: 1%;
  word-break: break-all;
}

.option-icon{
  filter: grayscale(100%);
  margin:0 2.5%;
}
.option-icon:hover{
  filter: grayscale(0%);
}
.el-table /deep/ th.el-table__cell {
  background-color: #f9f9f9;
}

.el-table /deep/ .el-table__body-wrapper {
  font-size: 13px;
}

.el-table /deep/ thead {
  font-size: 14px;
  color: #777777;
}

.el-table /deep/ .el-table_1_column_1 .cell:first-child {
  padding: 0 0 0 30px;
}

.el-table /deep/ .cell:first-child {
  font-weight: normal;
}
</style>
