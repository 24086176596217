<template>
    <Frame>
        <div class="success">
            <i class="el-icon-success"></i>
            <span class="tip" v-html="$t('DeliverSuccess.success')+':&nbsp;&nbsp;&nbsp;'+$t('DeliverSuccess.billno')"></span>
            <span class="billno" @click="toDeliverSearch(billno)" v-for="billno in BillNos">{{ billno }}</span>
            <span class="texttip">{{$t('DeliverSuccess.texttip')}}</span>
        </div>
    </Frame>
</template>
<script>
export default {
    name:"DeliverSuccess",
    data(){
        return {
            DelBillNo:"this.$route.query.DelBillNo",
            BillNos:[]
        }
    },
    beforeRouteEnter(to,from,next){
        if(from.path == "/Home/DeliverCar" || from.path == "/Home/Deliver"){
            next() 
        }else{
            next("/Home/Deliver")
        }
        
    },
    created(){
        this.DelBillNo = this.$route.query.DelBillNo;
        this.BillNos = this.DelBillNo.split(",");
        debugger
    },
    methods:{
        toDeliverSearch(DelBillNo){
            this.$router.push({
                path:"/Home/DeliverSearchDetail",
                query:{
                    DelBillNo
                }
            })
        }
    }
}
</script>

<style scoped>
.success{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}
.success i{
    color:#409EFF;
    font-size: 80px;
}
.success span{
    margin:20px 0 0 0;
    font-size: 24px;
}
.success .billno{
    cursor: pointer;
    margin-bottom: 0;
}
.success .billno:hover{
    cursor: pointer;
    color:#409EFF;
}
.success .texttip{
    color: rgb(138, 137, 137);
    font-size: 18px;
}
</style>
