<template>
  <el-container>
    <el-aside width="208">
      <div class="logo">
        <img :src="logo">
      </div>
      <el-menu width="208" :router="true" :default-active="handlerDetail($route.path)">
        <el-menu-item index="/Home/Default">
          <template slot="title">
            <svg-icon icon-class="Home" />{{ $t('comm.default') }}
          </template>
        </el-menu-item>
        <el-submenu index="Order">
          <template slot="title">
            <svg-icon icon-class="Order" />{{ $t('comm.order') }}
          </template>
          <el-menu-item index="/Home/Order">{{ $t('comm.ordersearch') }}</el-menu-item>
          <!-- ADV202306010014 新版供应商平台配置，增加参数配置栏位【不同收货地址产生不同收货申请单】、是否显示【回复交期】、是否显示【发货基本单位】 -->
          <el-menu-item index="/Home/ReplyDeliveryTime" v-if="$store.state.AllowChangeOfDeliveryDate">{{ $t('comm.replydeliverytime') }}</el-menu-item>
          <el-menu-item index="/Home/OrderDetailSearch">{{ $t('comm.orderdetailsearch') }}</el-menu-item>
          <el-menu-item index="/Home/OrderChangeSearch">{{ $t('comm.orderchangesearch') }}</el-menu-item>
        </el-submenu>
        <el-menu-item index="/Home/Deliver">
          <template slot="title">
            <svg-icon icon-class="Deliver" />{{ $t('comm.deliver') }}
          </template>
        </el-menu-item>
        <el-menu-item index="/Home/DeliverSearch">
          <template slot="title">
            <svg-icon icon-class="DeliverSearch" />{{ $t('comm.deliversearch') }}
          </template>
        </el-menu-item>
        <!-- 退货查询 -->
        <el-menu-item index="/Home/ReturnQuery">
          <template slot="title">
            <svg-icon icon-class="ReturnQuery" />{{ $t('comm.returnQuery') }}
          </template>
        </el-menu-item>
        <el-menu-item index="/Home/Reconciliation">
          <template slot="title">
            <svg-icon icon-class="ReconciliationSearch" />{{ $t('comm.reconciliationsearch') }}
          </template>
        </el-menu-item>
        <el-menu-item index="/Home/ARSearch">
          <template slot="title">
            <svg-icon icon-class="ARSearch" />{{ $t('comm.ARSearch') }}
          </template>
        </el-menu-item>
        <el-submenu index="Report">
          <template slot="title">
            <svg-icon icon-class="Report" />{{ $t('comm.report') }}
          </template>
          <el-menu-item index="/Home/OrderExecution">{{ $t('comm.orderexecution') }}</el-menu-item>
          <el-menu-item index="/Home/TrackingProducts">{{ $t('comm.trackingProducts') }}</el-menu-item>
          <el-menu-item index="/Home/InspectionResults">{{ $t('comm.inspectionresults') }}</el-menu-item>
          <el-menu-item index="/Home/SupplyAssessment">{{ $t('comm.supplyassessment') }}</el-menu-item>
          <el-menu-item index="/Home/DeliverDelay">{{ $t('comm.deliverdelay') }}</el-menu-item>
        </el-submenu>
        <el-submenu index="/Home/OperationMenu" v-if="operationMenu.length!=0">
          <template slot="title">
            <svg-icon icon-class="OperationMenu" />{{ $t('comm.operationmenu') }}
          </template>
          <el-menu-item v-for="page in operationMenu" :index="'/Home/OperationMenu-'+page.UId">{{ page.DisplayName }}</el-menu-item>
        </el-submenu>
        <el-submenu index="/Home/DownLoad">
          <template slot="title">
            <svg-icon icon-class="DownLoad" />{{ $t('comm.download') }}
          </template>
          <el-menu-item index="/Home/SortFile">{{ $t('comm.sortfile') }}</el-menu-item>
          <el-menu-item v-show="SCM78" index="/Home/DownloadPrint">{{ $t('comm.downloadprint') }}</el-menu-item>
        </el-submenu>
        <el-menu-item index="/Home/HelpCenter">
          <template slot="title">
            <svg-icon icon-class="Help" />{{ $t('comm.help') }}
          </template>
        </el-menu-item>
        <el-submenu index="/Home/InformCenter">
          <template slot="title">
            <svg-icon icon-class="publication" />{{ $t('comm.inform') }}
          </template>
          <el-menu-item index="/Home/BulletinCenter">{{ $t('comm.bulletin') }}</el-menu-item>
          <el-menu-item index="/Home/Information">{{ $t('comm.message') }}</el-menu-item>
        </el-submenu>
      </el-menu>
    </el-aside>

    <el-container style="background-color:#859faf17">
      <el-header>
        <div class="headinfo">
          <div class="user">
            <svg-icon style="transform:scale(0.65);margin: auto 0;" icon-class="Profile" />
            <XElTooltip :overflow="true" :content="username" placement="bottom">
              <span style="margin: auto 0;" >{{ username }}</span>
            </XElTooltip>
            <el-dropdown @command="handleCommand" trigger="click">
              <i class="el-icon-arrow-down"></i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="changePwd">{{ $t('Home.changepassword') }}</el-dropdown-item>
                <el-dropdown-item command="billSetting">{{ $t('Home.billsetting') }}</el-dropdown-item>
                <el-dropdown-item command="loginInfo">{{ $t('Home.register') }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="deliver" @click="turnToCar">
            <span class="button">
              <svg-icon style="transform:scale(0.8);" icon-class="DeliverCar" />{{ $t('Home.delivercar') }}
            </span>
            <!-- <span>{{ $t('Home.delivercar') }}</span> -->
            <span class="red">{{ deliverCarCount }}</span>
          </div>
          <div class="button exit" @click="doLoginout">
            <svg-icon style="transform:scale(0.65);margin: auto 0;" icon-class="Exit" />{{ $t('Home.back') }}
            <!-- <el-button class="button" type="text">{{ $t('Home.back') }}</el-button> -->
          </div>
        </div>
      </el-header>
      <router-view></router-view>
      <DialogChangePwd ref="dialog"></DialogChangePwd>
      <el-dialog :title=" $t('Home.logininfo')" :visible="userinfodialog" width="30%" :before-close="handleClose">
        <div style="padding-left:5%" v-loading="dialoading">
          <div class="logininfo-row"><div class="row-dot"></div><span>{{ $t('Home.createtime') }}:</span><span class="row-detail">{{userinfo.CreateTime}}</span></div>
          <div class="logininfo-row"><div class="row-dot"></div><span>{{ $t('Home.lastlogindate') }}:</span><span class="row-detail">{{userinfo.LastLoginDate}}</span></div>
          <div class="logininfo-row"><div class="row-dot"></div><span>{{ $t('Home.membertype') }}:</span><span class="row-detail">{{userinfo.MemberType}}</span></div>
          <div class="logininfo-row"><div class="row-dot"></div><span>{{ $t('Home.ipaddress') }}:</span><span class="row-detail">{{userinfo.IP}}</span></div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="userinfodialog =false">{{ $t('comm.confirm') }}</el-button>
        </span>
      </el-dialog>
      <el-dialog :title="$t('Home.billsetting')" :visible="billSettingdialog" width="500px" :before-close="BillhandleClose">
        <div style="padding-left:5%" v-loading="dialoading">
          <div class="billsetting-row">
            <div class="billsetting-title">
              {{ $t('Home.toolbarShowStyle') }}:
            </div>
            <div class="billsetting-options">
              <el-radio v-model = "toolbarShowStyle" label="showIconAndText">  {{ $t('Home.showIconAndText') }}</el-radio>
              <el-radio v-model = "toolbarShowStyle" label="showIcon">  {{ $t('Home.showIcon') }}</el-radio>
              <el-radio v-model = "toolbarShowStyle" label="showText">  {{ $t('Home.showText') }}</el-radio>
            </div>
          </div>
          <div class="billsetting-row">
            <div class="billsetting-title">{{ $t('Home.fieldRawTextType') }}:</div>
            <div class="billsetting-options">
              <el-radio v-model = "fieldRawTextType" label="0">{{ $t('Home.showcodeandname') }}</el-radio>
              <el-radio v-model = "fieldRawTextType" label="1">{{ $t('Home.showname') }}</el-radio>
              <el-radio v-model = "fieldRawTextType" label="2">{{ $t('Home.showcode') }}</el-radio>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button  @click="billSettingdialog =false">{{ $t('button.Cancel')}}</el-button>
          <el-button type="primary" @click="confirmBillSetting">{{ $t('comm.confirm') }}</el-button>
        </span>
      </el-dialog>
      <div class="footer">{{ webSupCopyRight }} &nbsp;&nbsp;<span class="linkHref" @click="linkHref();">{{ internetContentProvider }}</span></div>
    </el-container>
  </el-container>
</template>

<script>
import { mapState } from "vuex";
import DialogChangePwd from '@/components/DialogChangePwd';
import XElTooltip from "@/components/X-el-tooltip.vue";
import {getWebSupplierConfig,loadAndUpdateVersion} from "@/utils/utils"
export default {
  components: {
    DialogChangePwd,XElTooltip
  },
  data() {
    return {
      loginDate:'',
      username: "",
      userId:"",
      timer: '',
      SCM78:false,
      dialogFormVisible: false,
      labelPosition: "right",
      logo: '',
      userinfodialog:false,
      billSettingdialog:false,
      dialoading:false,
      toolbarShowStyle:'showIconAndText',
      fieldRawTextType:'0',
      form: {
        oldPwd: "",
        newPwd: "",
        confirmPwd: ""
      },
      userinfo:{},
      operationMenu: [],
      webSupCopyRight:'',
      internetContentProvider:'',
      internetContentProviderHref:''
    };
  },
  created() {
    this.getOperationMenu();
  },
   mounted() {
    this.getUserConfig();
    this.invokeKeepAlive();
    this.axiosInvoke("/SupplierComm/Handler/BulletinHandler.ashx?",
      { action: 'getuserinfo' },
      (res) => {
        var userinfo = eval(res.data);
        this.username = userinfo[0].username;
        this.userId = userinfo[0].userId;
        this.logo = userinfo[0].logo;
      })
    this.$store.commit("GETPAGESIZE",localStorage.getItem("pagesize")?Number(localStorage.getItem("pagesize")):10);
    this.$store.dispatch("getDeiverCarCount", this);
    getWebSupplierConfig(this);
    this.checkSCM78();
  },
  methods: {
    //保持连线
    invokeKeepAlive() {
      if (sessionStorage.getItem("KeepAlive") && sessionStorage.getItem("KeepAlive") == "KeepAlive") {
        this.timer = setInterval(this.keepAlive, 30000);
      }
    },

    //连线请求
    keepAlive() {
      this.axiosInvoke("/Functions/Sys.ashx", { action: 'keepalive' });
    },

    //登出操作
    doLoginout() {
      this.axiosInvoke("/SupplierComm/handler/SysHandler.ashx?",
        { action: 'logout' },
        (res) => {
          var resData = res.data;
          if (this.timer) {
            clearInterval(this.timer);
          }
          console.log(resData);
        })
      this.$router.replace("/");
    },
    getUserConfig() {
      this.axiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx", {
        action: "getuserconfig",
      },
        res => {
          let data = eval(res.data);
          this.loginDate = decodeURIComponent(data[0].loginDate);
          if(data[1].WebSupCopyRight && data[1].WebSupCopyRight!="")
          {
            this.webSupCopyRight = data[1].WebSupCopyRight;
          }
          if(data[1].InternetContentProvider && data[1].InternetContentProvider!="")
          {
            this.internetContentProvider = data[1].InternetContentProvider;
          }
          if(data[1].InternetContentProviderHref && data[1].InternetContentProviderHref!="")
          {
            this.internetContentProviderHref = data[1].InternetContentProviderHref;
          }
          loadAndUpdateVersion(this);
        })
    },
    linkHref(){
      if(this.internetContentProviderHref && this.internetContentProviderHref !=""){
        window.open(this.internetContentProviderHref);
      }
    },
    //跳转发货�?
    turnToCar() {
      this.$router.push("/Home/DeliverCar");
    },

    //修改密码操作;修改单据操作；查看登录信息
    handleCommand(command) {
      if (command == "changePwd") {
        this.$refs.dialog.dialogFormVisible = true
      }
      if(command == "loginInfo"){
        this.userinfodialog = true;
        this.dialoading = true;
        this.axiosInvoke("/SupplierComm/handler/VueSysHandler.ashx?",
        { action: 'getuserdetailinfo' },
        (res) => {
          var data = res.data;
          this.userinfo = data;
          switch(data.MemberType){
            case '0':
              data.MemberType = this.$t('Home.commonuser');
              break; 
            case '1':
              data.MemberType = this.$t('Home.tempuser');
              break; 
            case '2':
              data.MemberType = this.$t('Home.client');
              break; 
            case '3':
              data.MemberType = this.$t('Home.supplier');
              break; 
            default:
              data.MemberType = this.$t('Home.unknow');
              break; 
          }
          this.dialoading = false;
        })
      }
      if(command == "billSetting"){
        this.billSettingdialog = true;
        this.axiosInvoke("/EIP/Functions/BillSetting.ashx",{
          action:'getBillSetting',
          preConfig: false
        },(res)=>{
          var data = res.data;
          console.log(data);
          if(data.fieldRawTextType){
            this.fieldRawTextType = data.fieldRawTextType;
          }else{
            this.fieldRawTextType = "0";
          }
          if(data.toolbarShowStyle){
            this.toolbarShowStyle = data.toolbarShowStyle;
          }else{
            this.toolbarShowStyle = "showIconAndText"
          }
			    var key = this.userId + "_toolbarshowstyle";
			    var value = JSON.stringify({ toolbarShowStyle: this.toolbarShowStyle });
			    localStorage.setItem(key, value);
        })
      }
    },

    //获取操作菜单
    getOperationMenu() {
      this.axiosInvoke('/SupplierComm/Handler/VueSysHandler.ashx', {
        action: "getoperationmenu",
      }, async (res) => {
        var data = eval(res.data);
        this.operationMenu = data;
      })
    },

    //剪切名称中的Detail 用于左侧导航栏高光定�?
    handlerDetail(path) {
      if (path.indexOf("Detail") > -1 && path.substring(path.indexOf("Detail")+6)=="") {
        path = path.slice(0, path.indexOf("Detail"));
      }
      return path
    },
    handleClose(){
      this.userinfodialog = false;
    },
    BillhandleClose(){
      this.billSettingdialog = false;
    },
    //检查SCM78配置�?
    checkSCM78(){
      this.axiosInvoke('/SupplierComm/Handler/VueSysHandler.ashx', {
        action: "checkscm78",
      }, async (res) => {
        var data = res.data;
        this.SCM78 = data.SCM78;
      })
    },
    confirmBillSetting(){
      this.axiosInvoke("/EIP/Functions/BillSetting.ashx",{
        action:'savebillsetting',
        fieldRawTextType:this.fieldRawTextType,
        toolbarShowStyle:this.toolbarShowStyle,
      },(res)=>{
        var data = res.data;
        if(data.success){
          this.billSettingdialog = false;
          this.$store.commit("TIPPOP",{popType:'success',popMessage:this.$t('Home.billsettingsucess')});
          var key = this.userId + "_toolbarshowstyle";
          var value = JSON.stringify({ toolbarShowStyle: this.toolbarShowStyle });
          localStorage.setItem(key, value);
        }
        })
        
    }
  },
  computed: {
    ...mapState(["deliverCarCount", "keepalive"])
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-container {
  height: 100vh;
  position: relative;
}

.el-container /deep/ .el-aside {
  background-color: #103e7d;
  max-width: 208px;
  width: 15vw;
  min-width: 178px;
  box-shadow: 7px 0px 6px 0px rgb(0 0 0 / 10%);
  scrollbar-width: none;
}
.logininfo-row{
  margin-bottom: 7px;
  display: flex;
}
.row-detail{
  padding-left:3%; 
}
.row-dot{
  height:5px;
  width: 5px;
  background-color: #249dfa;
  border-radius: 50%;
  margin:auto 2%;
}
.linkHref {
  color: #959595;
  font-size: 14px;
  cursor: pointer;
}
.button {
  /* 灰度100%，图像完全变灰；亮度0%，图像将完全变黑，100%恢复原始图像； */
  filter: grayscale(1) brightness(0.6); 
  cursor: pointer;
  color: #575757;
  font-size: 15px;
}
.button:hover {
  /* 灰度0%，代表元素图像 */
  filter: grayscale(0);
  cursor: pointer;
  color: #409EFF;
}

@media screen and (max-width: 1180px) {
  .el-container /deep/ .el-main {
  margin: 0px 0px 0px!important;
}
}

.el-container /deep/ .el-aside::-webkit-scrollbar {
  width: 0 !important;
}

.el-container /deep/ .logo {
  text-align: center;
  height: 70px;
  line-height: 80px;
}

.el-container /deep/ .logo img {
  width: 137px;
  height: auto;
}

.el-container /deep/ .el-menu {
  background-color: #103e7d;
  width: 100%;
}

.el-container /deep/ .el-menu .svg-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: text-top;
  margin: 0 0.7rem;
}

.el-container /deep/ .el-menu-item-group__title,
.el-container /deep/ .el-submenu__title,
.el-container /deep/ .el-submenu .el-menu-item,
.el-container /deep/ .el-menu-item {
  color: white;
}

.el-container /deep/ .el-menu-item:focus,
.el-container /deep/ .el-menu-item:hover,
.el-container /deep/ .el-submenu__title:focus,
.el-container /deep/ .el-submenu__title:hover,
.el-container /deep/ .el-menu-item.is-active,
.el-container /deep/ .el-submenu__title.is-active {
  outline: 0;
  background-color: #46a8f4 !important;
}

.el-container /deep/ .el-aside .el-submenu__title,
.el-container /deep/ .el-aside .el-menu-item {
  padding-left: 0 !important;
  height: 50px;
  line-height: 50px;
  font-size: 14px;
}

.el-container /deep/ .el-aside .el-submenu .el-menu-item {
  padding-left: 25% !important;
}

.el-container /deep/ .el-header {
  color: #575757;
  line-height: 55px;
  height: 55px !important;
  display: flex;
  justify-content: flex-end;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
  font-size: 14px;
  background-color: white;
}

.el-container /deep/ .el-header .headinfo {
  display: flex;
  width: 50%;
  justify-content: space-around;
}

.el-container /deep/ .el-header .svg-icon {
  width: 2rem;
  height: 2rem;
  vertical-align: middle;
  margin-right: 0.5rem;
}

.el-container /deep/ .el-header .user span {
  /* display: inline-flex; */
  /* max-height: 100%; */
  min-width: 100px;
  max-width: 160px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-inline-box;
  max-height: 63%;
  -webkit-box-orient: vertical;
  -webkit-line-clamp:inherit;
}

.el-container /deep/ .el-header .user i {
  margin-left: 5px;
}

.el-container /deep/ .el-header .user i:hover {
  cursor: pointer;
}

.el-container /deep/ .el-header .deliver:hover {
  cursor: pointer;
}

.el-container /deep/ .el-header .deliver .red {
  color: red;
}

.el-container /deep/ .el-header .exit {
  display: flex;
  cursor: pointer;
}

.el-container /deep/ .el-header .exit .el-button {
  color: black;
  font-size: 14px;
}

.el-container /deep/ .el-main {
  padding: 0;
  margin: 15px 15px 25px 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  background-color: white;
  position: relative;
  scrollbar-width: none;
}

.el-container /deep/ .el-main.isDefault {
  margin: 0;
}

.el-container /deep/ .el-main::-webkit-scrollbar {
  width: 0 !important;
}
.el-submenu /deep/.el-submenu__title i{
  color: white;
}
.el-container /deep/ .footer {
  position: absolute;
  bottom: 3px;
  left: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(-50%, 0);
  color: #959595;
  font-size: 14px;
}

.dialogForm /deep/ .el-form-item {
  width: 90%;
  margin-bottom: 30px;
}
.billsetting-row{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding-bottom: 15px;
}
.billsetting-title{
  width: 30%
}
.billsetting-options{
  width: 70%;
  flex-wrap: wrap;
  display: flex;
}
.billsetting-options .el-radio{
  width: 100%;
  padding-bottom: 10px;
}
.dialogForm /deep/ .el-form-item .el-form-item__content {
  width: calc(100% - 140px);
}
</style>
