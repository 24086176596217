<template>
    <el-dialog class="msgDialog" :title="$t('comm.uploadFile')" :visible="uploadVisible" :show-close="true" @close="showClose">
      <div>
        <el-form ref="dataForm">
            <el-form-item :label="$t('comm.chooseFile')">
                <el-upload class="upload-demo"
                    ref="upload"
                    drag
                    action="/"
                    :file-list="fileList"
                    :http-request="uploadFile"
                    :before-upload="beforeUpload"
                    :on-preview="handlePreview"
                    :on-remove="handleRemove"
                    :limit="3"
                    :on-exceed="handleExceed"                    
                    >
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text" :disabled="btnDisabled">{{$t('comm.uploadTiptxt')}}<em>{{ $t('comm.uploadClick') }}</em></div>
                    <div class="el-upload__tip" slot="tip">{{ $t('comm.fileTypeListTxt') }}{{this.allowFileTypeListTxt}}{{$t('comm.allowFileNumberTip')}}</div>
                </el-upload>
            </el-form-item>
        </el-form>
      </div>
      <el-button type="primary" :disabled="btnDisabled" @click="doUpLoad()">{{ $t('comm.uploadConfirmText') }}</el-button>
      <el-button :disabled="btnDisabled" @click="showClose()">{{ $t('comm.cancelText') }}</el-button>
    </el-dialog>
</template>
<script>
export default {
    props:{
        base:{
            type:Object,
            default:new Object()
        },
        uploadDate:{
            type:String,
            default:''
        },
        // 允许上传后缀名列表显示
        allowFileTypeListTxt:{
            type:String,
            default:''
        }, 
        // 允许上传后缀名列表
        allowFileTypeList:{
            type:String,
            default:''
        },
    },
    name:'DialogUpload',
    data(){
        return{
            allFileType: false, // 支持所有文件后缀名
            tempFileTypeList: new Array(),
            btnDisabled:false, // 上传按钮是否禁用
            uploadVisible:false,
            url:'',
            headers:{},
            fileList: [],
            //上传的附件列表
            accessoryList:[],
            dataForm:''
        }
    },
    methods:{
        uploadFile(param){
            debugger
            this.param = param;
            // 此处第二个参数固定为lastModifiedDate（后台解析有调用到）
            this.axiosInvoke("/SupplierComm/Handler/AccessoryHandler.ashx?action=upload&lastModifiedDate=" + param.file.lastModifiedDate.toLocaleString()+"&lastModified=" + param.file.lastModified + "&allFileType=" + this.allFileType + "&checkFileTypeList=" + this.tempFileTypeList + "&isVuePage=true", param, (res,errflag) => {
                debugger
                //  ADV202310230016 新版供应商，增加支持上传文件后缀名限制动态配置，通过读取平台配置【维护附件类型】对应的后缀名信息
                if(errflag){
                    // 移除附件上传列表（匹配当前行项移除）
                    let uploadFiles = this.$refs.upload.uploadFiles;
                    if(uploadFiles.length > 0){
                        for(var i = uploadFiles.length - 1; i >= 0; i--){
                            let tempFile = uploadFiles[i];
                            if(param.file.lastModified == tempFile.raw.lastModified && param.file.lastModifiedDate.toGMTString() == tempFile.raw.lastModifiedDate.toGMTString())
                            {
                                this.$refs.upload.uploadFiles.splice(i,1);
                            }
                        }
                    }
                    // 移除附件缓存列表显示（移除当前创建的最新一个）
                    let uploadFilechildNodes = this.$refs.upload.$el.querySelectorAll('.el-upload-list')[0].childNodes;
                    debugger
                    if(uploadFilechildNodes.length > 0){
                        this.$refs.upload.$el.querySelectorAll('.el-upload-list')[0].childNodes[uploadFilechildNodes.length-1].remove();
                    }
                    this.tableLoading = false;
                    return false;
                }else{
                    let result = eval(res.data);
                    debugger
                    if (result && result.length > 0) {
                        debugger
                        result[0].State = 1; //0 没有异动，1 新增，2 修改，3 删除 
                        result[0].isSelf = true; //是否是当前用户上传
                        this.accessoryList.push(result[0]);
                    }
                }
            },true);
        },
        doUpLoad(){
            debugger
            this.btnDisabled = true; // 禁用按钮点击
            this.base.tableLoading = true;
            if(this.accessoryList && this.accessoryList.length>0){                
                this.axiosInvoke('/SupplierComm/Handler/BillInfo.ashx', 
                {
                    action: "save",
                    billNo: this.base.billNo,
                    progId: this.base.progId,
                    AccessoryList: JSON.stringify(this.accessoryList), 
                    InternalId: this.base.internalId,
                    isVuePage: true,
                    AccessState:'addnew' // 附件状态：新增、删除、下载
                },
                (res) => {
                    this.accessoryList.forEach((acc)=>{
                        this.axiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx",{
                        action:'updateaccopinfo',
                        ProgId:this.base.progId,
                        BillNo:this.base.billNo,
                        AccessoryName:acc.fileName,
                        operation:'addnew'
                    })
                    })
                  
                    // 移除缓存文件
                    this.$refs.upload.clearFiles(); // 清空已上传的文件
                    this.accessoryList = []; // 数组置空
                    // 隐藏上传弹窗
                    this.uploadVisible = false;
                    this.btnDisabled = false; // 解除禁用按钮点击
                    this.base.tableLoading = false;
                    debugger
                    var data = res.data;
                    if(data && data.isDoPostFile){ // 上传成功后的逻辑
                        // 重新获取附件(父组件刷新数据)
                        this.base.getbillaccessorylist();
                        //执行后的提示
                        this.$message({
                            type:'success',
                            message:this.$t('comm.uploadSuccess')
                        });   
                    }               
                });
            }else{
                this.$message.warning(this.$t('comm.fileNullTip')); // 请选择要上传的文件!
                setTimeout(() =>{
                    this.btnDisabled = false; // 解除禁用按钮点击
                    this.base.tableLoading = false;
                },3000)
                return false;
            }
        },
        showClose(){
            debugger
            if(this.accessoryList && this.accessoryList.length>0){                
                this.axiosInvoke('/SupplierComm/Handler/AccessoryHandler.ashx', 
                {
                    action: "deletelist",
                    AccessoryList: JSON.stringify(this.accessoryList)
                },
                (res) => {
                    debugger
                    var data = res.data;
                    if(data.result){
                    // 移除缓存文件
                    this.$refs.upload.clearFiles(); // 清空已上传的文件
                    this.accessoryList = []; // 数组置空 
                    }             
                });
            }
            // 隐藏上传弹窗
            this.uploadVisible = false;
        },
        // 上传前校验
        beforeUpload(file) {
            debugger
            if(file.size > 1024 * 1024 * 20){
                this.$message.warning("\“" + file.name + "\”" + this.$t('comm.fileSizeTip')); // 文件过大,请上传小于等于20M的文件!
                return false;
            }
            if(file.name.indexOf('@')!=-1 ||file.name.indexOf('#')!=-1||file.name.indexOf('""')!=-1||file.name.indexOf('<')!=-1||file.name.indexOf('>')!=-1||file.name.indexOf('?')!=-1||file.name.indexOf('|')!=-1||file.name.indexOf('/')!=-1||file.name.indexOf(':')!=-1||file.name.indexOf('`\`')!=-1){
                this.$message.warning("\“" + file.name + "\”" + this.$t('comm.fileSpecialCharTip'));    
                return false;
            } 
            let index = file.name.lastIndexOf(".");
            let Type = file.name.substr(index + 1, file.name.length);
            this.tempFileTypeList = new Array();
            if(this.allowFileTypeList.indexOf(';') > -1){
                let configFileTypeList = this.allowFileTypeList.split(';');
                configFileTypeList.forEach(v =>{
                    let tempFileType = v.split('.');
                    if(tempFileType && tempFileType.length > 1){
                        if(tempFileType[1] == "*"){
                        this.allFileType = true; // 支持所有文件后缀名
                        return;
                        } 
                        else{
                            this.tempFileTypeList.push(tempFileType[1]);
                        }
                    }
                });
            }else{
                let tempFileType = this.allowFileTypeList.split('.'); 
                if(tempFileType && tempFileType.length > 1){
                    if(tempFileType[1] == "*"){
                        this.allFileType = true; // 支持所有文件后缀名
                    } 
                    else{
                        this.tempFileTypeList.push(tempFileType[1]);
                    }
                }
            }
            // ".doc",".docx",".xls",".xlsx",".ppt",".pptx",".txt",".jpg",".png",".gif",".bmp",".rar",".zip",".7z",".pdf",".xmind"——套装附件上传支持后缀名
            // if (['doc', 'docx', 'xlsx', 'xls', 'ppt', 'pptx','txt', 'jpg', 'png', 'gif', 'bmp', 'zip', 'rar','7z','pdf','xmind'].indexOf(Type.toLowerCase()) === -1) {
            if (!this.allFileType && this.tempFileTypeList.indexOf(Type.toLowerCase()) === -1) {
                this.$message({
                type: 'warning',
                message: this.$t('comm.fileTypeListTxt') + this.allowFileTypeList, // 仅支持上传格式： + 平台配置后缀名列表
                // message: this.$t('comm.fileTypeListTxt') + this.$t('comm.fileTypeList'), // '仅支持上传格式：doc、docx、xlsx、xls、ppt、pptx、txt、jpg、png、gif、bmp、zip、rar、7z、pdf、xmind'
                });
                return false;
            }
        },
        handleRemove(file) {
            debugger
            if(this.accessoryList && this.accessoryList.length>0){
                for(var i=0;i<this.accessoryList.length;i++){
                    var accessTemp = this.accessoryList[i];
                    if(accessTemp.fileName == file.raw.name && accessTemp.lastModified == file.raw.lastModified){
                        // 匹配当前删除行，从数组中移除
                        this.accessoryList.splice(i,1);
                        break;
                    }
                }
            }            
        },
        handleExceed(files) {
            this.$message.warning(this.$t('comm.fileMaxNumberTip')); // 文件上传数量已达上限
        },
        // 消除文件校验
        handleChange(file, fileList) {
            if (fileList.length > 0) {
                this.dataForm.fileList = fileList;
                this.$refs.dataForm.clearValidate('fileList'); // 清除文字校验
            }
        },
        handlePreview(res, file) {
            if (res.code == 0) {
                this.dataForm.url = res.data.url;
                this.dataForm.name = res.data.name;
                this.dataForm.fileId = res.data.fid;
                this.dataForm.size = file.size;
            }
        }        
    },
    watch:{
        uploadDate(n,o){
            debugger
            this.uploadVisible = this.base.showUpload;
        }
    }
}
</script>
<style>
.msgDialog{
    width: 100%;
    margin: 0 auto;
}
.msgDialog .el-dialog{
    width: 500px;
}
.msgDialog .el-dialog__header{
  padding: 10px;
}
.msgDialog .el-dialog__body{
  padding: 10px;
}
.msgContent{
  padding-bottom: 10px;
}
</style>
