<template>
  <Frame>
    <PageHeader :title="$t('comm.deliverdelay')" :hasBack="false" />
    <div class="deliverdelay" style="padding:10px 10px 0;">
      <div class="delaymessage">
        <span class="detialmessage">{{ $t('DeliverDelay.messagedate') }}：
          <el-date-picker v-model="date" type="daterange" style="width:30%;color:#bbbbbb;" range-separator="~" @change="getTime()" :unlink-panels="true" :default-value="timeDefault"
            :start-placeholder="$t('comm.startdate')" :end-placeholder="$t('comm.enddate')" size="small"></el-date-picker>
        </span>
        <span class="detialmessage" style="padding-left:30px">{{ $t('DeliverDelay.product') }}：
          <el-input :placeholder="$t('DeliverDelay.inputpls')" v-model="product" clearable style="width:30%;color:#bbbbbb;"
            @change="search(0)" size="small"></el-input>
        </span>
        <span class="detialmessage">
          &nbsp;&nbsp;
          <el-checkbox v-model="containUnRelease" @change="search(0);" :disabled="isDisabled" class="checkBox">{{ $t('comm.containUnRelease') }}</el-checkbox>
        </span>
      </div>
      <div class="delaymessage">
        <span class="detialmessage">{{ $t('DeliverDelay.specifications') }}：
          <el-input :placeholder="$t('DeliverDelay.inputTxt')" v-model="materialSpec" clearable style="width:30%;color:#bbbbbb;" @change="search(0)" size="small"></el-input>
        </span>
        <span style="float:right;padding-right:20px;padding-bottom:20px;">
          <el-button type="reset" @click="reset()" style="height: 32px;padding: 8px 20px;">{{ $t('button.reset') }}</el-button>
          <el-button type="primary" @click="query()" style="height: 32px;padding: 8px 20px;">{{ $t('button.query') }}</el-button>
        </span>
      </div>
      <el-table ref="multipleTable" :data="tableData" :height="tableHeight" style="margin:0;width:100%"
        tooltip-effect="dark" v-loading="tableLoading">
        <el-table-column prop="BillDate" :label="$t('DeliverDelay.date')" min-width="90"></el-table-column>
        <el-table-column prop="BillNo" :label="$t('DeliverDelay.messagenumber')">
          <template scope="scope">
            <el-link :underline="false" @click=" ToOrderDetail(scope.row.BillNo)">
            {{ scope.row.BillNo }}</el-link>
            </template>
        </el-table-column>
        <el-table-column :width="WidthControl('MaterialId',tableData)" prop="MaterialId" :label="$t('DeliverDelay.codeid')" ></el-table-column>
        <el-table-column :width="WidthControl('MaterialName',tableData)" prop="MaterialName" :label="$t('DeliverDelay.name')" ></el-table-column>
        <el-table-column :width="WidthControl('MaterialSpec',tableData)" prop="MaterialSpec" :label="$t('DeliverDelay.specifications')" ></el-table-column>
        <el-table-column prop="TotalSQty" align="right" :label="$t('DeliverDelay.allnumber')">
          <template slot-scope="scope">
          <span>{{ scope.row.TotalSQty }}&nbsp{{ scope.row.SUnitName }}</span>
        </template>
      </el-table-column>
        <el-table-column prop="DeliveryDate" :label="$t('DeliverDelay.messagedate')" min-width="90"></el-table-column>
        <el-table-column prop="LastConfirmDate" :label="$t('DeliverDelay.wareday')" min-width="90"></el-table-column>
        <el-table-column prop="StoreConfirmSQty" align="right" :label="$t('DeliverDelay.stockquantity')" >
        <template slot-scope="scope">
          <span>{{ scope.row.StoreConfirmSQty }}&nbsp{{ scope.row.SUnitName }}</span>
        </template>
        </el-table-column>
        <el-table-column prop="DelayDays" :label="$t('DeliverDelay.delayday')"></el-table-column>
      </el-table>
      <div>
        <el-pagination style="padding-left:50%;width:50%;" @current-change="handleCurrentChange"
          layout="total,prev,pager,next,jumper" :pager-count="5" :current-page="currentPage" :total="totalCount">
        </el-pagination>
      </div>
    </div>
  </Frame>
</template>

<script>
import SvgIcon from '../components/SvgIcon'
import { handleShortDate } from '@/utils/handleDate';
import { getTimeoutVal, radioJudgeExcel } from '../utils/utils.js'
export default {
  name: 'DeliverDelay',
  components: { SvgIcon },
  data() {
    return {
      //ADV202312040006 供应商平台优化-交货延迟报表增加纳入结案订单
      isDisabled: false, // 是否可点击
      containUnRelease: false, // 是否含结案
      tableHeight: window.innerHeight - 273,
      product: '',
      materialSpec: '',
      date: '',
      tableLoading: false,
      startdate: '',
      enddate: '',
      totalCount: 0,
      currentPage: 1,
      pageindex: 0,
      pagesize: 10,
      timeDefault:'',
      tableData: []
    }
  },
  created(){
    this.getSessionInform("DeliverDelay",(v)=>{
            this.currentPage = v.currentPage;
            this.pageindex = v.pageindex;
            this.product = v.product;
            this.materialSpec = v.materialSpec;
            this.date = v.date;
            this.startdate = v.startdate;
            this.enddate = v.enddate;
        })
  },
  mounted() {
    let that = this;
    window.onresize = () => {
      if (that.resizeFlag) {
        clearTimeout(that.resizeFlag);
      }
      that.resizeFlag = setTimeout(() => {
        that.tableHeight = window.innerHeight - 273;
        that.resizeFlag = null;
      }, 100);
    }
    this.timeDefault =new Date();
    this.timeDefault.setMonth(new Date().getMonth()-1);
    this.search(this.pageindex);
  },
  methods: {
    search(pageindex) {
      var timeVal= getTimeoutVal(); //设置计时器统一时间 //控制复选框更新频率500毫秒
      this.isDisabled = true;
      setTimeout(() =>{ 
        this.isDisabled = false;
      },timeVal);
      this.pageindex = pageindex;
      this.tableLoading = true;
      this.axiosInvoke("/SupplierComm/Handler/VueReportHandler.ashx", {
        action: 'purconsignmentdelayanalysisrpt',
        containUnRelease: this.containUnRelease, // 单据状态（结案）
        startdate: this.startdate,
        enddate: this.enddate,
        material: this.product,
        materialSpec: this.materialSpec,
        pagesize: this.pagesize,
        pageindex,
      }, (res,errflag) => {
        if(errflag) {this.tableLoading = false; this.tableData = null; return;}          
        var data = eval(res.data);
        this.totalCount = data[0].totalCount;
        this.currentPage = pageindex + 1;
        data.shift();
        this.tableData = data;
        this.tableLoading = false;
      })
    },
    handleCurrentChange(val) {
      this.pageindex = val - 1;
      this.search(val - 1);
    },
    getTime() {
      if(this.date){
        this.startdate = handleShortDate(this.date[0], "From");
        this.enddate = handleShortDate(this.date[1], "To");
      }
      else{
        this.startdate = "";
        this.enddate = "";
      }
      this.search(0);
    },
    reset() {//重置
      this.product = "";
      this.materialSpec = "";
      this.date = "";
      this.startdate = "";
      this.enddate = "";
      this.search(0);
    },
    query() {//查询
      this.search(0);
    },
     //跳转到orderDetail
     ToOrderDetail(billno){
            this.recoredPageToJump({
                pageindex:this.pageindex,
                currentPage: this.currentPage,
                pagesize: this.pagesize,
                product: this.product,
                materialSpec: this.materialSpec,
                startdate: this.startdate,
                enddate: this.enddate,
                date:this.date,
            },"DeliverDelay",billno,"OrderDetail");
        },
    //加载el-tooltip信息，鼠标聚焦时显示完整信息
    renderTitle(h, { column }) {
      let titleValue = '';
      let columnRealWidth = column.realWidth;
      switch (column.property) {
        case 'BillDate':
          titleValue = this.$t("DeliverDelay.date");
          break;
        case 'BillNo':
          titleValue = this.$t("DeliverDelay.messagenumber");
          break;
        case 'MaterialId':
          titleValue = this.$t("DeliverDelay.codeid");
          break;
        case 'MaterialName':
          titleValue = this.$t("DeliverDelay.name");
          break;
        case 'MaterialSpec':
          titleValue = this.$t("DeliverDelay.specifications");
          break;
        case 'TotalSQty':
          titleValue = this.$t("DeliverDelay.allnumber");
          break;
        case 'DeliveryDate':
          titleValue = this.$t("DeliverDelay.messagedate");
          break;
        case 'LastConfirmDate':
          titleValue = this.$t("DeliverDelay.wareday");
          break;
        case 'StoreConfirmSQty':
          titleValue = this.$t("DeliverDelay.stockquantity");
          break;
        case 'DelayDays':
          titleValue = this.$t("DeliverDelay.delayday");
          break;
        default:
          titleValue = 'ARSearch默认栏位信息（测试）'
          break;
      }
      return this.renderTooltip(h, titleValue, columnRealWidth)
    }
  }
}
</script>

<style scoped>
.detialmessage {
  color: #5d5d5d;
  font-size: 14px;
}

.delaymessage {
  padding: 10px 20px;
}

.el-checkbox /deep/ .el-checkbox__label {
  color: #5d5d5d;
  font-size: 14px;
}

.el-table /deep/ .el-table__header .cell {
  overflow: hidden;
  white-space: nowrap;
}

.el-table /deep/ th.el-table__cell {
  background-color: #f9f9f9;
}

.el-table /deep/ .el-table__body-wrapper {
  font-size: 13px;
}

.el-table /deep/ thead {
  font-size: 14px;
  color: #5d5d5d;
}

.topbutton {
  color: #777777;
  font-size: 14px;
}
</style>
